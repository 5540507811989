import XLSX from "xlsx";

export const getUploadPayload = (data, companyList, networks) => {
  const companies = companyList?.filter((un) => un.companyName).map((item) => ({ id: item.id, name: item.companyName }));
  const workbook = XLSX.read(data, { type: 'binary' });
  const firstSheet = workbook.SheetNames[0];
  const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
  // to bind the data to model. 
  const payload = [];
  excelRows.forEach(function (row, index) {
    let companyFilter = (companies.filter((un) => un.name == row.parentCompany));
    let parentPeopleFilter = (networks.filter((un) => un.name == row.parentPeople));
    let setParentCompany, setParentPeople;
    if (companyFilter.length > 0) {
      setParentCompany = companyFilter[0].id;
    } else {
      const ParentComp = row?.parentCompany?.toUpperCase();
      alert(`Please upload the Excel correctly. Network's Parent Company Name ${ParentComp} doesn't exist.`);
      return;
      // Optionally, you can exit the program here if needed.
    }
    if (parentPeopleFilter.length > 0) {
      setParentPeople = parentPeopleFilter[0].id;
    } else {
      const ParentPeople = row?.parentPeople?.toUpperCase();
      alert(`Please upload the Excel correctly. Network's Parent People Name ${ParentPeople} doesn't exist.`);
      return;
      // Optionally, you can exit the program here if needed.
    }
    if (setParentCompany.length > 0 && setParentPeople.length > 0) {
      payload.push({
        "networkName": row.networkName,
        "parentCompany": setParentCompany,
        "parentPeople": setParentPeople
      });
    }
  });
  if (payload.length > 0) {
    return payload;
  }
}
