import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { submitCreateNetworkContactForm, editNetworkContact, getNetworkContactDetails } from '../../../reducers/NetworkContactReducer';
import useQuery from '../../../utils/useQuery';
import { schema, initialFormValues, getPayload, getEditPayload } from './utils';
import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
    Select,
    InputLabel,
    FormControl,
    FormHelperText,
    TextField,
    MenuItem,
} from '@mui/material';
import {
    AppContent,
    AppContentInner,
    AppWrapper,
    AppMain,
    HeaderText,
} from '../../../styles/appStyles';
import {
    FormControlWrapper,
    FormButtonWrapper
} from '../../../styles/formStyles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),

        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '400px',
        },
        '& .MuiButtonBase-root': {
            margin: theme.spacing(2),
        },
    },
    numberField: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0

        },
    }
}));

const CreateNetworkContact = props => {
    const classes = useStyles();
    const { restClient: { rest }, networkContactData, getNetworkContactDetails } = props;
    const query = useQuery();
    const action = query.get('action');
    const networkId = query.get('id');
    const contactId = query.get('contactId');

    const [formState, setFormState] = useState({
        isValid: false,
        values: initialFormValues,
        touched: {},
        errors: {},
    });

    useEffect(() => {
        if (action === 'edit' && networkId && rest) {
            const getNetwork = async () => {
                getNetworkContactDetails(networkId, contactId);
            }
            getNetwork();
        }
    }, [action, networkId, contactId, getNetworkContactDetails, rest])

    useEffect(() => {
        if (action === 'edit' && networkContactData) {
            setFormState({
                isValid: false,
                values: networkContactData,
                touched: {},
                errors: {},
            });
        }
    }, [action, networkContactData]);

    const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

    const handleChange = (event) => {
        // event.persist();

        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleStateChange = (field, value) => {
        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [field]: value
            },
            touched: {
                ...prevFormState.touched,
                [field]: true
            }
        }));
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
    
        setFormState((prevFormState) => ({
          ...prevFormState,
          values: {
            ...prevFormState.values,
            [name]: value.trim(), // Trim leading and trailing spaces on blur
          },
          touched: {
            ...prevFormState.touched,
            [name]: true,
          },
        }));
      };

    useEffect(() => {
        const errors = validate(formState.values, schema, { fullMessages: false });

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {}
        }));

    }, [formState.values]);

    const handleStartCaseChange = (event) => {
        const { name, value } = event.target;
        handleStateChange(name, StartCase(value));
    }

    const StartCase = (text) => {
        return text
            .split(" ")
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
    };

    const checkErrors = () => {
        let isErrorExist = false;
        let touched = {};
        for (let key in formState.values) {
            if (formState.values.hasOwnProperty(key)) {
                touched = {
                    ...touched,
                    [key]: true
                }
            }
        }

        const errors = validate(formState.values, schema, { fullMessages: false });
        if (errors) {
            isErrorExist = true;
        }

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {},
            touched: touched,
        }));

        return isErrorExist;
    }

    const submitForm = event => {
        event.preventDefault();
        if (!checkErrors()) {
            const { values } = formState;
            if (action === 'edit' && networkId) {
                const editPayload = getEditPayload(values);
                props.editNetworkContact(networkId, contactId, editPayload);
            }
            else {
                const payload = getPayload(values);
                props.submitCreateNetworkContactForm(networkId, payload);
            }
        }
    }
    return (
        <AppContent>
            <AppContentInner>
                <AppWrapper>
                    <AppMain>
                        <Container maxWidth="lg">
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={12}>
                                    <Card >
                                        <HeaderText >
                                            {action === 'edit' ? <h5>Edit Network Contact</h5> : <h5>Create New Network Contact</h5>}
                                        </HeaderText>
                                        <CardContent >
                                            <form onSubmit={submitForm}>
                                                <Grid container spacing={4}>
                                                    <Grid item lg={6} xs={12}>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('firstName')}
                                                                    helperText={
                                                                        hasError('firstName') ? formState.errors.firstName[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="First Name *"
                                                                    fullWidth
                                                                    data-test-id="Create-Network-Contact-FirstName"
                                                                    placeholder="First Name"
                                                                    type="text"
                                                                    name="firstName"
                                                                    value={formState.values.firstName}
                                                                    onChange={handleStartCaseChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('middleName')}
                                                                    helperText={
                                                                        hasError('middleName') ? formState.errors.middleName[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Middle Name *"
                                                                    fullWidth
                                                                    data-test-id="Create-Network-Contact-MiddleName"
                                                                    placeholder="Middle Name"
                                                                    type="text"
                                                                    name="middleName"
                                                                    value={formState.values.middleName}
                                                                    onChange={handleStartCaseChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('lastName')}
                                                                    helperText={
                                                                        hasError('lastName') ? formState.errors.lastName[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Last Name *"
                                                                    fullWidth
                                                                    data-test-id="Create-Network-Contact-LastName"
                                                                    placeholder="Last Name"
                                                                    type="text"
                                                                    name="lastName"
                                                                    value={formState.values.lastName}
                                                                    onChange={handleStartCaseChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('emailAddress')}
                                                                    helperText={
                                                                        hasError('emailAddress') ? formState.errors.emailAddress[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Email Address *"
                                                                    fullWidth
                                                                    data-test-id="Create-Network-Contact-EmailAddress"
                                                                    placeholder="Email Address"
                                                                    type="text"
                                                                    name="emailAddress"
                                                                    value={formState.values.emailAddress}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                    </Grid>
                                                    <Grid item lg={6} xs={12}>
                                                        <FormControlWrapper>
                                                            <FormControl variant="outlined" className='w-100'
                                                            >
                                                                <InputLabel id="countryCode-select-outlined-label">Country *</InputLabel>
                                                                <Select
                                                                    error={hasError('countryCode')}
                                                                    labelId="countryCode-select-outlined-label"
                                                                    id="countryCode-select-outlined"
                                                                    label="Country"
                                                                    data-test-id="Create-Network-Contact-countryCode"
                                                                    placeholder="Country"
                                                                    value={formState.values.countryCode}
                                                                    onChange={handleChange}
                                                                    inputProps={{
                                                                        name: 'countryCode',
                                                                        id: 'countryCode',
                                                                    }}
                                                                >
                                                                    <MenuItem value="0" disabled>Select country</MenuItem>
                                                                    {[{ name: 'USA', value: '1' }, { name: 'India', value: '91' }].map((countryCode) => <MenuItem key={countryCode.value} value={countryCode.value}>{countryCode.name}</MenuItem>)}
                                                                </Select>
                                                                {hasError('countryCode') && (
                                                                    <FormHelperText htmlFor="form-selector" error={hasError('countryCode')}>
                                                                        {formState.errors.countryCode[0]}
                                                                    </FormHelperText>
                                                                )}
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('mobileNumber')}
                                                                    helperText={
                                                                        hasError('mobileNumber') ? formState.errors.mobileNumber[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Mobile Number *"
                                                                    fullWidth
                                                                    className={classes.numberField}
                                                                    data-test-id="Create-Network-Contact-MobileNumber"
                                                                    placeholder="Mobile Number"
                                                                    type="number"
                                                                    name="mobileNumber"
                                                                    value={formState.values.mobileNumber}
                                                                    onChange={handleStartCaseChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('whatsAppNumber')}
                                                                    helperText={
                                                                        hasError('whatsAppNumber') ? formState.errors.whatsAppNumber[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="WhatsApp Number *"
                                                                    fullWidth
                                                                    className={classes.numberField}
                                                                    data-test-id="Create-Network-Contact-WhatsAppNumber"
                                                                    placeholder="WhatsApp Number"
                                                                    type="number"
                                                                    name="whatsAppNumber"
                                                                    value={formState.values.whatsAppNumber}
                                                                    onChange={handleStartCaseChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl>
                                                                <TextField
                                                                    error={hasError('smsNumber')}
                                                                    helperText={
                                                                        hasError('smsNumber') ? formState.errors.smsNumber[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Sms Number *"
                                                                    fullWidth
                                                                    className={classes.numberField}
                                                                    data-test-id="Create-Network-Contact-SmsNumber"
                                                                    placeholder="Sms Number"
                                                                    type="number"
                                                                    name="smsNumber"
                                                                    value={formState.values.smsNumber}
                                                                    onChange={handleStartCaseChange}
                                                                    onBlur={handleBlur}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <Grid container justifyContent="center" spacing={5}>
                                                            <Grid item>
                                                                    <FormButtonWrapper className="align-box-row justify-box-row">
                                                                        <Link to={'/networkcontacts?id=' + networkId} className='linkStyle'>
                                                                            <Button
                                                                                type="submit"
                                                                                color="secondary"
                                                                                data-test-id="Create-Network-Contact-Cancel"
                                                                                variant="contained"
                                                                                size="large"
                                                                                className="my-2">
                                                                                cancel
                                                                            </Button>
                                                                        </Link>
                                                                    </FormButtonWrapper>
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormButtonWrapper className="align-box-row justify-box-row">
                                                                        <Button
                                                                            type="submit"
                                                                            color="primary"
                                                                            variant="contained"
                                                                            data-test-id="Create-Network-Contact-Submit"
                                                                            size="large"
                                                                            className="Buttons">
                                                                            {action === 'edit' ? 'Update' : 'Submit'}
                                                                        </Button>
                                                                    </FormButtonWrapper>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </AppMain>
                </AppWrapper>
            </AppContentInner>
        </AppContent>
    );
}

const mapStateToProps = state => ({
    restClient: state.restClient,
    networkContactData: state.networkContact.networkContactData
});

export default connect(mapStateToProps, { submitCreateNetworkContactForm, editNetworkContact, getNetworkContactDetails })(CreateNetworkContact);
