export const initialFormValues = {
  dealName: '',
  // dealDescription: '',
  dealBrand: '',
  dealNetwork: '', //based on state
  dealEquipment: [],
  industry: '',
  dealStartDate: new Date().toISOString(),
  dealEndDate: new Date(new Date().setDate(new Date().getDate() + 10)).toISOString(),
  creatorCompany: '',//set as loggedin user
  creatorPeople: '',//set as loggedin user
  dealInvites: [],
  dealParticipantStartDate: new Date().toISOString(),
  dealParticipantEndDate: new Date(new Date().setDate(new Date().getDate() + 10)).toISOString(),
  dealParticipants: [],
  channelParticipants: '',
  channelName: '',//r
  channelType: '',//r
  channelDescription: '',
  buyer: [],
  buyerPrice: '75000',//r
  seller: '',
  acceptedPrice: '75000',//r
  UnreadMessages: []
};

export const schema = {
  dealNetwork: {
    presence: { allowEmpty: false, message: 'Deal Network is required' },
  },
  dealBrand: {
    presence: { allowEmpty: false, message: 'Deal Brand is required' },
  },
  channelType: {
    presence: { allowEmpty: false, message: 'Channel Type is required' },
  },
  buyer: {
    presence: { allowEmpty: false, message: 'Buyer Name is required' },
  },
  channelName: {
    presence: { allowEmpty: false, message: 'Deal Messaging Channel Name is required' },
  },
};


export const getPayload = (values) => {
  const payload = {
    dealNetwork: values.dealNetwork,
    dealEquipment: values.dealEquipment,
    dealName: values.dealName,
    dealDescription: values.dealDescription,
    dealBrand: values.dealBrand,
    industry: values.industry,
    startDate: values.dealStartDate,
    endDate: values.dealEndDate,
    elapsedTime: values.elapsedTime,
    creatorCompany: values.creatorCompany, //delete in UI
    creatorPeople: values.creatorPeople, //delete in UI
    dealInvites: values.dealInvites,
    dealParticipants: values.dealParticipants,
    UnreadMessages: values.UnreadMessages,
    dealClosingSale: values.dealClosingSale,
    "dealMessagingChannels": [
      {
        channelParticipants: values.channelParticipants,
        channelName: values.channelName,
        channelType: values.channelType,
        channelDescription: values.channelDescription,
        startDate: values.channelDescriptionStartDate,
        endDate: values.channelDescriptionEndDate,
        numMessages: values.numMessages,
        cost: values.cost,
        messagingChannelStatus: values.messagingChannelStatus,
      }
    ],
    "dealCommunicationParticipant": [
      {
        inviteToCommunicate: values.inviteToCommunicate,
        messageChannelParticipant: values.messageChannelParticipant,
        startDate: values.inviteToCommunicateStartDate,
        endDate: values.inviteToCommunicateEndDate,
        participantStatus: values.participantStatus,
      }
    ],
    // "dealClosingSale": [
    //   {
    //     buyer: values.buyer,
    //     buyerPrice: values.buyerPrice,
    //     seller: values.seller,
    //     acceptedPrice: values.acceptedPrice,
    //     quantity: values.quantity,
    //     totalPrice: values.totalPrice,
    //     agreementSignedDate: values.agreementSignedDate,
    //     closingSaleStatus: values.closingSaleStatus,
    //   }
    // ],
    "dealClosingList": [
      {
        closingType: values.closingType,
        startDate: values.ClosingStartDate,
        endDate: values.ClosingEndDate,
        dealEquipment: values.dealClosingEquipment,
        quantity: values.equipmentQuantity,
      }
    ],
    "dealClosingPayment": [
      {
        paymentMethod: values.paymentMethod,
        paymentDate: values.paymentDate,
        paymentStatus: values.paymentStatus,
      }
    ],
    "dealClosingDelivery": [
      {
        deliveryDate: values.deliveryDate,
        deliverySigned: values.deliverySigned,
        deliveryStatus: values.deliveryStatus,
      }
    ],
  }
  return payload;
}