import { enqueueSnackbar } from './NotifierReducer';
import { setShowLoader } from './LoaderReducer';
import { push } from 'connected-react-router';
export const SET_NETWORK_DATA = 'SET_NETWORK_DATA';
export const SET_NETWORK_LIST = 'SET_NETWORK_LIST';

//Get all networks
export const getNetworkList = (pageState) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const fetchedNetworkList = await client.service('network').find(
        {
          query: {
            limit: pageState?.limit,
            page: pageState?.page,
            sort: pageState?.sort,
            search: pageState?.searchData
          }
        }
      );
      let networkList = [];
      if (fetchedNetworkList.networks && fetchedNetworkList.networks.data)
        networkList = fetchedNetworkList.networks.data.map(item => {
          const network = {};
          network.id = item._id;
          network.networkName = item.networkName;
          network.parentPeople = item.parentPeople && item.parentPeople.contact && `${item.parentPeople.contact.firstName}  ${item.parentPeople.contact.lastName}`;
          network.parentCompany = item.parentCompany && item.parentCompany.companyName;
          network.networkContacts = item.networkContacts ? item.networkContacts : "";
          return network;
        })
      // networkList.sort(function (a, b) {
      //   if (a.networkName < b.networkName) {
      //     return -1;
      //   }
      //   if (a.networkName > b.networkName) {
      //     return 1;
      //   }
      //   return 0;
      // });
      let sortedNetwork;
      if (pageState?.sort === null) {
        sortedNetwork = networkList.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      } else {
        sortedNetwork = networkList
      }
      networkList = { networkList: sortedNetwork, total: fetchedNetworkList.networks.total, skip: fetchedNetworkList.networks.skip };
      dispatch({ type: SET_NETWORK_LIST, payload: networkList })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch({ type: SET_NETWORK_LIST, payload: [] })
    }
  }
}
// create network passing model 
export const submitNetworkRegisterForm = (payload = {}, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const network = await client.service('network').create({
        "network": payload
      });
      if (type === 'upload') {
        dispatch(getNetworkList())
      } else {
        dispatch({ type: SET_NETWORK_DATA, payload: network })
        dispatch(push('/network'));
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Network Created successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      //dispatch(push('/network'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Network Creation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// edit network 
export const editNetwork = (networkId, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('network').patch(networkId, payload);
      dispatch(enqueueSnackbar({
        message: 'Network information Updated successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/network'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit Network information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const patchNetwork = (networkId, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const newNetworkList = await client.service('network').patch(networkId, payload);
      dispatch({ type: SET_NETWORK_LIST, payload: newNetworkList });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Network details are edited successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Network details failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export const removeNetwork = (networkId, networkList, totalCount) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('network').remove(networkId);
      const newList = [...networkList];
      const index = networkList.findIndex(item => item.id === networkId);
      if (index !== -1) { // Check if the item was found
        newList.splice(index, 1);
        const updatedTotalRecords = totalCount - 1; // Update totalRecords
        let listing = { networkList: newList, total: updatedTotalRecords }
        dispatch({ type: SET_NETWORK_LIST, payload: listing });
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Network has been deleted successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// select network details by network Id.
export const getNetworkById = (networkId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getNetwork = await client.service(`network`).get(networkId, {})
      const data = getNetwork.network || {};
      const values = {
        networkID: data._id,
        networkName: data.networkName,
        parentPeople: data.parentPeople,
        parentCompany: data.parentCompany
      };

      dispatch({ type: SET_NETWORK_DATA, payload: values })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export default function reducer(
  state = {
    networkList: null,
    networkData: null,
  },
  action
) {
  switch (action.type) {
    case SET_NETWORK_LIST:
      return {
        ...state,
        networkList: action.payload
      };
    case SET_NETWORK_DATA:
      return {
        ...state,
        networkData: action.payload
      };
    default:
      break;
  }
  return state;
}
