import React from "react";
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DealComponent from '../../components/Deals'

const useStyles = makeStyles({
  MuiContainer: {
    display: 'flex', 
    },
  });
  
const DealPage = () => {    
const classes = useStyles();
return (
<Grid container spacing={3} className={classes.MuiContainer}> 
<DealComponent />
</Grid>  
)
}

export default DealPage;
