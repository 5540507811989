import XLSX from "xlsx";

export const getUploadPayload = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    // to bind the data to model. 
    const payload = [];
    excelRows.forEach(function (row, index) {
        payload.push({
            "firstName": row.firstName,
            "middleName": row.middleName,
            "lastName": row.lastName,
            "emailAddress": row.emailAddress,
            "countryCode": row.countryCode,
            "mobileNumber": row.mobileNumber,
            "whatsAppNumber": row.whatsAppNumber,
            "smsNumber": row.smsNumber
        });
        // console.log(payload);
    });
    return payload;
}
