import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  submitUserRegisterForm,
  getUserList,
  removeUser,
} from "../../../reducers/UserReducer";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridPagination
} from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import {
  Card,
  Typography,
  IconButton,
  Grid,
  Box,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SearchBar from '../../../utils/Search';
import { ThemeProvider, createTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "1400px",
    // margin: "20px",
  },
  tableTitle: {
    display: "flex",
    alignItems: "center",
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0 1.5rem",
  },
  actions: {
    color: "rgb(17, 82, 147)",
  },
  panel: {
    "& .css-1knaqv7-MuiButtonBase-root-MuiButton-root": {
      color: "dodgerblue",
      fontSize: "100",
    },
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-3rem",
    // maxWidth: "1400px"
  },
  cardStyle: {
    height: "480px",
    width: "1314px",
    marginLeft: "-5rem",
    marginBottom: "-1rem",
    [theme.breakpoints.down('lg')]: {
      width: '90%',
      height: '450px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('md')]: {
      width: '95%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 'auto',
      height: '500px',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
    [theme.breakpoints.up('lg')]: {
      width: '100%',
      height: '500px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '1rem',
    },
  },
}));

const UserList = (props) => {
  const classes = useStyles();
  const {
    userList = [],
    restClient: { rest },
    getUserList,
    totalCount
  } = props;
  const history = useHistory();

  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: ""
  });
  const [rowCountState, setRowCountState] = React.useState(totalCount);
  const [filteredUserList, setFilteredUserList] = useState([]); // State for filtered user list
  const [search, setSearch] = React.useState('');

  // useEffect(() => {
  //   // Set filteredUserList to userList initially
  //   if(userList.length > 0){
  //   setFilteredUserList(userList);
  //   }
  // }, [userList]);

  useEffect(() => {
    // Filter user list when search state changes
    if (userList.length > 0) {
      const filteredList = userList.filter(user =>
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.companyName.toLowerCase().includes(search.toLowerCase()) ||
        user.phone.toLowerCase().includes(search.toLowerCase()) ||
        user.roleSystem.toLowerCase().includes(search.toLowerCase())
      );

      setFilteredUserList(filteredList);
      if (filteredList.length > 0) {
        setRowCountState(filteredList?.length)
      }
    }
  }, [search, userList]);

  // Get the data from userreducer 
  useEffect(() => {
    const getList = async () => {
      if (rest) {
        if (pageState) {
          await getUserList(pageState);
        }
      }
    };
    getList();
  }, [rest, getUserList, pageState]);

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);

  //user edit
  const handleUserEdit = (event, user) => {
    history.push(`/users/create?action=edit&id=${user.id}`);
  };

  const handleUserView = (event, user) => {
    history.push(`/view/users?action=View&id=${user.id}`);
  };
  // remove users
  const handleUserDelete = (event, user) => {
    props.removeUser(user.id, userList, totalCount);
  };
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.name} placement="bottom" arrow>
            <Box
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  "&:hover": {
                    cursor: "pointer",
                    fontWeight: "bold",
                  },
                }}
                onClick={(event) => {
                  handleUserView(event, params.row);
                }}
              >
                {params.row.name}
              </Box>
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "roleSystem",
      headerName: "Role",
      type: "string",
      width: 150,
    },
    {
      field: "email",
      headerName: "Email",
      type: "string",
      width: 300,
    },
    {
      field: "phone",
      headerName: "Phone",
      // type: 'number',
      width: 200,
    },
    {
      field: "companyName",
      headerName: "Company",
      type: "string",
      width: 200,
      renderCell: (params) => {
        return (
          <Tooltip title={params.row.companyName} placement="bottom" arrow>
            <Box
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              {params.row.companyName}
            </Box>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 130,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="View User">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleUserView(event, params.row);
                }}
              >
                <ViewWeekIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Edit User">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  handleUserEdit(event, params.row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete User">
              <IconButton
                sx={{ color: "#3E5272" }}
                onClick={(event) => {
                  window.confirm(
                    "Are you sure want to delete " + params.row.name + " ?"
                  ) && handleUserDelete(event, params.row);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              USERS
            </Typography>
          </Grid>
          <Grid item xs={12} lg={4} sx={isSmallScreen ? {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start'
          } : {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}>
            <Tooltip title="Create New User">
              <Link to="/users/create">
                <IconButton sx={{ color: "#3E5272" }}>
                  <AddBoxIcon />
                </IconButton>
              </Link>
            </Tooltip>
            {/* <SearchBar
              searchText={search}
              filterChange={onFilterChange}
              onClickFunc={handleClearText}
            /> */}
          </Grid>
        </Grid>
      </Box>
    );
  }

  const onFilterChange = (event) => {
    setSearch(event.target.value);

  };
  // console.log(pageState.search);
  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  return (
    <div>
      <Box>
        <SearchBar
          searchText={search}
          filterChange={onFilterChange}
          onClickFunc={handleClearText}
        />
      </Box>
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rowCount={filteredUserList.length >= 0 ? filteredUserList.length : rowCountState}
            paginationMode="server"
            rowsPerPageOptions={[100]}
            pagination
            pageSize={pageState.pageSize}
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            columns={columns}
            rows={filteredUserList || []}
            disableSelectionOnClick
            checkboxSelection
            sortingMode="server"
            onSortModelChange={(col) => {
              if (col.length > 0) {
                if (col[0].sort === "asc") {
                  setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
                } else {
                  setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
                }
              } else {
                setPageState((old) => ({ ...old, sort: null }));
              }
            }}
            sx={{
              "& .css-ptiqhd-MuiSvgIcon-root": {
                color: "#ee9907",
              },
              "& .css-1oqqzyl-MuiContainer-root": {
                maxWidth: "1400px"
              }
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }}
            density="standard"
            components={{
              Toolbar: DataGridTitle,
              Pagination: GridPagination,
            }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  userList: state.user && state.user.userList.userList,
  totalCount: state.user && state.user.userList.total,
  limit: state.user && state.user.userList.limit,
});

export default connect(mapStateToProps, {
  submitUserRegisterForm,
  getUserList,
  removeUser,
})(UserList);