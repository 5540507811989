import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import useQuery from '../../../utils/useQuery';
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from '../../../styles/appStyles';
import {
  FormButtonWrapper
} from '../../../styles/formStyles';
import {
  Grid,
  Container,
  Card,
  CardContent,
  IconButton,
  Button,
} from '@mui/material';
import { getEquipmentById } from '../../../reducers/EquipmentReducer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';
import getTranslationObject from '../../../utils/getTranslations';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import carousel styles
import defaultAvatar from '../../../assets/images/DefaultAvatar.jpg';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import noImage from '../../NewDealGrid/no-pictures.png';

const ViewEquipment = props => {
  const { restClient: { rest }, equipmentData, getEquipmentById, locale } = props;
  const query = useQuery();
  const equipmentID = query.get('id');
  const history = useHistory();
  const i18n = getTranslationObject(locale, "view_equipment_page");

  const handleCancel = (event) => {
    event.preventDefault();
    // Go back to the previous page
    history.goBack();
  };

  useEffect(() => {
    if (equipmentID && rest) {
      const getEquipment = async () => {
        getEquipmentById(equipmentID);
      }
      getEquipment();
    }
  }, [equipmentID, rest, getEquipmentById])

  const [imgs, setImgs] = useState([noImage]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSlideChange = (index) => {
        setCurrentIndex(index);
    };

  const imageBaseUrl = `https://atgdevstore.blob.core.windows.net/equipment/${equipmentID}/`;

  useEffect(() => {
    if (equipmentData?.pictures?.length > 0) {
      const imageUrls = equipmentData.pictures.map(image => `${imageBaseUrl}${image}`);
      setImgs(imageUrls);
    } else {
      setImgs([noImage]);
    }
  }, [equipmentData]);

  return (
    <Grid
      item
      xs={12}
      lg={12}>
      <Card>
        <Header >
          <HeaderText >
            <h5>{i18n.translate("page_title")}</h5>
          </HeaderText>
        </Header>
        <CardContent >
        {/* {equipmentData?.pictures && equipmentData.pictures.length > 0 ? ( */}
        <div style={{paddingBottom: "10px"}}>
            <Carousel
              showThumbs={false}
              infiniteLoop={false}
              showArrows={true} 
              showStatus={false}
              emulateTouch
              dynamicHeight={false}
              selectedItem={currentIndex} // Set the current slide based on state
              onChange={handleSlideChange} // Update the current index on slide change
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                  currentIndex > 0 && (
                      <IconButton
                          onClick={onClickHandler}
                          sx={{
                              position: 'absolute',
                              top: '50%',
                              left: '15px',
                              transform: 'translateY(-50%)',
                              zIndex: 2,
                              color: 'white',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                              },
                          }}
                          aria-label={label}
                      >
                          <ArrowBackIosNewIcon />
                      </IconButton>
                  )
              }
              renderArrowNext={(onClickHandler, hasNext, label) => {
                // Flatten all the images from dealEquipment
                // const totalImages = equipmentData.pictures ? ;

                return (
                    currentIndex < imgs.length - 1 && (
                        <IconButton
                            onClick={onClickHandler}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: '15px',
                                transform: 'translateY(-50%)',
                                zIndex: 2,
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                              },
                            }}
                            aria-label={label}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    )
                );
            }}
            >
              {imgs?.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image} // Full image URL
                      alt={'Equipment'} // Alt text for accessibility
                      style={{ height: '300px', width: '33%' }} // Style to adjust image size
                    />
                  </div>
                ))}
            </Carousel>
          {/* ) : (
            <p>No images available</p>
          )} */}
          </div>
          <form className='formSpacing'>
            <Grid container rowSpacing={1}>
              <Grid container xs={12} md={12} lg={12}>
                <TableContainer elevation={0} sx={{ overflowX: 'auto' }}>
                  <Table sx={{ minWidth: { xs: 300, sm: 500 } }} aria-label="custom pagination table">
                    <TableBody>
                      <TableRow key={equipmentData.branchID} >
                        <TableCell component="th" scope="row" style={{ width: 50 }}>
                          {i18n.translate("branch_id")}
                        </TableCell>
                        <TableCell style={{ width: 160 }} >
                          {equipmentData.branchID}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.createdByName} >
                        <TableCell component="th" scope="row" style={{ width: 50 }}>
                          {i18n.translate("created_by")}
                        </TableCell>
                        <TableCell style={{ width: 160 }} >
                          {equipmentData.createdByName}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.companyEquipmentID}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("company_equipment_id")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.companyEquipmentID}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.equipmentName}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("equipment_name")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.equipmentName}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.equipmentDescription}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("equipment_description")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.equipmentDescription}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.companyName}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("company_name")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.companyName}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.fleetType}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("fleet_type")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.fleetType}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.make}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("manufacturing_company")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.make}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.majorCategory}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("major_category")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.majorCategory}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.model}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("model")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.model}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.modelYear}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("model_year")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.modelYear}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.meterRead}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("meter_read")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.meterRead}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.serialNum}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("serial_number")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.serialNum}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.quantity}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("quantity")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.quantity}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.currentLocationCity}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("current_location_city")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.currentLocationCity}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.pricingModel}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("pricing_model")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.pricingModel}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.reservePrice}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("reserve_price")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.reservePrice}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.mediaLink}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("media_link")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          <a href={`https://${equipmentData.mediaLink}`} target="_blank" rel="noreferrer" style={{ textDecoration: "none", color: "rgb(120, 120, 120)" }} >{equipmentData.mediaLink}</a>
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.industry}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("industry")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.industry}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.locationLat}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("latitude")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.locationLat}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.locationLong}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("longitude")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.locationLong}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.locationStatus}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("location_status")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.locationStatus}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.usageStatus}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("usage_status")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.usageStatus}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.qtrProjected}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("qtr_projected")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.qtrProjected}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.photoLink}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("photo_link")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.photoLink}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.pricingTiers}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("pricing_tiers")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.pricingTiers}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.assetDescription}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("asset_description")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.assetDescription}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.reservePrice}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("reserve_price")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.reservePrice}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.listingPrice}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("listing_price")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.listingPrice}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.comparableRetailPrice}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("comparable_retail_price")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.comparableRetailPrice}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.needStatus}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("need_status")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.needStatus}
                        </TableCell>
                      </TableRow>
                      <TableRow key={equipmentData.availabilityStatus}>
                        <TableCell component="th" scope="row">
                          {i18n.translate("availability_status")}
                        </TableCell>
                        <TableCell style={{ width: 160 }}>
                          {equipmentData.availabilityStatus}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                    <TableFooter>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            &nbsp;&nbsp;&nbsp;
            <FormButtonWrapper>
              {/* <Link to="/equipment" className='linkStyle'> */}
              <Button color='primary' variant='contained' type='cancel' onClick={handleCancel} data-test-id="View-Equipment-GoBack" text-decoration='none'>
                {i18n.translate("go_back_button")}
              </Button>
              {/* </Link> */}
            </FormButtonWrapper>
            {/* <div><button onClick={handleCancel}>Cancel</button></div> */}
          </form>
        </CardContent>
      </Card>
    </Grid>
  );
};
const mapStateToProps = state => ({
  restClient: state.restClient,
  equipmentData: state.equipment.equipmentData,
  locale: state.locale,
});
export default connect(mapStateToProps, { getEquipmentById })(ViewEquipment);