import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { submitNetworkRegisterForm, getNetworkById, editNetwork } from '../../../reducers/NetworkReducer';
import { getCompanyList } from '../../../reducers/CompanyReducer';
import { getUserList } from '../../../reducers/UserReducer';
import useQuery from '../../../utils/useQuery';
import { schema, initialFormValues, getPayload } from './utils';
import {
  HeaderText,
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
} from '../../../styles/appStyles';
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  MenuItem
} from '@mui/material';

import {
  FormControlWrapper,
  FormButtonWrapper
} from '../../../styles/formStyles';


const CreateNetwork = props => {
  const { restClient: { rest }, networkData, companyList, userList, getNetworkById, getCompanyList, getUserList } = props;
  const query = useQuery();
  const action = query.get('action');
  const networkId = query.get('id');

  const [formState, setFormState] = useState({
    isValid: false,
    values: initialFormValues,
    touched: {},
    errors: {},
  });

  useEffect(() => {
    if (action === 'edit' && networkId && rest) {
      const getNetwork = async () => {
        getNetworkById(networkId);
      }
      getNetwork();
    }
  }, [action, networkId, getNetworkById, rest])

  useEffect(() => {
    if (action === 'edit' && networkData) {
      setFormState({
        isValid: false,
        values: networkData,
        touched: {},
        errors: {},
      });
    }
  }, [action, networkData]);

  useEffect(() => {
    if (rest) {
      const getNetworkParentCompanies = async () => {
        getCompanyList();
      }
      getNetworkParentCompanies();
    }
  }, [rest, getCompanyList])

  useEffect(() => {
    if (rest) {
      const getNetworkParentPeoples = async () => {
        getUserList();
      }
      getNetworkParentPeoples();
    }
  }, [rest, getUserList])


  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

  const handleChange = (event) => {
    // event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleStateChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [field]: value
      },
      touched: {
        ...prevFormState.touched,
        [field]: true
      }
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value.trim(), // Trim leading and trailing spaces on blur
      },
      touched: {
        ...prevFormState.touched,
        [name]: true,
      },
    }));
  };

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));

  }, [formState.values]);

  const handleStartCaseChange = (event) => {
    const { name, value } = event.target;
    handleStateChange(name, StartCase(value));
  }

  const StartCase = (text) => {
    return text
      .split(" ")
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };

  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true
        }
      }
    }

    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));

    return isErrorExist;
  }

  const submitForm = event => {
    event.preventDefault();
    if (!checkErrors()) {
      const { values } = formState;
      const payload = getPayload(values);
      if (action === 'edit' && networkId)
        props.editNetwork(networkId, payload);
      else
        props.submitNetworkRegisterForm(payload);
    }
  }
  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="lg">
              <Grid container spacing={5}>
                <Grid
                  item
                  xs={12}
                  lg={12}>
                  <Card >
                    <HeaderText >
                      {action === 'edit' ? <h5>Edit Network</h5> : <h5>Create New Network</h5>}
                    </HeaderText>
                    <CardContent >
                      <form onSubmit={submitForm}>
                        <FormControlWrapper>
                          <FormControl>
                            <TextField
                              error={hasError('networkName')}
                              helperText={
                                hasError('networkName') ? formState.errors.networkName[0] : null
                              }
                              variant="outlined"
                              label="Network Name *"
                              fullWidth
                              data-test-id="Create-Network-NetworkName"
                              placeholder="Network Name"
                              type="text"
                              name="networkName"
                              value={formState.values.networkName}
                              onChange={handleStartCaseChange}
                              onBlur={handleBlur}
                            />
                          </FormControl>
                        </FormControlWrapper>
                        <FormControlWrapper>
                          <FormControl variant="outlined" className='w-100'
                          >
                            <InputLabel id="parentCompany-select-outlined-label">Parent Company *</InputLabel>
                            <Select
                              error={hasError('parentCompany')}
                              labelId="parentCompany-select-outlined-label"
                              id="parentCompany-select-outlined"
                              label="Parent Company"
                              data-test-id="Create-Network-ParentCompany"
                              placeholder="Parent Company"
                              value={formState.values.parentCompany}
                              onChange={handleChange}
                              inputProps={{
                                name: 'parentCompany',
                                id: 'parentCompany',
                              }}
                            >
                              <MenuItem value="0" disabled>Select Parent Company</MenuItem>
                              {(companyList || []).map((company) => <MenuItem value={company.id}>{company.companyName}</MenuItem>)}
                            </Select>
                            {hasError('parentCompany') && (
                              <FormHelperText htmlFor="form-selector" error={hasError('parentCompany')}>
                                {formState.errors.parentCompany[0]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </FormControlWrapper>
                        <FormControlWrapper>
                          <FormControl variant="outlined" className='w-100'
                          >
                            <InputLabel id="parentPeople-select-outlined-label">Parent People *</InputLabel>
                            <Select
                              error={hasError('parentPeople')}
                              labelId="parentPeople-select-outlined-label"
                              id="parentPeople-select-outlined"
                              label="Parent People"
                              data-test-id="Create-Network-ParentPeople"
                              placeholder="Parent People"
                              value={formState.values.parentPeople}
                              onChange={handleChange}
                              inputProps={{
                                name: 'parentPeople',
                                id: 'parentPeople',
                              }}
                            >
                              <MenuItem value="0" disabled>Select Parent People</MenuItem>
                              {(userList || []).map((user) => <MenuItem value={user.id}>{user.name}-({user.companyName})</MenuItem>)}
                            </Select>
                            {hasError('parentPeople') && (
                              <FormHelperText htmlFor="form-selector" error={hasError('parentPeople')}>
                                {formState.errors.parentPeople[0]}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </FormControlWrapper>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <Grid container justifyContent="center" spacing={5}>
                            <Grid item>
                                <FormButtonWrapper className="align-box-row justify-box-row">
                                  <Link to="/network" className='linkStyle'>
                                    <Button
                                      type="submit"
                                      color="secondary"
                                      data-test-id="Create-Network-Cancel"
                                      variant="contained"
                                      size="large"
                                      className="my-2">
                                      cancel
                                    </Button>
                                  </Link>
                                </FormButtonWrapper>
                              </Grid>
                              <Grid item>
                                <FormButtonWrapper className="align-box-row justify-box-row">
                                  <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    data-test-id="Create-Network-Submit"
                                    size="large"
                                    className="Buttons">
                                    {action === 'edit' ? 'Update' : 'Submit'}
                                  </Button>
                                </FormButtonWrapper>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  networkData: state.network.networkData,
  companyList: state.company.companyList.companyList,
  userList: state.user && state.user.userList.userList
});

export default connect(mapStateToProps, { submitNetworkRegisterForm, getNetworkById, editNetwork, getCompanyList, getUserList })(CreateNetwork);

