import React, { useEffect, useState, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { connect } from "react-redux";
// import XLSX from 'xlsx';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridPagination
} from "@mui/x-data-grid";
import {
  Card,
  Typography,
  Grid,
  Box,
  Button,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Sample_rawdata_file from "../../../utils/Sample_rawdata_file.xlsx";
import SearchBar from '../../../utils/Search';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { ThemeProvider, createTheme } from '@mui/material';
import {submitCompanyRawDataForm, getCompanyRawDataList} from '../../../reducers/CompanyReducer';
import { setLoggedInUserData } from '../../../reducers/UserReducer';
import { getUploadPayload } from "./utils";

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles({
  actions: {
    color: "#3E5272",
  },
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  link: {
    color: "rgba(0, 0, 0, 0.87)",
    "&:hover": {
      backgroundColor: "rgb(180, 180, 180)",
    },
  },
  header: {
    maxWidth: "730px",
    marginLeft: "-4rem"
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-31px"
  },
  cardStyle: {
    height: "350px",
    width: "1314px",
    marginLeft: "-5rem",
    marginBottom: "-20px"
  },
  textInput: {
    paddingLeft: "10px"
  },
  dropDiv: {
    display: "flex"
  },
  dropDiv2: {
    paddingLeft: "70px"
  }
});

const CompanyRawData = (props) => {
  const {
    restClient: { rest },
    loggedInUserData,
    getCompanyRawDataList,
    rawList,
  } = props;

  const history = useHistory();
  const classes = useStyles();
  const [file, setFile] = useState();
  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: '',
  });
  const [search, setSearch] = React.useState('');
  const ref = useRef(null);
  const [disabled, setDisabled] = useState(true);
  const [rawDataList, setRawDataList] = useState([]);
  const [excelData, setExcelData] = useState(null);

  useEffect(() => {
    //if (companyList.length > 0) {
      setRawDataList(rawList);
    // }
  }, [rawList])

  useEffect(() => {
    const getList = async () => {
      if (rest && loggedInUserData) {
        await getCompanyRawDataList(pageState, loggedInUserData);
      }
    };
    getList();
  }, [rest, getCompanyRawDataList, loggedInUserData, pageState]);


  function handleChange(event) {
    setFile(event.target.files[0])
    setDisabled(false);
  }

  const columns = [
    {
      field: "companyName",
      headerName: "Company Name",
      width: 250,
    },
    {
      field: "Address",
      headerName: "Address",
      width: 220,
    },
    {
      field: "City",
      headerName: "City",
      width: 170,
    },
    {
      field: "State",
      headerName: "State",
      width: 150,
    },
    {
      field: "County",
      headerName: "Country",
      // type: "string",
      width: 200,
    },
    {
      field: "neighborhood",
      headerName: "Neighborhood",
      width: 230
    },
    // {
    //   field: "website",
    //   headerName: "website",
    //   type: "string",
    //   width: 200,
    // },
    // {
    //   field: "companyDescription",
    //   headerName: "Company Description",
    //   type: "string",
    //   width: 170,
    // },
  ];

  // const ImportExport = (e) => {
  //   const file = e.target.files[0];
  //   const reader = new FileReader();

  //   reader.onload = (event) => {
  //     const data = new Uint8Array(event.target.result);
  //     const workbook = XLSX.read(data, { type: 'array' });
  //     const sheetName = workbook.SheetNames[0];
  //     const sheet = workbook.Sheets[sheetName];
  //     const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });
  //     setExcelData(jsonData);
  //     setDisabled(false);
  //     // props.submitCompanyRawDataForm(jsonData);
  //   };

  //   reader.readAsArrayBuffer(file);
  //   // const timer = setTimeout(() => {
  //   //   ref.current.form.reset();
  //   //   setDisabled(true);
  //   // }, 2000);
  //   // return () => clearTimeout(timer);
  // };

  const ImportExport = (event) => {
    const regex = /^.*\.(xlsx|xls)$/;
    if (regex.test(file.name.toLowerCase())) {
      if (typeof FileReader !== "undefined") {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            const payload = getUploadPayload(reader.result);
            if (payload?.length > 0) {
              props.submitCompanyRawDataForm(payload, "upload");
            } else {
              return 0;
            }
          };
          reader.readAsBinaryString(file);
        }
      } else {
        console.log("This browser does not support HTML5.");
      }
    } else {
      console.log("Please upload a valid Excel file.");
    }
    const timer = setTimeout(() => {
      ref.current.form.reset();
      setDisabled(true);
    }, 2000);
    return () => clearTimeout(timer);
  };


  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              RAWDATA
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
              <Grid item lg={6} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  <GridToolbarContainer>
                    <GridToolbarExport />
                  </GridToolbarContainer>
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  {/* <Tooltip title="Create New Equipment">
                    <Link to="/equipment/create">
                      <IconButton sx={{ color: "#3E5272" }}>
                        <AddBoxIcon />
                      </IconButton>
                    </Link>
                  </Tooltip> */}
                  {/* <Tooltip title="Delete multiple equipments">
                    <Link>
                      <IconButton sx={{ color: "#3E5272" }}>
                        <DeleteIcon onClick={handleMultidelete} />
                      </IconButton>
                    </Link>
                  </Tooltip> */}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const onFilterChange = (event) => {
    setSearch(event.target.value);
  };
  
  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };
  const handleUpload = () => {
    if (excelData) {
      // Format excelData according to API requirements
      // const formattedData = formatExcelData(excelData);
  
      // Dispatch action with the formatted data
      props.submitCompanyRawDataForm(excelData, 'upload', loggedInUserData);
    }
  };

  return (
    <div >
      <div className={classes.tableheader}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h3 className={classes.header}>
            <form>
              Upload RawData Excel File (.xlsx) :
              <input type="file" onChange={handleChange} ref={ref} className={classes.textInput} />
              <Button component="label" variant="contained" endIcon={<UploadFileOutlinedIcon />} onClick={ImportExport} disabled={disabled}>
                Upload file
              </Button>
            </form>
          </h3>
          <a
            href={Sample_rawdata_file}
            download="Sample_rawdata_file"
            style={{ textDecoration: "none", marginRight: "-5rem" }}
          >
            <Button variant="outlined" size="small" sx={{ color: "#3E5272" }}>
              Download Sample Excel file
            </Button>
          </a>
        </Box>
        <SearchBar
          searchText={search}
          filterChange={onFilterChange}
          onClickFunc={handleClearText}
        />
      </div>
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={rawDataList || []}
            columns={columns}
            rowsPerPageOptions={[100]}
            pageSize={100}
            pagination
            disablecheckboxSelection
            disableSelectionOnClick
            sx={{
              "& .css-ptiqhd-MuiSvgIcon-root": {
                color: "#ee9907",
              },
            }}
            components={{
              Toolbar: DataGridTitle,
              Pagination: GridPagination,
            }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  rawList: state.company && state.company.rawList.rawList,
  loggedInUserData: state.user && state.user.loggedInUserData,
  // totalCount: state.company && state.company.companyList.total,
  // limit: state.company && state.company.companyList.limit,
});

export default connect(mapStateToProps, {
  submitCompanyRawDataForm,
  setLoggedInUserData,
  getCompanyRawDataList,
})(CompanyRawData);
