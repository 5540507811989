import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import useQuery from '../../../utils/useQuery';
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from '../../../styles/appStyles';
import {
  FormControlWrapper,
  FormButtonWrapper
} from '../../../styles/formStyles';
import { schema, initialFormValues, getPayload } from './utils';
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  Select,
  InputLabel,
  FormControl,
  FormHelperText,
  TextField,
  MenuItem,
} from '@mui/material';
import { submitBrandRegisterForm, getBrandById, editBrand } from '../../../reducers/BrandReducer';
import { getNetworkList } from '../../../reducers/NetworkReducer';
import { getUserList } from '../../../reducers/UserReducer';
import { getCompanyList } from '../../../reducers/CompanyReducer';
import validate from 'validate.js';

validate.validators.IsValidWebURL = function (value) {
  var regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  if (!validate.isEmpty(value) && !regex.test(value)) {
    return "Enter a valid Brand URL";
  }
  return null
};


const CreateBrand = props => {
  const { restClient: { rest }, brandData, userList, networkList, companyList, getBrandById, getCompanyList, getUserList } = props;
  const query = useQuery();
  const action = query.get('action');
  const brandID = query.get('id');
  const [formState, setFormState] = useState({
    isValid: false,
    values: initialFormValues,
    touched: {},
    errors: {},
  });
  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));
  const handleChange = (event) => {
    // event.persist();
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };
  const handleStateChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [field]: value
      },
      touched: {
        ...prevFormState.touched,
        [field]: true
      }
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value.trim(), // Trim leading and trailing spaces on blur
      },
      touched: {
        ...prevFormState.touched,
        [name]: true,
      },
    }));
  };

  useEffect(() => {
    if (action === 'edit' && brandID && rest) {
      const getBrand = async () => {
        getBrandById(brandID);
      }
      getBrand();
    }
  }, [action, brandID, rest, getBrandById])

  useEffect(() => {
    if (action === 'edit' && brandData) {
      setFormState({
        isValid: false,
        values: brandData,
        touched: {},
        errors: {},
      });
    }
  }, [action, brandData]);
  useEffect(() => {
    if (rest) {
      const getBrandCompany = async () => {
        getCompanyList();
      }
      getBrandCompany();
    }
  }, [rest, getCompanyList])

  useEffect(() => {
    if (rest) {
      const getBrandNetwork = async () => {
        getNetworkList();
      }
      getBrandNetwork();
    }
  }, [rest])

  useEffect(() => {
    if (rest) {
      const getBrandUser = async () => {
        getUserList();
      }
      getBrandUser();
    }
  }, [rest, getUserList])


  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));
  }, [formState.values]);
  const handleStartCaseChange = (event) => {
    const { name, value } = event.target;
    handleStateChange(name, StartCase(value));
  }
  const StartCase = (text) => {
    return text
      .split(" ")
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };
  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true
        }
      }
    }
    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }
    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));
    return isErrorExist;
  }
  const submitForm = event => {
    event.preventDefault();
    if (!checkErrors()) {
      const { values } = formState;
      const payload = getPayload(values);
      if (action === 'edit' && brandID) {
        props.editBrand(brandID, payload);
      }
      else {
        props.submitBrandRegisterForm(payload);
      }
    }
  }
  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="lg">
              <Grid container spacing={5}>
                <Grid
                  item
                  xs={12}
                  lg={12}>
                  <Card >
                    <Header >
                      <HeaderText >
                        <h5>{action === 'edit' ? 'View / Edit Brand' : 'Create New Brand'}</h5>
                      </HeaderText>
                    </Header>
                    <CardContent >
                      <form className='formSpacing' onSubmit={submitForm}>
                        <Grid container spacing={4}>
                          <Grid item lg={6} xs={12}>
                            <FormControlWrapper>
                              <TextField
                                error={hasError('brandName')}
                                helperText={
                                  hasError('brandName') ? formState.errors.brandName[0] : null
                                }
                                variant="outlined"
                                label="Enter Brand Name *"
                                data-test-id="Create-Brand-BrandName"
                                fullWidth
                                placeholder="Brand Name"
                                type="text"
                                name="brandName"
                                id="brandName"
                                value={formState.values.brandName}
                                onChange={handleStartCaseChange}
                                onBlur={handleBlur}
                              />
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <TextField
                                error={hasError('brandDescription')}
                                helperText={
                                  hasError('brandDescription') ? formState.errors.brandDescription[0] : null
                                }
                                variant="outlined"
                                multiline
                                label="Enter Brand Description"
                                data-test-id="Create-Brand-BrandDescription"
                                fullWidth
                                placeholder="Brand Description"
                                type="text"
                                name="brandDescription"
                                id="brandDescription"
                                value={formState.values.brandDescription}
                                onChange={handleStartCaseChange}
                                onBlur={handleBlur}
                              />
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('brandPeople')}
                                helperText={
                                  hasError('brandPeople') ? formState.errors.brandPeople[0] : null
                                }
                              >
                                <InputLabel >Select Brand People</InputLabel>
                                <Select
                                  error={hasError('brandPeople')}
                                  helperText={
                                    hasError('brandPeople') ? formState.errors.brandPeople[0] : null
                                  }
                                  label="Select Brand Name "
                                  data-test-id="Create-Brand-BrandPeople"
                                  value={formState.values.brandPeople}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'brandPeople',
                                    id: 'brandPeople',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Brand People</MenuItem>
                                  {(userList || []).map((user) => <MenuItem value={user.id}>{user.name}-({user.companyName})</MenuItem>)}
                                </Select>
                                {hasError('brandPeople') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('brandPeople')}>
                                    {formState.errors.brandPeople[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('brandNetwork')}
                                helperText={
                                  hasError('brandNetwork') ? formState.errors.brandNetwork[0] : null
                                }
                              >
                                <InputLabel >Select Brand Network</InputLabel>
                                <Select
                                  error={hasError('brandNetwork')}
                                  helperText={
                                    hasError('brandNetwork') ? formState.errors.brandNetwork[0] : null
                                  }
                                  label="Select Brand Network Name"
                                  data-test-id="Create-Brand-BrandNetwork"
                                  value={formState.values.brandNetwork}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'brandNetwork',
                                    id: 'brandNetwork',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Brand Network</MenuItem>
                                  {(networkList || []).map((network) => <MenuItem value={network.id}>{network.networkName}</MenuItem>)}
                                </Select>
                                {hasError('brandNetwork') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('brandNetwork')}>
                                    {formState.errors.brandNetwork[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                                error={hasError('brandCompany')}
                                helperText={
                                  hasError('brandCompany') ? formState.errors.brandCompany[0] : null
                                }
                              >
                                <InputLabel>Select Brand Company *</InputLabel>
                                <Select
                                  error={hasError('brandCompany')}
                                  helperText={
                                    hasError('brandCompany') ? formState.errors.brandCompany[0] : null
                                  }
                                  label="Select Brand Company"
                                  data-test-id="Create-Brand-BrandCompany"
                                  value={formState.values.brandCompany}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'brandCompany',
                                    id: 'brandCompany',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Brand Company</MenuItem>
                                  {(companyList || []).map((company) => <MenuItem value={company.id}>{company.companyName}</MenuItem>)}
                                </Select>
                                {hasError('brandCompany') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('brandCompany')}>
                                    {formState.errors.brandCompany[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <TextField
                                error={hasError('brandURL')}
                                helperText={
                                  hasError('brandURL') ? formState.errors.brandURL[0] : null
                                }
                                variant="outlined"
                                label="Enter Brand URL"
                                data-test-id="Create-Brand-BrandURL"
                                fullWidth
                                placeholder="Brand URL"
                                type="text"
                                name="brandURL"
                                id="brandURL"
                                value={formState.values.brandURL}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                            </FormControlWrapper>
                          </Grid>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <Grid container justifyContent="center" spacing={5}>
                              <Grid item>
                                  <FormButtonWrapper>
                                    <Link to="/brand" className='linkStyle'>
                                      <Button
                                        type="submit"
                                        color="secondary"
                                        data-test-id="Create-Brand-Cancel"
                                        variant="contained"
                                        size="large"
                                        className="my-2">
                                        cancel
                                      </Button>
                                    </Link>
                                  </FormButtonWrapper>
                                </Grid>
                                <Grid item>
                                  <FormButtonWrapper>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      data-test-id="Create-Brand-Submit"
                                      size="large"
                                      className="Buttons">
                                      {action === 'edit' ? 'Update' : 'Submit'}
                                    </Button>
                                  </FormButtonWrapper>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
};
const mapStateToProps = state => ({
  restClient: state.restClient,
  userList: state.user && state.user.userList.userList,
  networkList: state.network && state?.network?.networkList?.networkList,
  companyList: state.company.companyList.companyList,
  brandData: state.brand.brandData
});
export default connect(mapStateToProps, { submitBrandRegisterForm, getNetworkList, getUserList, getBrandById, editBrand, getCompanyList })(CreateBrand);