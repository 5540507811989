import React, { useEffect } from 'react';
// import MaterialTable from 'material-table';
import {
  DataGrid,
} from "@mui/x-data-grid";
import { connect } from 'react-redux';
import { getPlacesList } from '../../reducers/EspoCrmReducer';
// import tableIcons from '../../utils/MaterialTableIcons';
import { makeStyles } from "@mui/styles";
// import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";

const useStyles = makeStyles({
  actions: {
    color: "#3E5272"
  },
  root: {
    maxWidth: '100%',
    margin: '20px'
  },
  link: {
    textDecoration: "none",
    color: "#696969",
    '&:hover': {
      backgroundColor: "rgb(180, 180, 180)",
    }
  },
  header: {
    maxWidth: "700px"
  },
  create: {
    color:"#3BB143"
  }
});

const PlacesList = props => {
  const { contactList = [], restClient: { rest }, getPlacesList } = props;
  const classes = useStyles();
  useEffect(() => {
    const getList = async () => {
      try {
        if (rest) {
          await getPlacesList();
        }
      } catch (e) {
        console.log(e);
      }
    };
    getList();
  }, [rest, getPlacesList]);


  // const theme = createTheme({
  //   overrides: {
  //     MuiTableCell: {
  //       root: {
  //         borderBottom: 'none',
  //       },
  //     },
  //     MuiTypography: {
  //       h6: {
  //         color: "#696969",
  //         fontSize: "large",
  //         fontWeight: "bolder"
  //       }
  //     },
  //     MuiTableSortLabel: {
  //       root: {
  //         color: "#fff !important"
  //       },
  //       icon: {
  //         color: "#fff !important"
  //       }
  //     }
  //   }
  // },
  // )

  return (
    // <MuiThemeProvider theme={theme}>
    <div className={classes.root}>
      <DataGrid
        // title="PLACES LIST"
        // icons={tableIcons}
        columns={[
          // { title: 'Name', field: 'name' },
          // { title: 'Phone Number', field: 'phone' },
          // { title: 'City', field: 'city' },
          // { title: 'State', field: 'addressState' },
          // { title: 'Country', field: 'addressCountry' },
          // { title: 'Postal Code', field: 'addressPostalCode' },
        ]}
        data={contactList || []}
        // options={{
        //   exportButton: true,
        //   exportAllData: true,
        //   actionsColumnIndex: -1,
        //   showEmptyDataSourceMessage: contactList && contactList.length === 0 ? true : false,
        //   maxBodyHeight: 400,
        //   headerStyle: {
        //     color: "#FFF",
        //     fontWeight: "700",
        //     backgroundColor: "#3E5272"
        //   },
        //   rowStyle: {
        //     fontSize: "1rem",
        //     fontWeight: "600",
        //     color: "#696969"
        //   }
        // }}
        // actions={[
        //   (rowData) => {
        //     return userList.some((i)=>i.email === rowData.email)
        //       ? { icon: forwardRef((props, ref) =><CheckCircleRoundedIcon className={classes.create} {...props} ref={ref} />), tooltip: 'User already exists' }
        //       : { 
        //         icon: forwardRef((props, ref) =><AddBox className={classes.actions} {...props} ref={ref} />),
        //         tooltip: 'Add New Contact',
        //         onClick: (event, rowData) =>handleUserCreate(event,rowData),
        //         isFreeAction: true
        //       }
        //  }
        // ]}
      />
    </div>
  // </MuiThemeProvider>
  )
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  contactList: state.espoCrm && state.espoCrm.contactList
});
export default connect(mapStateToProps, { getPlacesList })(PlacesList);