import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getUserById } from '../../../reducers/UserReducer';
import useQuery from '../../../utils/useQuery';
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  HeaderText,
} from '../../../styles/appStyles';
import {
  FormButtonWrapper
} from '../../../styles/formStyles';
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';

const ViewUser = props => {
  const { restClient: { rest }, userData, getUserById } = props;
  const query = useQuery();
  const userId = query.get('id');

  useEffect(() => {
    if (userId && rest) {
      const getUser = async () => {
        getUserById(userId);
      }
      getUser();
    }
  }, [userId, getUserById, rest])

  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="xl">
              <Grid container spacing={5}>
                <Grid
                  item
                  xs={12}
                  lg={12}>
                  <Card className='cardView'>
                    <HeaderText  >
                      <h2>View User Details</h2>
                    </HeaderText>
                    <CardContent >
                      <form className='formSpacing'>
                        <Grid container rowSpacing={1}>
                          <Grid container xs={12} md={12} lg={12}>
                            <TableContainer elevation={0} >
                              <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                <TableBody>
                                  <TableRow key={userData.roleType} >
                                    <TableCell component="th" scope="row" style={{ width: 50 }}>
                                      {"Role Type"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} >
                                      {userData.roleType}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={userData.firstName}>
                                    <TableCell component="th" scope="row">
                                      {"First Name"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {userData.firstName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={userData.lastName}>
                                    <TableCell component="th" scope="row">
                                      {"Last Name"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {userData.lastName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={userData.webURL}>
                                    <TableCell component="th" scope="row">
                                      {"Company Website"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {userData.webURL}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={userData.mobileNumber}>
                                    <TableCell component="th" scope="row">
                                      {"Mobile Number"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {userData.mobileNumber}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={userData.smsNumber}>
                                    <TableCell component="th" scope="row">
                                      {"SMS Number"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {userData.smsNumber}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={userData.email}>
                                    <TableCell component="th" scope="row">
                                      {"Email"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {userData.email}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={userData.countryCode}>
                                    <TableCell component="th" scope="row">
                                      {"Country Code"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {userData.countryCode}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={userData.roleSystem}>
                                    <TableCell component="th" scope="row">
                                      {"Role System"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {userData.roleSystem}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                                <TableFooter>
                                </TableFooter>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        <FormButtonWrapper className="align-box-row justify-box-row">
                          <Link to="/users" className='linkStyle'>
                            <Button
                              type="submit"
                              color="primary"
                              data-test-id="View-User-GoBack"
                              variant="contained"
                              size="large"
                              className="my-2">
                              Go Back
                            </Button>
                          </Link>
                        </FormButtonWrapper>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  userData: state.user.userData,
});

export default connect(mapStateToProps, { getUserById })(ViewUser);

