import React from 'react';
import { Typography, Button, Container, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <Container maxWidth="sm">
            <Box sx={{ my: 4 }}>
                <Typography variant="h1" component="h1" gutterBottom>
                    404
                </Typography>
                <Typography variant="h4" component="h2" gutterBottom>
                    Oops! Page not found.
                </Typography>
                <Typography variant="body1" paragraph>
                    The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/">
                    Go to homepage
                </Button>
            </Box>
        </Container>
    );
};

export default NotFound;
