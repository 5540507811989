import enLocale from "../locales/en.json";
import esLocale from "../locales/es.json";
import ptLocale from "../locales/pt.json";
import frLocale from "../locales/fr.json";
import { enUS, esES, ptBR, frFR } from "@mui/x-data-grid/locales";

// TODOs (current):
/*
  1. Finish static content
  2. Apply interpolation where needed
  3. If possible access store inside translation object
  
  1. Throw error if filekey is invalid

  1. Pull Sreenu's patch and fix conflicts
  2. Fix EquipmentList page to use getCategoryList
  3. Talk to Sreenu about using useState for majorCategory instead of calculating from formState.
  4. Ask about MUI required prop
*/

// DONE:
// 1. central i18n object
// 2. working interpolation
// 3. Fallback to keys
// 4. Static content for all of site except notes on google docs
export default function getTranslationObject(locale, fileKey) {
  let translationSet = enLocale;
  let dataGridLocale = enUS;
  switch (locale) {
      case ('es'):
          translationSet = esLocale;
          dataGridLocale = esES;
          break;
      case ('pt'):
          translationSet = ptLocale;
          dataGridLocale = ptBR;
          break;
      case ('fr'):
          translationSet = frLocale;
          dataGridLocale = frFR;
          break;
      case ('en'):
      default:
          translationSet = enLocale;
          dataGridLocale = enUS;
          break;
  }
  const translations = translationSet[fileKey];
  const dataGridLocaleProp = dataGridLocale.components.MuiDataGrid.defaultProps.localeText;

  const translate = (key, tokens={}) => {
    if (!translations[key]) {
      return key;
    }

    let val = translations[key];
    if (tokens.length === 0) {
      return val;
    }

    // interpolate translated strings, e.g. "Welcome to {companyName}" => "Welcome to AskTakeGo"
    for (const [placeholder, replacement] of Object.entries(tokens)) {
      val = val.replaceAll("{"+placeholder+"}", replacement);
    }
    return val;
  }

  const getIndustryList = () => {
    const tCategories = translationSet.utils.industry_categories;
    return tCategories.filter((un) => un.industry).map((un) => un.industry);
  }

  const getCategoryList = (industry) => {
    const uCategories = enLocale.utils.industry_categories;
    const ind = uCategories.findIndex(item => item.industry === industry);
    return (ind >= 0 ? translationSet.utils.industry_categories[ind].majorCategory : []);
  }

  return {dataGridLocaleProp, translate, getIndustryList, getCategoryList};
}