export const initialFormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  mobileNumber: '',
  dealCreation: true,
  makeOffer: true,
  acceptOffer: true,
  rejectOffer: true,
};


export const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'First name is required' }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'Last name is required' }
  },
  email: {
    presence: { allowEmpty: false, message: 'Email is required' }
  },
  mobileNumber: {
    presence: { allowEmpty: false, message: 'Phone is required' },
    //IsValidPhoneNumber: {},
  },
};

export const getPayload = (values) => {
  const payload = {
    "contact": {
      firstName: values.firstName,
      lastName: values.lastName,
      emailAddress: values.email,
      mobileNumber: values.mobileNumber
    },
    email: values.email,
    password: values.password,
    phone: values.mobileNumber,
    dealCreation: values.dealCreation,
    acceptOffer: values.acceptOffer,
    makeOffer: values.makeOffer,
    rejectOffer: values.rejectOffer
  }
  return payload;
}


export const userSelctableRoles = [{ name: 'Trader' }, { name: 'User' }, { name: 'Service Provider' }, { name: 'Executive' }];
