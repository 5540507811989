import React, { useState, useEffect, useRef } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Routes from "./Routes";
import configureStore, { history } from "./config/configureStore";
import Header from "./components/Header";
import Footer from "./components/Footer";
import "./App.css";
import { ThemeProviderCustom } from "./location";
import InactivityDetector from '../src/InteractivityDetector/index';
import { LicenseInfo } from '@mui/x-license';

const App = () => {
  const [headerHeight, setHeaderHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  LicenseInfo.setLicenseKey(process.env.REACT_APP_DATAGRID_KEY || "0bb996ab7e9c04c1e49500417c209349Tz05MTg4NSxFPTE3NDkxMjkxOTMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

  const { store, persistor } = configureStore();

  useEffect(() => {
    // Calculate the total height of the header and footer
    const totalHeight = headerHeight + footerHeight;

    // Update the height of the Routes component
    document.documentElement.style.setProperty('--routes-height', `calc(100vh - ${totalHeight}px)`);
  }, [headerHeight, footerHeight]);
  return (
    <Provider store={store}>
      <InactivityDetector timeout={900000}>
        <ConnectedRouter history={history}>
          <PersistGate loading={null} persistor={persistor}>
            <ThemeProviderCustom>
              <Header onHeightChange={setHeaderHeight} /> {/* Attach ref to Header */}
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Routes />
              </LocalizationProvider>
              <Footer onHeightChange={setFooterHeight} /> {/* Attach ref to Footer */}
            </ThemeProviderCustom>
          </PersistGate>
        </ConnectedRouter>
      </InactivityDetector>
    </Provider>
  );
};

export default App;
