import { enqueueSnackbar } from './NotifierReducer';
import { setShowLoader } from './LoaderReducer';
import { push } from 'connected-react-router';
// import loggers from '../pages/Logger';
export const SET_VENDOR_DATA = 'SET_VENDOR_DATA';
export const SET_VENDOR_LIST = 'SET_VENDOR_LIST';

//Get all vendors
export const getVendorList = (pageState) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const fetchedVendorList = await client.service('integratedvendor').find(
                {
                    query: {
                        limit: pageState?.limit,
                        page: pageState?.page,
                        sort: pageState?.sort,
                        search: pageState?.searchData
                    }
                }
            );
            let vendorList = [];
            if (fetchedVendorList.integratedvendors && fetchedVendorList.integratedvendors.data)
                vendorList = fetchedVendorList.integratedvendors.data.map(item => {
                    const vendor = {};
                    vendor.id = item._id;
                    vendor.companyName = item.companyName;
                    vendor.webURL = item.webURL;
                    vendor.companyEmailAddress = item.companyEmailAddress;
                    vendor.city = item.city;
                    vendor.state = item.state;
                    vendor.zipcode = item.zipcode;
                    vendor.country = item.country;
                    vendor.phoneNumber = item.phoneNumber;
                    vendor.industry = item.industry;
                    vendor.stockSymbol = item.stockSymbol;
                    vendor.searchLinkedinCompany = item.searchLinkedinCompany;
                    vendor.linkedinCompanyPageUrl = item.linkedinCompanyPageUrl;
                    vendor.deptOrTitle = item.deptOrTitle;
                    vendor.contactLinkedin = item.contactLinkedin;
                    vendor.annualRevenue = item.annualRevenue;
                    vendor.numberOfEmployees = item.numberOfEmployees;
                    vendor.description = item.description;
                    vendor.companyOwner = item.companyOwner;
                    vendor.facebook = item.facebook;
                    vendor.twitter = item.twitter;
                    vendor.instagram = item.instagram;
                    vendor.linkedin = item.linkedin;
                    vendor.youtube = item.youtube;
                    vendor.firstLine = item.firstLine;
                    vendor.firstName = item.firstName;
                    vendor.emailAddress = item.emailAddress;
                    return vendor;
                })
            vendorList.sort(function (a, b) {
                if (a.companyName < b.companyName) {
                    return -1;
                }
                if (a.companyName > b.companyName) {
                    return 1;
                }
                return 0;
            });
            vendorList = { vendorList: vendorList, total: fetchedVendorList.integratedvendors.total, skip: fetchedVendorList.integratedvendors.skip }
            dispatch({ type: SET_VENDOR_LIST, payload: vendorList })
            dispatch(setShowLoader(false));
            // loggers.info('successfully got list');
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch({ type: SET_VENDOR_LIST, payload: [] })
            // logger.LoggerComponent.log('error', 'got error in list');
        }
    }
}
// create vendor passing model 
export const submitVendorRegisterForm = (payload = {}, type) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const vendor = await client.service('integratedvendor').create({
                "integratedvendor": payload
            });
            if (type === 'upload') {
                const pageState = {
                    page: 1,
                    limit: 100,
                    sort: null,
                    searchData: '',
                };
                dispatch(getVendorList(pageState))
            } else {
                dispatch({ type: SET_VENDOR_DATA, payload: vendor })
                dispatch(push('/vendor'));
            }
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Integrated Vendor Created successfully.`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
            dispatch(push('/vendor'));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Integrated Vendor Creation failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

// edit vendor 
export const editVendor = (vendorID, payload = {}) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            await client.service('integratedvendor').patch(vendorID, payload);
            dispatch(enqueueSnackbar({
                message: 'Vendor information Updated successfully.',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
            dispatch(push('/vendor'));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: `Edit Vendor information failed. ${error.message}`,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

// export const patchBrand = (brandID, payload = {}) => {
//   return async (dispatch, getState) => {
//     try {
//       dispatch(setShowLoader(true));
//       const client = getState().restClient.rest;
//       const newBrandList = await client.service('brand').patch(brandID, payload);
//       dispatch({ type: SET_BRAND_LIST, payload: newBrandList });
//       dispatch(setShowLoader(false));
//       dispatch(enqueueSnackbar({
//         message: `Brand details are edited successfully.`,
//         options: {
//           key: new Date().getTime() + Math.random(),
//           variant: 'success',
//           anchorOrigin: {
//             vertical: 'top',
//             horizontal: 'right',
//           }
//         },
//       }));
//     } catch (error) {
//       dispatch(setShowLoader(false));
//       dispatch(enqueueSnackbar({
//         message: `Brand details failed. ${error.message}`,
//         options: {
//           key: new Date().getTime() + Math.random(),
//           variant: 'error',
//           anchorOrigin: {
//             vertical: 'top',
//             horizontal: 'right',
//           }
//         },
//       }));
//     }
//   }
// }
export const deleteVendor = (vendorID, vendorList, totalCount) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            await client.service('integratedvendor').remove(vendorID);
            const newList = [...vendorList];
            const index = vendorList.findIndex(item => item.id === vendorID);
            if (index !== -1) { // Check if the item was found
                newList.splice(index, 1);
                const updatedTotalRecords = totalCount - 1; // Update totalRecords
                let listing = { vendorList: newList, total: updatedTotalRecords }
                dispatch({ type: SET_VENDOR_LIST, payload: listing });
            }
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: 'Vendor Company has been deleted successfully.',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: error.message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

// select Vendor details by vendor Id.
export const getVendorById = (vendorID) => {
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(true));
            const client = getState().restClient.rest;
            const getVendor = await client.service(`integratedvendor`).get(vendorID, {})
            const data = getVendor.integratedvendor || {};
            const values = {
                vendorId: data._id,
                companyName: data.companyName ?? '',
                webURL: data.webURL ?? '',
                companyEmailAddress: data.companyEmailAddress ?? '',
                city: data.city ?? '',
                state: data.state ?? '',
                zipcode: data.zipcode ?? '',
                country: data.country ?? '',
                phoneNumber: data.phoneNumber ?? '',
                industry: data.industry ?? '',
                stockSymbol: data.stockSymbol ?? '',
                searchLinkedinCompany: data.searchLinkedinCompany ?? '',
                linkedinCompanyPageUrl: data.linkedinCompanyPageUrl ?? '',
                deptOrTitle: data.deptOrTitle ?? '',
                contactLinkedin: data.contactLinkedin ?? '',
                annualRevenue: data.annualRevenue ?? '',
                numberOfEmployees: data.numberOfEmployees ?? '',
                description: data.description ?? '',
                companyOwner: data.companyOwner ?? '',
                facebook: data.facebook ?? '',
                twitter: data.twitter ?? '',
                instagram: data.instagram ?? '',
                linkedin: data.linkedin ?? '',
                youtube: data.youtube ?? '',
                firstLine: data.firstLine ?? '',
                firstName: data.firstName ?? '',
                emailAddress: data.emailAddress ?? '',
            };

            dispatch({ type: SET_VENDOR_DATA, payload: values })
            dispatch(setShowLoader(false));
        } catch (error) {
            dispatch(setShowLoader(false));
            dispatch(enqueueSnackbar({
                message: error.message,
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right',
                    }
                },
            }));
        }
    }
}

export default function reducer(
    state = {
        vendorList: [],
        vendorData: {},
    },
    action
) {
    switch (action.type) {
        case SET_VENDOR_LIST:
            return {
                ...state,
                vendorList: action.payload
            };
        case SET_VENDOR_DATA:
            return {
                ...state,
                vendorData: action.payload
            };
        default:
            break;
    }
    return state;
}
