export const SHOW_LOADER = 'SHOW_LOADER';

// add loader for page.
export const setShowLoader = (data) => {
    return (dispatch) => {
      dispatch({ type: SHOW_LOADER, payload: data });
    }
}

  export default function reducer(
    state = {
    showLoader: false
    },
    action
  ) {
    switch (action.type) {
    case SHOW_LOADER:
        return {
        ...state,
        showLoader: action.payload
    }
      default:
        break;
    }
    return state;
  }
  