import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { submitVendorRegisterForm, getVendorList, deleteVendor } from '../../../../reducers/VendorReducer';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridPagination } from '@mui/x-data-grid';
import { Card, Typography, IconButton, Grid, Box, Tooltip, Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import SearchBar from '../../../../utils/Search';
import { GetUploadPayload } from "./utils";
import Sample_vendor_file from "../../../../utils/Sample_vendor_file.xlsx";
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeaders: {
                    backgroundColor: '#3E5272',
                    color: 'white',
                },
                columnSeparator: {
                    visibility: "hidden",
                },
                sortIcon: {
                    color: "#ee9907",
                }
            },
        },
    },
});

const useStyles = makeStyles({
    root: {
        maxWidth: "100%",
        height: "400px",
    },
    actions: {
        color: "#3E5272",
    },
    childDiv: {
        paddingTop: "10px",
    },
    tableheader: {
        marginTop: "-31px"
      },
      header: {
        marginLeft: "-4rem"
      },
      cardStyle: {
        height: "350px", 
        width: "1314px",
        marginLeft: "-5rem", 
        marginBottom: "-20px"
      },
      textInput: {
        paddingLeft: "10px"
      }
});

const VendorList = (props) => {
    const {
        vendorList = [],
        restClient: { rest },
        getVendorList,
        totalCount,
    } = props;
    const classes = useStyles();
    const history = useHistory();
    const [pageState, setPageState] = useState({
        page: 1,
        limit: 100,
        sort: null,
        searchData: '',
    });
    const [rowCountState, setRowCountState] = React.useState(totalCount);
    const [search, setSearch] = React.useState('');
    // const [sortedList, setSortedList] = useState([]);
    const [file, setFile] = useState();
    const ref = useRef(null);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        const getList = async () => {
            try {
                if (rest) {
                    if (pageState) {
                        await getVendorList(pageState);
                    }
                }
            } catch (e) {
                console.log(e);
            }
        };
        getList();
    }, [rest, getVendorList, pageState]);

    React.useEffect(() => {
        setRowCountState((prevRowCountState) =>
            totalCount !== undefined ? totalCount : prevRowCountState
        );
    }, [totalCount, setRowCountState]);

    // function getDifference(array1, array2) {
    //     return array1.filter(object1 => {
    //         return !array2.some(object2 => {
    //             return object1.companyName == object2.companyName;
    //         });
    //     });
    // }

    function handleChange(event) {
        setFile(event.target.files[0])
        setDisabled(false);
    }

    const handleVendorEdit = (event, vendor) => {
        history.push(`/create/vendor?action=edit&id=${vendor.id}`);
    };
    // const handleVendorView = (event, vendor) => {
    //     history.push(`/view/vendor?action=ViewColumn&id=${vendor.id}`);
    // };
    const handleCompanyCreate = (event, vendor) => {
        history.push(`/company/create?action=vendorCompany&id=${vendor.id}`);
    };
    const handleVendorDelete = (event, vendor) => {
        props.deleteVendor(vendor.id, vendorList, totalCount);
    };

    const ImportExport = (event) => {
        const regex = /^.*\.(xlsx|xls)$/;
        if (regex.test(file.name.toLowerCase())) {
            if (typeof FileReader !== "undefined") {
                const reader = new FileReader();
                if (reader.readAsBinaryString) {
                    reader.onload = (e) => {
                        const payload = GetUploadPayload(reader.result);
                        props.submitVendorRegisterForm(payload, "upload");
                    };
                    reader.readAsBinaryString(file);
                }
            } else {
                console.log("This browser does not support HTML5.");
            }
        } else {
            console.log("Please upload a valid Excel file.");
        }
        const timer = setTimeout(() => {
            ref.current.form.reset();
            setDisabled(true);
        }, 2000);
        return () => clearTimeout(timer);
    };

    const columns = [
        {
            field: "companyName",
            headerName: "Company Name",
            width: 250,
            // renderCell: (params) => {
            //     return (
            //         <Box
            //             sx={{
            //                 "&:hover": {
            //                     cursor: "pointer",
            //                     fontWeight: "bold",
            //                 },
            //             }}
            //             onClick={(event) => {
            //                 handleVendorView(event, params.row);
            //             }}
            //         >
            //             {params.row.companyName}
            //         </Box>
            //     );
            // },
        },
        {
            field: "webURL",
            headerName: "Vendor Website",
            type: "string",
            width: 210,
            renderCell: (params) => {
                return (
                    <Tooltip title={params.row.webURL} placement="bottom" arrow>
                        <Box
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }}
                        >
                            {params.row.webURL}
                        </Box>
                    </Tooltip>
                );
            },
        },
        {
            field: "companyEmailAddress",
            headerName: "Email Address",
            type: "string",
            width: 330,
        },
        {
            field: "phoneNumber",
            headerName: "Phone Number",
            width: 250,
        },
        {
            field: "actions",
            headerName: "Actions",
            sortable: false,
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Tooltip title="Create Company">
                            <Button
                                sx={{ p: '5px' }}
                                onClick={(event) => {
                                    handleCompanyCreate(event, params.row);
                                }}
                                variant='outlined'
                                color='success'
                                size='small'
                            >
                                Add Company
                            </Button>
                        </Tooltip>
                        <Tooltip title="Edit Vendor">
                            <IconButton
                                sx={{ color: "#3E5272" }}
                                onClick={(event) => {
                                    handleVendorEdit(event, params.row);
                                }}
                            >
                                <EditIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Vendor">
                            <IconButton
                                sx={{ color: "#3E5272" }}
                                onClick={(event) => {
                                    window.confirm(
                                        "Are you sure want to delete " +
                                        params.row.companyName +
                                        " ?"
                                    ) && handleVendorDelete(event, params.row);
                                }}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                );
            },
        },
    ];


    function DataGridTitle() {
        return (
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <Typography variant="h5"
                            sx={{
                                margin: "6px",
                                fontWeight: "bolder",
                                fontSize: "1.2rem",
                                color: "#696969",
                                lineHeight: 1.6,
                            }}>
                            INTEGRATED VENDORS
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container spacing={0}>
                            <Grid item lg={6} xs={6}>
                                <div className={classes.search}>
                                    {/* <GridToolbarQuickFilter
                      quickFilterParser={(searchInput) => { onFilterChange(searchInput) }
                      }
                      quickFilterFormatter={(quickFilterValues) => quickFilterValues.join(', ')}
                      debounceMs={500} // time before applying the new quick filter value
                    /> */}

                                </div>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <div className={classes.childDiv}>
                                    <GridToolbarContainer>
                                        <GridToolbarExport />
                                    </GridToolbarContainer>
                                </div>
                            </Grid>
                            <Grid item lg={3} xs={12}>
                                <div className={classes.childDiv}>
                                    {/* <Tooltip title="Create New Vendor">
                                        <Link to="/vendor/create">
                                            <IconButton sx={{ color: "#3E5272" }} disabled>
                                                <AddBoxIcon />
                                            </IconButton>
                                        </Link>
                                    </Tooltip> */}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const onFilterChange = (event) => {
        // Here you save the data you need from the filter model and perform server-side search/filtering
        // Once the server-side search is completed, you can update the searchInput state with the new value

        // Example: Simulating server-side search delay with setTimeout
        setSearch(event.target.value);
        // if (search.length > 4 || search.trim() == '') {
        if (event?.target?.value?.length >= 3)
            setTimeout(() => {
                setPageState((old) => ({ ...old, searchData: event.target.value }));
            }, 2000);
        if (event?.target?.value?.length < 1)
            setPageState((old) => ({ ...old, searchData: '' }));
        // }
    };
    // console.log(pageState.search);
    const handleClearText = () => {
        setSearch('');
        setPageState((old) => ({ ...old, searchData: '' }));
    };
    return (
        <div>
            <div className={classes.tableheader}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <h3 className={classes.header}>
                    <form>
                        Upload Vendor Company Excel File (.xlsx) :
                        <input type="file" onChange={handleChange} ref={ref} className={classes.textInput} />
                        <Button component="label" variant="contained" endIcon={<UploadFileOutlinedIcon />} onClick={ImportExport} disabled={disabled}>
                            Upload file
                        </Button>
                    </form>
                </h3>
                <a
                    href={Sample_vendor_file}
                    download="Sample_vendor_file"
                    style={{ textDecoration: "none", marginRight: "-5rem" }}
                >
                    <Button variant="outlined" size="small" sx={{ color: "#3E5272" }}>
                        Download Sample Excel file
                    </Button>
                </a>
            </Box>
            <SearchBar
                searchText={search}
                filterChange={onFilterChange}
                onClickFunc={handleClearText}
            />
            </div>
            <Card className={classes.cardStyle}>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        rowCount={rowCountState}
                        paginationMode="server"
                        rowsPerPageOptions={[100]}
                        pagination
                        pageSize={pageState.pageSize}
                        onPageChange={(newPage) => {
                            setPageState((old) => ({ ...old, page: newPage + 1 }));
                        }}
                        columns={columns}
                        rows={vendorList}
                        disableSelectionOnClick
                        checkboxSelection
                        sortingMode="server"
                        onSortModelChange={(col) => {
                            if (col.length > 0) {
                                if (col[0].sort === "asc") {
                                    setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
                                } else {
                                    setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
                                }
                            } else {
                                setPageState((old) => ({ ...old, sort: null }));
                            }
                        }}
                        sx={{
                            "& .css-ptiqhd-MuiSvgIcon-root": {
                                color: "#ee9907",
                            },
                            // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                            //     display: 'none'
                            // }
                        }}
                        density="standard"
                        // components={{ Toolbar: DataGridTitle }}
                        components={{
                            Toolbar: DataGridTitle,
                            Pagination: GridPagination,
                        }}
                    />
                </ThemeProvider>
            </Card>
        </div>
    );
};

const mapStateToProps = (state) => ({
    restClient: state.restClient,
    vendorList: state.vendor && state.vendor.vendorList.vendorList,
    totalCount: state.vendor && state.vendor.vendorList.total,
    limit: state.vendor && state.vendor.vendorList.limit,
});

export default connect(mapStateToProps, {
    submitVendorRegisterForm,
    getVendorList,
    deleteVendor,
})(VendorList);