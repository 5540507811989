import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  Grid,
  Container,
  Card,
  CardContent,
  FormControl,
  Button,
  TextField,
  Box,
} from "@mui/material";
import validate from "validate.js";
import { verifyUser } from "../../reducers/AccountReducer";
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from "../../styles/appStyles";

import { FormControlWrapper, FormButtonWrapper } from "../../styles/formStyles";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import getTranslationObject from "../../utils/getTranslations";

const ForgetPassWord = (props) => {
  const { locale } = props;
  const i18n = getTranslationObject(locale, "forgot_password_page");

  const values = {
    email: "",
  };
  const [formState, setFormState] = useState({
    isValid: false,
    values: values,
    touched: {},
    errors: {},
  });
  const schema = {
    email: {
      presence: {
        allowEmpty: false,
        message: i18n.translate("email_required_message"),
      },
      email: true,
      length: {
        maximum: 64,
      },
    },
  };



  const hasError = (field) =>
    !!(formState.touched[field] && formState.errors[field]);

  const handleChange = (event) => {
    // event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]: event.target.value,
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true,
      },
    }));
  };

  useEffect(
    (schema) => {
      const errors = validate(formState.values, schema, {
        fullMessages: false,
      });

      setFormState((prevFormState) => ({
        ...prevFormState,
        isValid: !errors,
        errors: errors || {},
      }));
    },
    [formState.values]
  );

  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true,
        };
      }
    }

    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));

    return isErrorExist;
  };
  const submitForm = (event) => {
    event.preventDefault();
    if (!checkErrors()) {
      const { values } = formState;
      const payload = {
        email: values.email,
      };
      props.verifyUser("sendResetPwd", payload);
    }
  };

  return (
    <Card sx={{ maxWidth: 600, height: '100%', mx: 'auto', my: 2 }}>
      <Header>
        <HeaderText>
          <ErrorOutlineIcon style={{ fontSize: "xxx-large" }} />
          <h5 style={{ color: "#FF7043" }}>{i18n.translate("forgot_password")}</h5>
          <p>{i18n.translate("enter_email")}</p>
        </HeaderText>
      </Header>
      <CardContent>
        <form onSubmit={submitForm}>
          <FormControlWrapper>
            <FormControl className="w-100">
              <TextField
                error={hasError("email")}
                helperText={
                  hasError("email") ? (
                    formState.errors.email[0]
                  ) : null
                }
                variant="outlined"
                label={i18n.translate("email_address")}
                fullWidth
                placeholder={i18n.translate("email_placeholder")}
                type="email"
                name="email"
                value={formState.values.email}
                onChange={handleChange}
              />
            </FormControl>
          </FormControlWrapper>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <FormButtonWrapper>
                            <Link to="/login" className="linkStyle">
                              <Button
                                type="submit"
                                color="secondary"
                                data-test-id="View-User-GoBack"
                                variant="contained"
                                size="large"
                                className="my-2"
                              >
                                Cancel
                              </Button>
                            </Link>
                          </FormButtonWrapper> */}
            <FormButtonWrapper>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                data-test-id="Forgot-Password-Submit"
                className="my-2"
              >
                {i18n.translate("submit")}
              </Button>
            </FormButtonWrapper>

          </Box >
          <Link to="/login" className="linkStyle" style={{ textDecoration: "none", color: "rgb(120, 120, 120)" }}>
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "20px", color: "#4DB6AC" }}>
              <ArrowBackIcon />
              <div>{i18n.translate("go_back")}</div>
            </div>
          </Link>
        </form >
      </CardContent >
    </Card >
  );
};

const mapStateToProps = (state) => ({
  locale: state.locale
});

export default connect(mapStateToProps, { verifyUser })(ForgetPassWord);
