import { Box, Modal, Slider, Button } from "@mui/material";
import { useRef, useState, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
// import { FcAddImage } from "react-icons/fc";
import "./avatar.css";
import { makeStyles } from '@mui/styles';
// import Header from "../../Header/header";

const useStyles = makeStyles((theme) => ({
    tableTitle: {
        borderRadius: "50%",
    },
    header: {
      textAlign: "center",
    //   paddingBottom: "30px",
      fontSize: "x-large",
      fontWeight: "800",
      color: "rgb(120, 120, 120)",
    }
  }));

// Styles
const boxStyle = {
  width: "300px",
  height: "300px",
  display: "flex",
  flexFlow: "column",
  justifyContent: "center",
  alignItems: "center"
};
const modalStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};

// Modal
const CropperModal = ({ src, modalOpen, setModalOpen, setPreview }) => {
  const [slideValue, setSlideValue] = useState(10);
  const cropRef = useRef(null);

  //handle save
  const handleSave = async () => {
    if (cropRef) {
      const dataUrl = cropRef.current.getImage().toDataURL();
      const result = await fetch(dataUrl);
      const blob = await result.blob();
      setPreview(URL.createObjectURL(blob));
      setModalOpen(false);
    }
  };

  return (
    <Modal sx={modalStyle} open={modalOpen}>
      <Box sx={boxStyle}>
        <AvatarEditor
          ref={cropRef}
          image={src}
          style={{ width: "100%", height: "100%" }}
          border={50}
          borderRadius={150}
          color={[0, 0, 0, 0.72]}
          scale={slideValue / 10}
          rotate={0}
        />

        {/* MUI Slider */}
        <Slider
          min={10}
          max={50}
          sx={{
            margin: "0 auto",
            width: "80%",
            color: "cyan"
          }}
          size="medium"
          defaultValue={slideValue}
          value={slideValue}
          onChange={(e) => setSlideValue(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            padding: "10px",
            border: "3px solid white",
            background: "black"
          }}
        >
          <Button
            size="small"
            sx={{ marginRight: "10px", color: "white", borderColor: "white" }}
            variant="outlined"
            onClick={(e) => setModalOpen(false)}
          >
            cancel
          </Button>
          <Button
            sx={{ background: "#5596e6" }}
            size="small"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

// Container
const UserProfileAvatar = () => {
    const classes = useStyles();
  // State to manage the image source
  const [imageSource, setImageSource] = useState('/profileImage/bulldozer.jpg');

  useEffect(() => {
    // Check if there is a saved image path in local storage
    const savedImagePath = localStorage.getItem('imagePath');

    if (savedImagePath) {
      setImageSource(savedImagePath);
    } else {
      // If no saved path, set a default image path
      const defaultImagePath = '/profileImage/bulldozer.jpg';
      setImageSource(defaultImagePath);
      localStorage.setItem('imagePath', defaultImagePath);
    }
  }, [imageSource]); // Empty dependency array ensures the effect runs only once on component mount

  const handleChangeImage = () => {
    // Change the image path dynamically
    const newImagePath = prompt('Enter the new image path:');
    // const newImagePath = '/images/jcb1.jpg';
    if(newImagePath) {
    setImageSource(newImagePath);
    
    // Save the new image path to local storage
    localStorage.setItem('imagePath', newImagePath);
    }
  };
  // image src
  const [src, setSrc] = useState(null);

  // preview
  const [preview, setPreview] = useState(null);

  // modal state
  const [modalOpen, setModalOpen] = useState(false);

  // ref to control input element
  const inputRef = useRef(null);

  // handle Click
  const handleInputClick = (e) => {
    e.preventDefault();
    const newImagePath = prompt('Enter the new image path:');
    // const newImagePath = '/images/jcb1.jpg';
    if(newImagePath) {
    setImageSource(newImagePath);
    
    // Save the new image path to local storage
    localStorage.setItem('imagePath', newImagePath);
    }
  };
  // handle Change
  const handleImgChange = (e) => {
    setSrc(URL.createObjectURL(e.target.files[0]));
    setModalOpen(true);
  };

  return (
    <>
      <div className={classes.header}>
        <h1 className={classes.header}>User Profile Image</h1>
      </div>
      <main className="container">
        <CropperModal
          modalOpen={modalOpen}
          src={imageSource}
          setPreview={setPreview}
          setModalOpen={setModalOpen}
        />
        <a href="/" onClick={handleInputClick}>
          <AddPhotoAlternateIcon className="add-icon" />
        </a>
        <small>Click to select image</small>
        <input
          type="file"
          accept="image/*"
          style={{display: "none"}}
          ref={inputRef}
          onChange={handleChangeImage}
        />
        <div className={classes.tableTitle}>
          <img
            src={imageSource}
            className={classes.tableTitle}
            alt=""
            width="150"
            height="150"
          />
          {/* <Header imageSource={imageSource} style={{display: "none"}} /> */}
        </div>
      </main>
    </>
  );
};

export default UserProfileAvatar;