import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { submitCreateCompanyBranchForm, editCompanyBranch, getCompanyBranchDetails } from '../../../reducers/CompanyBranchReducer';
import useQuery from '../../../utils/useQuery';
import { schema, initialFormValues, getPayload, getEditPayload } from './utils';
import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
    FormControl,
    TextField,
} from '@mui/material';
import {
    AppContent,
    AppContentInner,
    AppWrapper,
    AppMain,
    HeaderText,
} from '../../../styles/appStyles';
import {
    FormControlWrapper,
    FormButtonWrapper
} from '../../../styles/formStyles';

const CreateCompanyBranch = props => {
    const { restClient: { rest }, companyBranchData, getCompanyBranchDetails } = props;
    const query = useQuery();
    const action = query.get('action');
    const companyId = query.get('id');
    const branchId = query.get('branchId');

    const [formState, setFormState] = useState({
        isValid: false,
        values: initialFormValues,
        touched: {},
        errors: {},
    });

    useEffect(() => {
        if (action === 'edit' && companyId && rest) {
            const getBranch = async () => {
                getCompanyBranchDetails(companyId, branchId);
            }
            getBranch();
        }
    }, [action, companyId, getCompanyBranchDetails, branchId, rest])

    useEffect(() => {
        if (action === 'edit' && companyBranchData) {
            setFormState({
                isValid: false,
                values: companyBranchData,
                touched: {},
                errors: {},
            });
        }
    }, [action, companyBranchData]);

    const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));

    const handleChange = (event) => {
        // event.persist();

        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleStateChange = (field, value) => {
        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [field]: value
            },
            touched: {
                ...prevFormState.touched,
                [field]: true
            }
        }));
    };

    const handleBlur = (event) => {
        const { name, value } = event.target;
    
        setFormState((prevFormState) => ({
          ...prevFormState,
          values: {
            ...prevFormState.values,
            [name]: value.trim(), // Trim leading and trailing spaces on blur
          },
          touched: {
            ...prevFormState.touched,
            [name]: true,
          },
        }));
      };

    useEffect(() => {
        const errors = validate(formState.values, schema, { fullMessages: false });

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {}
        }));

    }, [formState.values]);

    const handleStartCaseChange = (event) => {
        const { name, value } = event.target;
        handleStateChange(name, StartCase(value));
    }

    const StartCase = (text) => {
        return text
            .split(" ")
            .map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            })
            .join(" ");
    };

    const checkErrors = () => {
        let isErrorExist = false;
        let touched = {};
        for (let key in formState.values) {
            if (formState.values.hasOwnProperty(key)) {
                touched = {
                    ...touched,
                    [key]: true
                }
            }
        }

        const errors = validate(formState.values, schema, { fullMessages: false });
        if (errors) {
            isErrorExist = true;
        }

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {},
            touched: touched,
        }));

        return isErrorExist;
    }

    const submitForm = event => {
        event.preventDefault();
        if (!checkErrors()) {
            const { values } = formState;
            if (action === 'edit' && companyId) {
                const editPayload = getEditPayload(values);
                props.editCompanyBranch(companyId, branchId, editPayload);
            }
            else {
                const payload = getPayload(values);
                props.submitCreateCompanyBranchForm(companyId, payload);
            }
        }
    }
    return (
        <AppContent>
            <AppContentInner>
                <AppWrapper>
                    <AppMain>
                        <Container maxWidth="lg">
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={12}>
                                    <Card >
                                        <HeaderText >
                                            {action === 'edit' ? <h5>Edit Company Branch</h5> : <h5>Create New Company Branch</h5>}
                                        </HeaderText>
                                        <CardContent >
                                            <form onSubmit={submitForm}>
                                                <FormControlWrapper>
                                                    <FormControl>
                                                        <TextField
                                                            error={hasError('branchID')}
                                                            helperText={
                                                                hasError('branchID') ? formState.errors.branchID[0] : null
                                                            }
                                                            variant="outlined"
                                                            label="BranchID *"
                                                            fullWidth
                                                            data-test-id="Create-Company-Branch-BranchID"
                                                            placeholder="BranchID"
                                                            type="text"
                                                            name="branchID"
                                                            value={formState.values.branchID}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </FormControl>
                                                </FormControlWrapper>
                                                <FormControlWrapper>
                                                    <FormControl>
                                                        <TextField
                                                            error={hasError('branchName')}
                                                            helperText={
                                                                hasError('branchName') ? formState.errors.branchName[0] : null
                                                            }
                                                            variant="outlined"
                                                            label="BranchName *"
                                                            fullWidth
                                                            data-test-id="Create-Company-Branch-BranchName"
                                                            placeholder="BranchName"
                                                            type="text"
                                                            name="branchName"
                                                            value={formState.values.branchName}
                                                            onChange={handleStartCaseChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </FormControl>
                                                </FormControlWrapper>
                                                <FormControlWrapper>
                                                    <FormControl>
                                                        <TextField
                                                            error={hasError('region')}
                                                            helperText={
                                                                hasError('region') ? formState.errors.region[0] : null
                                                            }
                                                            variant="outlined"
                                                            label="Region *"
                                                            fullWidth
                                                            data-test-id="Create-Company-Branch-Region"
                                                            placeholder="Region"
                                                            type="text"
                                                            name="region"
                                                            value={formState.values.region}
                                                            onChange={handleStartCaseChange}
                                                            onBlur={handleBlur}
                                                        />
                                                    </FormControl>
                                                </FormControlWrapper>
                                                <Grid container spacing={0}>
                                                    <Grid item xs={12}>
                                                        <Grid container justifyContent="center" spacing={5}>
                                                            <Grid item>
                                                                <FormButtonWrapper className="align-box-row justify-box-row">
                                                                    <Link to={'/companybranches?id=' + companyId} className='linkStyle'>
                                                                        <Button
                                                                            type="submit"
                                                                            color="secondary"
                                                                            data-test-id="Create-Company-Branch-Cancel"
                                                                            variant="contained"
                                                                            size="large"
                                                                            className="my-2">
                                                                            cancel
                                                                        </Button>
                                                                    </Link>
                                                                </FormButtonWrapper>
                                                            </Grid>
                                                            <Grid item>
                                                                <FormButtonWrapper className="align-box-row justify-box-row">
                                                                    <Button
                                                                        type="submit"
                                                                        color="primary"
                                                                        variant="contained"
                                                                        data-test-id="Create-Company-Branch-Submit"
                                                                        size="large"
                                                                        className="Buttons">
                                                                        {action === 'edit' ? 'Update' : 'Submit'}
                                                                    </Button>
                                                                </FormButtonWrapper>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </AppMain>
                </AppWrapper>
            </AppContentInner>
        </AppContent>
    );
}

const mapStateToProps = state => ({
    restClient: state.restClient,
    companyBranchData: state.companyBranch && state.companyBranch.companyBranchData
});

export default connect(mapStateToProps, { submitCreateCompanyBranchForm, editCompanyBranch, getCompanyBranchDetails })(CreateCompanyBranch);
