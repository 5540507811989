import XLSX from "xlsx";
export const getUploadPayload = (data, companies, networkList, users) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet], { raw: false });
    // to bind the data to model. 
    const payload = [];
    let networkFilter = (networkList.filter((un) => un.networkName).map(item => ({ id: item.id, parentPeople: item.parentPeople, networkName: item.networkName })));
    excelRows.forEach(function (row, index) {
        networkFilter = networkFilter.filter((un) => un.networkName === row.brandNetwork);
        if (networkFilter.length > 0) {
            // Set the value for setNetworkFilter
            let setNetworkFilter = networkFilter[0]?.id;
            if (setNetworkFilter !== undefined) {
                let companyFilter = companies.find((un) => un.company === row.brandCompany);
                if (companyFilter !== undefined && companyFilter?.company === row.brandCompany) {
                    let userForBrand = users.find((un) => un.name === row.brandPeople);
                    if (userForBrand !== undefined && userForBrand.name === row.brandPeople) {
                        payload.push({
                            "brandCompany": companyFilter.id,
                            "brandPeople": userForBrand.id,
                            "brandNetwork": setNetworkFilter,
                            "dealBrand": {
                                "brandName": row.brandName,
                                "brandDescription": row.brandDescription,
                                "brandURL": row.brandURL,
                            },
                        });
                    } else {
                        const brandPep = row.brandPeople.toUpperCase();
                        alert(`Please upload the Excel correctly. Brand's People(${brandPep}) doesn't exist.`);
                        return;
                    }
                } else {
                    const brandComp = row.brandCompany.toUpperCase();
                    alert(`Please upload the Excel correctly. Brand's Company(${brandComp}) doesn't exist.`);
                    return;
                }
            } else {
                const brandNet = row.brandNetwork.toUpperCase();
                alert(`Please upload the Excel correctly. Brand's Network(${brandNet}) doesn't exist.`);
                return;
            }
        }

        //     if(networkFilter.length>0){
        //     let setNetworkFilter = networkFilter[0]?.id ?? alert(`Please upload the Excel correctly.Wrong One:${networkFilter[0].networkName}`);
        //     let companyFilter = (companies.filter((un) => un.company === row.brandCompany));
        //     companyFilter = (companyFilter[0]?.company === row.brandCompany) ? companyFilter[0].id : alert(`Please upload the Excel correctly.Wrong One:${companyFilter[0].name}`);
        //     let userForBrand = (users.filter((un) => un.name === row.brandPeople).map(item => ({ id: item.id, name: item.name })));
        //     let brandPeople = (userForBrand[0].name === row.brandPeople) ? userForBrand[0].id : alert(`Please upload the Excel correctly.Wrong One:${userForBrand[0].name}`);
        //     payload.push({
        //         "brandCompany": companyFilter,
        //         "brandPeople": brandPeople,
        //         "brandNetwork": setNetworkFilter,
        //         "dealBrand": {
        //             "brandName": row.brandName,
        //             "brandDescription": row.brandDescription,
        //             "brandURL": row.brandURL,
        //         },
        //     });
        //     //console.log(payload);
        // }
    });
    if (payload?.length > 0) {
        return payload;
    }
}