import { enqueueSnackbar } from './NotifierReducer';
import { setShowLoader } from './LoaderReducer';
import { push } from 'connected-react-router';
// import loggers from '../pages/Logger';
export const SET_BRAND_DATA = 'SET_BRAND_DATA';
export const SET_BRAND_LIST = 'SET_BRAND_LIST';

//Get all brands
export const getBrandList = (pageState) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const fetchedBrandList = await client.service('brand').find(
        {
          query: {
            limit: pageState?.limit,
            page: pageState?.page,
            sort: pageState?.sort,
            search: pageState?.searchData
          }
        }
      );
      let brandList = [];
      if (fetchedBrandList.brands && fetchedBrandList.brands.data)
        brandList = fetchedBrandList.brands.data.map(item => {
          const brand = {};
          brand.id = item._id;
          brand.brandPeople = item.brandPeople.length > 0 ? `${item.brandPeople[0].contact.firstName} ${item.brandPeople[0].contact.lastName}` : '';
          brand.brandNetwork = item.brandNetwork.length ? item.brandNetwork[0].networkName : '';
          brand.brandCompany = item.brandCompany === null ? "" : item.brandCompany.companyName;
          brand.brandName = item.dealBrand === null ? "" : item.dealBrand.brandName;
          brand.brandDescription = item.dealBrand === null ? "" : item.dealBrand.brandDescription;
          brand.brandURL = item.dealBrand === null ? "" : item.dealBrand.brandURL;

          return brand;
        })
      // brandList.sort(function (a, b) {
      //   if (a.brandName < b.brandName) {
      //     return -1;
      //   }
      //   if (a.brandName > b.brandName) {
      //     return 1;
      //   }
      //   return 0;
      // });
      let sortedBrand;
      if (pageState?.sort === null) {
        sortedBrand = brandList.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      } else {
        sortedBrand = brandList
      }
      brandList = { brandList: sortedBrand, total: fetchedBrandList.brands.total, skip: fetchedBrandList.brands.skip }
      dispatch({ type: SET_BRAND_LIST, payload: brandList })
      dispatch(setShowLoader(false));
      // loggers.info('successfully got list');
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch({ type: SET_BRAND_LIST, payload: [] })
      // logger.LoggerComponent.log('error', 'got error in list');
    }
  }
}
// create brand passing model 
export const submitBrandRegisterForm = (payload = {}, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const brand = await client.service('brand').create({
        "brand": payload
      });
      if (type === 'upload') {
        dispatch(getBrandList())
      } else {
        dispatch({ type: SET_BRAND_DATA, payload: brand })
        dispatch(push('/brand'));
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Brand Created successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/brand'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Brand Creation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// edit brand 
export const editBrand = (brandID, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('brand').patch(brandID, payload);
      dispatch(enqueueSnackbar({
        message: 'Brand information Updated successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/brand'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit Brand information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const patchBrand = (brandID, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const newBrandList = await client.service('brand').patch(brandID, payload);
      dispatch({ type: SET_BRAND_LIST, payload: newBrandList });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Brand details are edited successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Brand details failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export const removeBrand = (brandID, brandList, totalCount) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('brand').remove(brandID);
      const newList = [...brandList];
      const index = brandList.findIndex(item => item.id === brandID);
      if (index !== -1) { // Check if the item was found
        newList.splice(index, 1);
        const updatedTotalRecords = totalCount - 1; // Update totalRecords
        let listing = { brandList: newList, total: updatedTotalRecords }
        dispatch({ type: SET_BRAND_LIST, payload: listing });
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Brand has been deleted successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// select Brand details by brand Id.
export const getBrandById = (brandID) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getBrand = await client.service(`brand`).get(brandID, {})
      const data = getBrand.brand || {};
      const values = {
        brandID: data._id,
        brandPeople: data.brandPeople,
        brandNetwork: data.brandNetwork,
        brandName: data.dealBrand.brandName,
        brandCompany: data.brandCompany,
        brandDescription: data.dealBrand.brandDescription,
        brandURL: data.dealBrand.brandURL,

      };

      dispatch({ type: SET_BRAND_DATA, payload: values })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export default function reducer(
  state = {
    brandList: [],
    brandData: {},
  },
  action
) {
  switch (action.type) {
    case SET_BRAND_LIST:
      return {
        ...state,
        brandList: action.payload
      };
    case SET_BRAND_DATA:
      return {
        ...state,
        brandData: action.payload
      };
    default:
      break;
  }
  return state;
}
