import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const NetworkStatusListener = ({ children }) => {
    const history = useHistory();

    useEffect(() => {
        const handleOnline = () => {
            if (window.navigator.onLine) {
                history.goBack(); // Return to the previous page when back online
            }
        };

        const handleOffline = () => {
            if (window.navigator.onLine) {
                history.push('/networkerror'); // Navigate to Network Error Page
            }
        };

        window.addEventListener('online', handleOnline);
        window.addEventListener('offline', handleOffline);

        return () => {
            window.removeEventListener('online', handleOnline);
            window.removeEventListener('offline', handleOffline);
        };
    }, [history]);

    return (
        <>
            {window.navigator.onLine ? children : null} {/* Show children only if online */}
        </>
    );
};

export default NetworkStatusListener;
