import XLSX from "xlsx";

export const GetUploadPayload = (data) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet], { raw: false });
    // to bind the data to model. 
    const payload = [];
    excelRows.forEach(function (row, index) {
        payload.push({
            "companyName": row.companyName,
            "webURL": row.webURL,
            "companyEmailAddress": row.companyEmail,
            "city": row.city,
            "state": row.state,
            "zipcode": row.zipcode,
            "country": row.country,
            "phoneNumber": row.phoneNumber,
            "firstLine": row.firstLine,
            "industry": row.industry,
            "stockSymbol": row.stockSymbol,
            "searchLinkedinCompany": row.searchLinkedinCompany,
            "linkedinCompanyPageUrl": row.linkedinCompanyPageUrl,
            "deptOrTitle": row.deptOrTitle,
            "firstName": row.firstName,
            "emailAddress": row.emailAddress,
            "contactLinkedin": row.contactLinkedin,
            "annualRevenue": row.annualRevenue,
            "numberOfEmployees": row.numberOfEmployees,
            "description": row.description,
            "companyOwner": row.companyOwner,
            "facebook": row.facebook,
            "twitter": row.twitter,
            "instagram": row.instagram,
            "linkedin": row.linkedin,
            "youtube": row.youtube,

        });
        //console.log(payload);
    });
    return payload;
}   
