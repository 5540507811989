import { enqueueSnackbar } from './NotifierReducer';
import { push } from 'connected-react-router';
import { setShowLoader } from './LoaderReducer';
import { BlobServiceClient } from '@azure/storage-blob';
export const SET_EQUIPMENT_DATA = 'SET_EQUIPMENT_DATA';
export const SET_EQUIPMENT_LIST = 'SET_EQUIPMENT_LIST';


const blobServiceClient = new BlobServiceClient(`${process.env.REACT_APP_BLOB_SAS_URL}`);

export async function uploadImage(containerName, subcontainer, file) {
  try {
    // Get a container client
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobName = `${subcontainer}/${file.name}`;
    // Get a block blob client
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Upload data to blob storage
    await blockBlobClient.uploadData(file, {
      blockSize: 4 * 1024 * 1024, // 4MB block size
      concurrency: 20,
      onProgress: ev => console.log(ev)
    });
    const fileUrl = blockBlobClient.url;
    console.log(`Upload of file '${file.name}' completed`);
    return { fileUrl, fileName: file.name, etag: blockBlobClient.etag, lastModified: blockBlobClient.lastModified };
  } catch (error) {
    if (error.statusCode === 404) {
      return false; // Blob does not exist
    }
    throw error; // Some other error occurred
  }
}

function getBlobUrls(uploadResults) {//For mapping the multiple files and the urls
  return uploadResults.map(result => result.fileUrl);
}

async function uploadMultipleFiles(containerName, subcontainer, files) { //For uploading the multiple files at a tiime.
  const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
  const results = await Promise.all(uploadPromises);
  const urls = getBlobUrls(results);
  return urls;
}


//For creating new equipment
export const submitEquipmentRegisterForm = (payload = {}, type, loggedInUserData) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true)); //loaders 
      const client = getState().restClient.rest;  //getting the current state from env url
      const files = payload.pictures;
      payload.pictures = payload.pictures.map((pic) => pic.name);
      const equipment = await client.service('equipment').create({
        "equipment": payload
      });
      if (type === 'upload') {  //Bulk Upload
        const pageState = {
          page: 1,
          limit: 100,
          sort: null,
          searchData: '',
        };
        dispatch(getEquipmentList(pageState, loggedInUserData));
      } else {
        console.log("Inside Files:", files);
        uploadMultipleFiles('equipment', equipment?.equipment?._id, files);
        dispatch({ type: SET_EQUIPMENT_DATA, payload: equipment }) //
        dispatch(push('/equipment'));
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Equipment Successfully Created.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/equipment'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Equipment Creation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export const getEquipmentList = (pageState, loggedInUserData) => {
  const role = loggedInUserData && loggedInUserData.user && loggedInUserData.user.roleSystem;
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const roleBase = (role === "regular" && loggedInUserData?.user?.atgRole?.roleType[0] !== "Trusted Agent") ? {
        limit: pageState?.limit,
        page: pageState?.page,
        sort: pageState?.sort,
        search: pageState?.searchData,
        createdBy: loggedInUserData.user._id
      } : {
        limit: pageState?.limit,
        page: pageState?.page,
        sort: pageState?.sort,
        search: pageState?.searchData,
      };
      const fetchedEquipmentList = await client.service('equipment').find(
        {
          query: roleBase
        }
      );
      let equipmentList = [];
      if (fetchedEquipmentList.equipment && fetchedEquipmentList.equipment.data)
        equipmentList = fetchedEquipmentList.equipment.data.map(item => {
          const equipment = {};
          equipment.id = item._id;
          equipment.comparableRetailPrice = item.comparableRetailPrice !== undefined ? item.comparableRetailPrice[0] : '';
          equipment.industry = item.industry !== undefined ? item.industry[0] : '';
          equipment.majorCategory = item.majorCategory !== undefined ? item.majorCategory[0] : '';
          equipment.companyId = item.company !== null ? item.company._id : '';
          equipment.companyName = item.company !== null ? item.company.companyName : '';
          equipment.companyEquipmentID = item.companyEquipmentID ?? '';
          equipment.fleetType = item.fleetType !== undefined ? item.fleetType : '';
          equipment.qtrProjected = item.qtrProjected !== undefined ? item.qtrProjected : '';
          equipment.equipmentName = item.equipmentName ?? '';
          equipment.equipmentDescription = item.equipmentDescription ?? '';
          equipment.make = item.make ?? '';
          equipment.model = item.model ?? '';
          equipment.modelYear = item.modelYear ? new Date(item.modelYear).getFullYear() : "";
          equipment.meterRead = item.meterRead !== undefined ? item.meterRead : '';
          equipment.serialNum = item.serialNum !== undefined ? item.serialNum : '';
          equipment.quantity = item.quantity !== undefined ? item.quantity : '';
          equipment.mediaLink = item.mediaLink !== undefined ? item.mediaLink : '';
          equipment.pricingModel = item.pricingModel !== undefined ? item.pricingModel : '';
          equipment.reservePrice = item.reservePrice !== undefined ? item.reservePrice : '';
          equipment.listingPrice = item.listingPrice !== undefined ? item.listingPrice : '';
          equipment.usageStatus = item.assetState.usageStateTransition.length > 0 ? item.assetState.usageStateTransition[0].usageStatus : "";
          equipment.needStatus = item.assetState.needStateTransition.length > 0 ? item.assetState.needStateTransition[0].needStatus : "";
          equipment.needStartDate = item.assetState.needStateTransition.length > 0 ? item.assetState.needStateTransition[0].startDate : '';
          equipment.needEndDate = item.assetState.needStateTransition.length > 0 ? item.assetState.needStateTransition[0].endDate : '';
          equipment.availabilityStatus = item.assetState.availabilityStateTransition.length > 0 ? item.assetState.availabilityStateTransition[0].availabilityStatus : "";
          equipment.availabilityStartDate = item.assetState.availabilityStateTransition.length > 0 ? item.assetState.availabilityStateTransition[0].startDate : "";
          equipment.availabilityEndDate = item.assetState.availabilityStateTransition.length > 0 ? item.assetState.availabilityStateTransition[0].endDate : "";
          equipment.locationStatus = item.assetState.locationStateTransition !== undefined ? item.assetState.locationStateTransition.locationStatus : "";
          equipment.createdBy = item.createdBy !== undefined ? item.createdBy._id : "";
          equipment.creatorName = item.creatorName !== undefined ? `${item.createdBy.contact.firstName[0]} ${item.createdBy.contact.lastName[0]}` : "";
          return equipment;
        })
      let sortedDeals;
      if (pageState?.sort === null) {
        sortedDeals = equipmentList.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      } else {
        sortedDeals = equipmentList
      }
      equipmentList = { equipmentList: sortedDeals, total: fetchedEquipmentList.equipment.total, skip: fetchedEquipmentList.equipment.skip };
      dispatch({ type: SET_EQUIPMENT_LIST, payload: equipmentList });
      // console.log("eqipList", equipmentList);
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch({ type: SET_EQUIPMENT_LIST, payload: [] });
      dispatch(setShowLoader(false));
    }
  }
}

export const getMatchedEquipmentList = (pageState, loggedInUserData) => {
  const role = loggedInUserData && loggedInUserData.user && loggedInUserData.user.roleSystem;
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const roleBase = role === "regular" ? {
        limit: pageState?.limit,
        page: pageState?.page,
        sort: pageState?.sort,
        search: pageState?.searchData,
      } : {
        limit: pageState?.limit,
        page: pageState?.page,
        sort: pageState?.sort,
        search: pageState?.searchData,
      };
      const fetchedEquipmentList = await client.service('equipment').find(
        {
          query: roleBase
        }
      );
      let equipmentList = [];
      if (fetchedEquipmentList.equipment && fetchedEquipmentList.equipment.data)
        equipmentList = fetchedEquipmentList.equipment.data.map(item => {
          const equipment = {};
          equipment.id = item._id;
          equipment.comparableRetailPrice = item.comparableRetailPrice !== undefined ? item.comparableRetailPrice[0] : '';
          equipment.industry = item.industry !== undefined ? item.industry[0] : '';
          equipment.majorCategory = item.majorCategory !== undefined ? item.majorCategory[0] : '';
          equipment.companyId = item.company !== null ? item.company._id : '';
          equipment.companyName = item.company !== null ? item.company.companyName : '';
          equipment.companyEquipmentID = item.companyEquipmentID ?? '';
          equipment.fleetType = item.fleetType !== undefined ? item.fleetType : '';
          equipment.qtrProjected = item.qtrProjected !== undefined ? item.qtrProjected : '';
          equipment.equipmentName = item.equipmentName ?? '';
          equipment.equipmentDescription = item.equipmentDescription ?? '';
          equipment.make = item.make ?? '';
          equipment.model = item.model ?? '';
          equipment.modelYear = item.modelYear ? new Date(item.modelYear).getFullYear() : "";
          equipment.meterRead = item.meterRead !== undefined ? item.meterRead : '';
          equipment.serialNum = item.serialNum !== undefined ? item.serialNum : '';
          equipment.quantity = item.quantity !== undefined ? item.quantity : '';
          equipment.mediaLink = item.mediaLink !== undefined ? item.mediaLink : '';
          equipment.pricingModel = item.pricingModel !== undefined ? item.pricingModel : '';
          equipment.reservePrice = item.reservePrice !== undefined ? item.reservePrice : '';
          equipment.listingPrice = item.listingPrice !== undefined ? item.listingPrice : '';
          equipment.usageStatus = item.assetState.usageStateTransition.length > 0 ? item.assetState.usageStateTransition[0].usageStatus : "";
          equipment.needStatus = item.assetState.needStateTransition.length > 0 ? item.assetState.needStateTransition[0].needStatus : "";
          equipment.needStartDate = item.assetState.needStateTransition.length > 0 ? item.assetState.needStateTransition[0].startDate : '';
          equipment.needEndDate = item.assetState.needStateTransition.length > 0 ? item.assetState.needStateTransition[0].endDate : '';
          equipment.availabilityStatus = item.assetState.availabilityStateTransition.length > 0 ? item.assetState.availabilityStateTransition[0].availabilityStatus : "";
          equipment.availabilityStartDate = item.assetState.availabilityStateTransition.length > 0 ? item.assetState.availabilityStateTransition[0].startDate : "";
          equipment.availabilityEndDate = item.assetState.availabilityStateTransition.length > 0 ? item.assetState.availabilityStateTransition[0].endDate : "";
          equipment.locationStatus = item.assetState.locationStateTransition !== undefined ? item.assetState.locationStateTransition.locationStatus : "";
          equipment.createdBy = item.createdBy !== undefined ? item.createdBy._id : "";
          equipment.creatorName = item.creatorName !== undefined ? `${item.createdBy.contact.firstName[0]} ${item.createdBy.contact.lastName[0]}` : "";
          return equipment;
        })
      let sortedDeals;
      if (pageState?.sort === null) {
        sortedDeals = equipmentList.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      } else {
        sortedDeals = equipmentList
      }
      let needEquipList = (sortedDeals || []).filter((x) => x.needStatus && !x.availabilityStatus)
      let availabilityEquipList = (sortedDeals || []).filter((x) => x.availabilityStatus && !x.needStatus)
      let result1 = (availabilityEquipList || []).filter(obj1 => needEquipList.some(obj2 => obj2.make === obj1.make && obj2.model === obj1.model && obj2.createdBy && obj2.modelYear === obj1.modelYear && new Date(obj1.availabilityStartDate) <= new Date(obj2.needEndDate) && new Date(obj1.availabilityEndDate) >= new Date(obj2.needStartDate)));//make,model,year,dates
      let result2 = (needEquipList || []).filter(obj1 => availabilityEquipList.some(obj2 => obj2.make === obj1.make && obj2.model === obj1.model && obj2.modelYear === obj1.modelYear));
      // const filteredData = result1.filter(obj =>
      //   dealList.some(item =>
      //     item.dealEquipment.some(objInArray => objInArray._id === obj.id) && item.payment === "Completed"
      //   )
      // );
      // const matchedEquip = result1.filter(
      //   (obj1) => filteredData.some((obj2) => obj1.id === obj2.id)
      // );
      equipmentList = { equipmentList: result1, buyerList: result2, total: fetchedEquipmentList.equipment.total, skip: fetchedEquipmentList.equipment.skip };
      dispatch({ type: SET_EQUIPMENT_LIST, payload: equipmentList });
      // console.log("result1", result1);
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch({ type: SET_EQUIPMENT_LIST, payload: [] });
      dispatch(setShowLoader(false));
    }
  }
}

export const getEquipmentById = (equipmentID) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getEquipment = await client.service(`equipment`).get(equipmentID, {})
      const data = getEquipment.equipment || {};
      const values = {
        equipmentID: data._id,
        comparableRetailPrice: data.comparableRetailPrice,
        industry: data.industry,
        majorCategory: data.majorCategory ? data.majorCategory : "",
        company: data.company ? data.company._id : "",
        companyName: data.company ? data.company.companyName : "",
        branchID: data.branchID,
        companyEquipmentID: data.companyEquipmentID ? data.companyEquipmentID : '',
        fleetType: data.fleetType,
        equipmentName: data.equipmentName,
        equipmentDescription: data.equipmentDescription,
        make: data.make ? data.make : '',
        model: data.model,
        modelYear: data.modelYear ? new Date(data.modelYear).getFullYear() : '',
        meterRead: data.meterRead ? data.meterRead : '',
        serialNum: data.serialNum ? data.serialNum : '',
        quantity: data.quantity,
        pricingModel: data.pricingModel,
        reservePrice: data.reservePrice ? data.reservePrice : "",
        listingPrice: data.listingPrice ? data.listingPrice : '',
        currentLocationCity: data.assetState.locationStateTransition ? data.assetState.locationStateTransition.currentLocationCity : "",
        locationLat: data.assetState.locationStateTransition ? data.assetState.locationStateTransition.locationLat : "",
        locationLong: data.assetState.locationStateTransition ? data.assetState.locationStateTransition.locationLong : "",
        assetDescription: data.assetState.assetDescription ? data.assetState.assetDescription : '',
        pricingTiers: data.pricingTiers ? data.pricingTiers : '',
        photoLink: data.photoLink ? data.photoLink : '',
        mediaLink: data.mediaLink ? data.mediaLink : '',
        qtrProjected: data.qtrProjected,
        usageStatus: data.assetState.usageStateTransition.length > 0 ? data.assetState.usageStateTransition[0].usageStatus : "",
        needStatus: data.assetState.needStateTransition.length > 0 ? data.assetState.needStateTransition[0].needStatus : "",
        needStartDate: data.assetState.needStateTransition.length > 0 ? data.assetState.needStateTransition[0].startDate : '',
        needEndDate: data.assetState.needStateTransition.length > 0 ? data.assetState.needStateTransition[0].endDate : '',
        availabilityStatus: data.assetState.availabilityStateTransition.length > 0 ? data.assetState.availabilityStateTransition[0].availabilityStatus : "",
        availabilityStartDate: data.assetState.availabilityStateTransition.length > 0 ? data.assetState.availabilityStateTransition[0].startDate : "",
        availabilityEndDate: data.assetState.availabilityStateTransition.length > 0 ? data.assetState.availabilityStateTransition[0].endDate : "",
        locationStatus: data.assetState.locationStateTransition ? data.assetState.locationStateTransition.locationStatus : "",
        createdBy: data.createdBy ? data.createdBy._id : "",
        createdByName: data.createdBy ? `${data.createdBy.contact.firstName[0]} ${data.createdBy.contact.lastName[0]}` : "",
        currentLocationCity: data.assetState ? data.assetState.locationStateTransition.currentLocationCity : "",
        locationLat: data.assetState ? data.assetState.locationStateTransition.locationLat : "",
        locationLong: data.assetState ? data.assetState.locationStateTransition.locationLong : "",
        pictures: data?.pictures,
      };

      dispatch({ type: SET_EQUIPMENT_DATA, payload: values })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// Function to edit (overwrite) an existing blob
export async function editBlob(containerName, subcontainer, file) {
  try {
    // Get a container client
    const containerClient = blobServiceClient.getContainerClient(containerName);

    const blobName = `${subcontainer}/${file.name}`;
    // Get a block blob client
    const blockBlobClient = containerClient.getBlockBlobClient(blobName);

    // Re-upload (overwrite) the blob with new content
    await blockBlobClient.uploadData(file, {
      blockSize: 4 * 1024 * 1024, // 4MB block size
      concurrency: 20,
      onProgress: ev => console.log(ev)
    });

    const fileUrl = blockBlobClient.url;
    console.log(`Edit of file '${file.name}' completed`);
    console.log(fileUrl);

    return { fileUrl, fileName: file.name, etag: blockBlobClient.etag, lastModified: blockBlobClient.lastModified };
  } catch (error) {
    if (error.statusCode === 404) {
      return false; // Blob does not exist
    }
    throw error; // Some other error occurred
  }
}

// Function to handle editing multiple blobs
async function editMultipleBlobs(containerName, subcontainer, files) {
  const editPromises = files.map(file => editBlob(containerName, subcontainer, file));
  const results = await Promise.all(editPromises);
  const urls = getBlobUrls(results);
  return urls;
}


const getPictureNames = (pictures) => {
  return pictures.map(pic => {
    // Check if the current item is an instance of File
    if (pic instanceof File) {
      return pic.name; // Return file name if it's a File object
    } else {
      return pic; // Return the picture string directly if it's not a File
    }
  });
};

const getFileTypes = (pictures) => {
  return pictures
    .filter(pic => pic instanceof File) // Filter only the File objects
};


export const editEquipment = (equipmentID, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const files = getFileTypes(payload?.pictures);
      payload.pictures = getPictureNames(payload?.pictures);
      payload.modelYear = payload.modelYear.toString();
      const result = await client.service('equipment').patch(equipmentID, payload);
      if (result) {
        editMultipleBlobs(
          'equipment', equipmentID, files
        );
      }
      dispatch(enqueueSnackbar({
        message: 'Equipment information EDITED And Saved.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/equipment'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit Equipment information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export const deleteEquipment = (equipmentID, equipmentList, totalCount) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('equipment').remove(equipmentID);
      const newList = [...equipmentList];
      const index = equipmentList.findIndex(item => item.id === equipmentID);
      if (index !== -1) { // Check if the item was found
        newList.splice(index, 1);
        const updatedTotalRecords = totalCount - 1; // Update totalRecords
        let listing = { equipmentList: newList, total: updatedTotalRecords }
        dispatch({ type: SET_EQUIPMENT_LIST, payload: listing });
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Equipment Information is deleted successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const deleteMatchedEquipment = (equipmentID, equipmentList) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('equipment').remove(equipmentID);
      const newList = [...equipmentList];
      const index = equipmentList.findIndex(item => item.id === equipmentID);
      if (index !== -1) { // Check if the item was found
        newList.splice(index, 1);
        let listing = { equipmentList: newList }
        dispatch({ type: SET_EQUIPMENT_LIST, payload: listing });
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Equipment Information is deleted successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const patchEquipment = (equipmentID, payload = {}) => {
  return async (dispatch, getState) => {
    const state = getState();
    const client = state.restClient.rest;
    try {
      dispatch(setShowLoader(true));
      const equipment = await client.service('equipment').patch(equipmentID, payload);
      const { equipmentList } = state.equipment || {};
      const newEquipmentList = equipmentList.map((item) => {
        if (item.id === equipment._id) {
          item.isActive = equipment.user.isActive;
        }
        return item;
      });
      dispatch({ type: SET_EQUIPMENT_LIST, payload: newEquipmentList });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Equipment ${equipment.user.isActive ? 'Created' : 'Not Created'} successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Equipment activation/deactivation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export default function reducer(
  state = {
    equipmentData: {},
    equipmentList: []
  },
  action
) {
  switch (action.type) {
    case SET_EQUIPMENT_DATA:
      return {
        ...state,
        equipmentData: action.payload
      };
    case SET_EQUIPMENT_LIST:
      return {
        ...state,
        equipmentList: action.payload
      };
    default:
      break;
  }
  return state;
} 