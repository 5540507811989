export const initialFormValues = {
  firstName: '',
  middleName: '',
  lastName: '',
  emailAddress: '',
  countryCode: '',
  mobileNumber: '',
  whatsAppNumber: '',
  smsNumber: ''
};

export const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'First Name is required' }
  },
  middleName: {
    presence: { allowEmpty: false, message: 'Middle Name is required' }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'Last Name is required' }
  },
  emailAddress: {
    presence: { allowEmpty: false, message: 'Email Address is required' }
  },
  countryCode: {
    presence: { allowEmpty: false, message: 'Country Code is required' }
  },
  mobileNumber: {
    presence: { allowEmpty: false, message: 'Mobile Number is required' }
  },
  whatsAppNumber: {
    presence: { allowEmpty: false, message: 'WhatsApp Number is required' }
  },
  smsNumber: {
    presence: { allowEmpty: false, message: 'Sms Number is required' }
  }
};

export const getPayload = (values) => {
  const payload = {
    firstName: values.firstName,
    middleName: values.middleName,
    lastName: values.lastName,
    emailAddress: values.emailAddress,
    countryCode: values.countryCode,
    mobileNumber: values.mobileNumber,
    whatsAppNumber: values.whatsAppNumber,
    smsNumber: values.smsNumber
  }
  return payload;
}

export const getEditPayload = (values) => {
  const payload = {
    'networkContacts.$.firstName': values.firstName,
    'networkContacts.$.middleName': values.middleName,
    'networkContacts.$.lastName': values.lastName,
    'networkContacts.$.emailAddress': values.emailAddress,
    'networkContacts.$.countryCode': values.countryCode,
    'networkContacts.$.mobileNumber': values.mobileNumber,
    'networkContacts.$.whatsAppNumber': values.whatsAppNumber,
    'networkContacts.$.smsNumber': values.smsNumber
  }
  return payload;
}
