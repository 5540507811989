import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  FormControl,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { submitCompanyRegisterForm, getCompanyById, editCompany } from '../../../reducers/CompanyReducer';
import validate from 'validate.js';
import useQuery from '../../../utils/useQuery';
import { schema, initialFormValues, getPayload, getEditPayload, stateCountryList, countryList } from './utils';
import { makeStyles } from '@mui/styles';

import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from '../../../styles/appStyles';

import {
  FormControlWrapper,
  FormButtonWrapper
} from '../../../styles/formStyles';
import { getVendorById } from '../../../reducers/VendorReducer';

validate.validators.IsValidZipCode = function (value) {
  var regex = /\d{5}(-\d{4})?/
  if (!validate.isEmpty(value) && !regex.test(value)) {
    return "Zip Code Is Not Valid";
  }
  return null
};

validate.validators.IsValidWebURL = function (value) {
  var regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');
  if (!validate.isEmpty(value) && !regex.test(value)) {
    return "Enter a valid Web URL";
  }
  return null
};

validate.validators.IsValidNumber = function (value) {
  var regex = /[1-9][0-9]?$|^100/
  if (!validate.isEmpty(value) && !regex.test(value)) {
    return "Number is required for this field"
  }
  return null
};

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  numberField: {
    '& input[type=number]': {
      '-moz-appearance': 'textfield'
    },
    '& input[type=number]::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0
    },
    '& input[type=number]::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0

    },
  }
}));


const CompanyRegisterComponent = (props) => {
  const { restClient: { rest }, companyData, getCompanyById, vendorData, getVendorById } = props;
  const classes = useStyles();
  const query = useQuery();
  const action = query.get('action');
  const companyId = query.get('id');
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);

  const [formState, setFormState] = useState({
    isValid: false,
    values: initialFormValues,
    touched: {},
    errors: {},
  });

  useEffect(() => {
    if (action === 'edit' && companyId && rest) {
      const getCompany = async () => {
        getCompanyById(companyId);
      }
      getCompany();
    }
  }, [action, companyId, getCompanyById, rest])

  useEffect(() => {
    if (action === 'edit' && companyData) {
      setFormState({
        isValid: false,
        values: companyData,
        touched: {},
        errors: {},
      });
    }
  }, [action, companyData]);
  useEffect(() => {
    if (action === 'vendorCompany' && companyId && rest) {
      const getVendor = async () => {
        getVendorById(companyId);
      };
      getVendor();
    }
  }, [action, companyId, rest, getVendorById]);

  function getStateText(countryCode, stateCode) {
    // Find the country object with the given countryCode
    const country = stateCountryList.find((c) => c.country.value === countryCode);

    if (country) {
      // Find the state object with the given stateCode within the country's states array
      if (country) {
        return country.states; // Return the state name (text)

      }
    }

    // Return a default value or handle the case where the country or state is not found
    return "Unknown";
  }

  useEffect(() => {
    if ((action === 'vendorCompany') && vendorData) {
      vendorData.country = vendorData.country == 'India' ? '91' : '1';
      vendorData.deptOrTitle = vendorData.deptOrTitle ?? '';
      let countryCode = vendorData.country;
      let vendorState = getStateText(countryCode, vendorData.state);
      setState(vendorState);
      setCountry(vendorData.country)
      setFormState({
        isValid: false,
        values: vendorData,
        touched: {},
        errors: {},
      });
    }
  }, [action, vendorData]);

  const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));
  const handleChange = (event) => {
    // event.persist();

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };
  const handleStateChange = (field, value) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [field]: value
      },
      touched: {
        ...prevFormState.touched,
        [field]: true
      }
    }));
  };

  const handleBlur = (event) => {
    const { name, value } = event.target;

    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [name]: value.trim(), // Trim leading and trailing spaces on blur
      },
      touched: {
        ...prevFormState.touched,
        [name]: true,
      },
    }));
  };

  const handleCountryChange = (event) => {
    // event.persist();
    const selectedCountry = event.target.value === '1' ? 'USA' : 'India';
    const state = stateCountryList.filter((un) => un.country.text === selectedCountry).map((un) => un.states).flat(1);
    setCountry(selectedCountry);
    setState(state);
    setFormState((prevFormState) => ({
      ...prevFormState,
      values: {
        ...prevFormState.values,
        [event.target.name]:
          event.target.type === 'checkbox'
            ? event.target.checked
            : event.target.value
      },
      touched: {
        ...prevFormState.touched,
        [event.target.name]: true
      }
    }));
  };

  useEffect(() => {
    const getCountry = async () => {
      try {
        let arr = [];
        if (action == 'edit') {
          if (companyId && companyData && rest) {
            arr = companyData.country.toString();
            const countryData = stateCountryList.filter((un) => un.country.value === arr);

            setState(countryData[0].states);
          }
        } else if (action == 'vendorCompany') {
          arr = companyData.country.toString();
        }
      } catch (e) {
        // console.log(e);
      }
    };
    getCountry();
  }, [action, companyId, companyData, rest]);

  useEffect(() => {
    const errors = validate(formState.values, schema, { fullMessages: false });

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {}
    }));

  }, [formState.values]);

  const handleStartCaseChange = (event) => {
    const { name, value } = event.target;
    handleStateChange(name, StartCase(value));
  }


  const StartCase = (text) => {
    return text
      .split(" ")
      .map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  };


  const checkErrors = () => {
    let isErrorExist = false;
    let touched = {};
    for (let key in formState.values) {
      if (formState.values.hasOwnProperty(key)) {
        touched = {
          ...touched,
          [key]: true
        }
      }
    }
    if (action === 'edit') {
      delete schema.branchID;
      delete schema.branchName;
    }

    const errors = validate(formState.values, schema, { fullMessages: false });
    if (errors) {
      isErrorExist = true;
    }

    setFormState((prevFormState) => ({
      ...prevFormState,
      isValid: !errors,
      errors: errors || {},
      touched: touched,
    }));

    return isErrorExist;
  }

  const submitForm = event => {
    event.preventDefault();
    if (!checkErrors()) {
      const { values } = formState;
      if (action === 'edit' && companyId) {
        const editPayload = getEditPayload(values);
        props.editCompany(companyId, editPayload.company);
      }
      else {
        const payload = getPayload(values);
        props.submitCompanyRegisterForm(payload);
      }
    }
  }


  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="lg">
              <Grid container spacing={5}>
                <Grid
                  item
                  xs={12}
                  lg={12}>
                  <Card >
                    <Header >
                      <HeaderText >
                        <h5>{action === 'edit' ? 'View / Edit Company' : 'Create New Company'}</h5>
                      </HeaderText>
                    </Header>
                    <CardContent >
                      <form onSubmit={submitForm}>
                        <Grid container spacing={4}>
                          <Grid item lg={6} xs={12}>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-CompanyName"
                                  id="companyName"
                                  name="companyName"
                                  label="Company Name *"
                                  placeholder="Enter Company Name"
                                  value={formState.values.companyName}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('companyName')}
                                  helperText={
                                    hasError('companyName') ? formState.errors.companyName[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  multiline
                                  variant="outlined"
                                  data-test-id="Create-Company-Description"
                                  id="description"
                                  label="Description *"
                                  name="description"
                                  placeholder="Enter Description"
                                  value={formState.values.description}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('description')}
                                  helperText={
                                    hasError('description') ? formState.errors.description[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  multiline
                                  variant="outlined"
                                  data-test-id="Create-Company-companyEmailAddress"
                                  id="companyEmailAddress"
                                  label="Company Email Address *"
                                  name="companyEmailAddress"
                                  placeholder="Enter Company Email Address"
                                  value={formState.values.companyEmailAddress}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('companyEmailAddress')}
                                  helperText={
                                    hasError('companyEmailAddress') ? formState.errors.companyEmailAddress[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  multiline
                                  variant="outlined"
                                  data-test-id="Create-Company-Web URL"
                                  id="webURL"
                                  label="Company Web Url *"
                                  name="webURL"
                                  placeholder="Enter Company Web Url"
                                  value={formState.values.webURL}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('webURL')}
                                  helperText={
                                    hasError('webURL') ? formState.errors.webURL[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                              >
                                <InputLabel id="dominantRole-select-outlined-label">Select Company Dominant Role *</InputLabel>
                                <Select
                                  error={hasError('dominantRole')}
                                  labelId="dominantRole-select-outlined-label"
                                  id="dominantRole-select-outlined"
                                  label="Select Company Dominant Role"
                                  data-test-id="Create-Company-DominantRole"
                                  placeholder="Dominant Role"
                                  value={formState.values.dominantRole}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'dominantRole',
                                    id: 'dominantRole',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Dominant Role</MenuItem>
                                  {[{ name: 'User' }, { name: 'Trader' }, { name: 'Service Provider' }].map((dominantRole) => <MenuItem value={dominantRole.name}>{dominantRole.name}</MenuItem>)}
                                </Select>
                                {hasError('dominantRole') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('dominantRole')}>
                                    {formState.errors.dominantRole[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            {/* <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                              >
                                <InputLabel id="traderSubType-select-outlined-label">Select Trader SubType Role *</InputLabel>
                                <Select
                                  error={hasError('traderSubType')}
                                  labelId="traderSubType-select-outlined-label"
                                  id="traderSubType-select-outlined"
                                  label="Select traderSubType"
                                  data-test-id="Create-Company-traderSubType"
                                  placeholder="traderSubType"
                                  value={formState.values.traderSubType}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'traderSubType',
                                    id: 'traderSubType',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select traderSubType</MenuItem>
                                  {[{ name: 'Buyer' }, { name: 'Seller' }].map((traderSubType) => <MenuItem value={traderSubType.name}>{traderSubType.name}</MenuItem>)}
                                </Select>
                                {hasError('traderSubType') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('traderSubType')}>
                                    {formState.errors.traderSubType[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper> */}
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-industry"
                                  id="industry"
                                  label="Industry"
                                  name="industry"
                                  placeholder="Enter Industry"
                                  value={formState.values.industry}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('industry')}
                                  helperText={
                                    hasError('industry') ? formState.errors.industry[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-deptOrTitle"
                                  id="deptOrTitle"
                                  label="Dept Or Title"
                                  name="deptOrTitle"
                                  placeholder="Enter Dept Or Title"
                                  value={formState.values.deptOrTitle}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('deptOrTitle')}
                                  helperText={
                                    hasError('deptOrTitle') ? formState.errors.deptOrTitle[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-annualRevenue"
                                  id="annualRevenue"
                                  label="annualRevenue"
                                  name="annualRevenue"
                                  placeholder="Enter annualRevenue"
                                  value={formState.values.annualRevenue}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('annualRevenue')}
                                  helperText={
                                    hasError('annualRevenue') ? formState.errors.annualRevenue[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper hidden={action === 'edit'}>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-BranchName"
                                  id="branchName"
                                  label="Branch Name *"
                                  name="branchName"
                                  placeholder="Enter  branchName"
                                  value={formState.values.branchName}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('branchName')}
                                  helperText={
                                    hasError('branchName') ? formState.errors.branchName[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper hidden={action === 'edit'}>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-BranchID"
                                  id="branchID"
                                  label="Branch ID *"
                                  name="branchID"
                                  placeholder="Enter  branchID *"
                                  value={formState.values.branchID}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('branchID')}
                                  helperText={
                                    hasError('branchID') ? formState.errors.branchID[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-WebURL"
                                  id="webURL"
                                  label="Company URL"
                                  name="webURL"
                                  placeholder="Enter companyURL"
                                  value={formState.values.webURL}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={hasError('webURL')}
                                  helperText={
                                    hasError('webURL') ? formState.errors.webURL[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-TaxID"
                                  id="taxId"
                                  label="Tax ID"
                                  name="taxId"
                                  placeholder="Enter taxId"
                                  value={formState.values.taxId}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('taxId')}
                                  helperText={
                                    hasError('taxId') ? formState.errors.taxId[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-contactLinkedin"
                                  id="contactLinkedin"
                                  label="Contact Linkedin"
                                  name="contactLinkedin"
                                  placeholder="Enter contactLinkedin"
                                  value={formState.values.contactLinkedin}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('contactLinkedin')}
                                  helperText={
                                    hasError('contactLinkedin') ? formState.errors.contactLinkedin[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-FirstLine"
                                  id="firstLine"
                                  label="Company Address1 *"
                                  name="firstLine"
                                  placeholder="Enter Company Address1 *"
                                  value={formState.values.firstLine}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('firstLine')}
                                  helperText={
                                    hasError('firstLine') ? formState.errors.firstLine[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-SecondLine"
                                  id="secondLine"
                                  label="Company Address2"
                                  name="secondLine"
                                  placeholder="Enter Company Address2"
                                  value={formState.values.secondLine}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('secondLine')}
                                  helperText={
                                    hasError('secondLine') ? formState.errors.secondLine[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                              >
                                <InputLabel id="country-select-outlined-label">Country *</InputLabel>
                                <Select
                                  error={hasError('country')}
                                  labelId="country-select-outlined-label"
                                  id="country-select-outlined"
                                  label="Country"
                                  data-test-id="create-company-country"
                                  value={formState.values.country}
                                  onChange={handleCountryChange}
                                  inputProps={{
                                    name: 'country',
                                    id: 'country',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select country</MenuItem>
                                  {(countryList || []).map((country) => <MenuItem key={country.value} value={country.value}>{country.text}</MenuItem>)}
                                </Select>
                                {hasError('country') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('country')}>
                                    {formState.errors.country[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'
                              >
                                <InputLabel id="create-company-state-select-outlined-label">State *</InputLabel>
                                <Select
                                  error={hasError('state')}
                                  helperText={
                                    hasError('state') ? formState.errors.state[0] : null
                                  }
                                  labelId="create-company-state-select-outlined-label"
                                  id="create-company-state-select-outlined"
                                  label="State"
                                  value={formState.values.state}
                                  data-test-id="Create-Company-State"
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'state',
                                    id: 'state',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select State</MenuItem>
                                  {(state || []).map((state) => <MenuItem key={state.value} value={state.value}>{state.text}</MenuItem>)}
                                </Select>
                                {hasError('state') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('state')}>
                                    {formState.errors.state[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-City"
                                  id="city"
                                  label="City *"
                                  name="city"
                                  placeholder="Enter city"
                                  value={formState.values.city}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('city')}
                                  helperText={
                                    hasError('city') ? formState.errors.city[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  className={classes.numberField}
                                  variant="outlined"
                                  data-test-id="Create-Company-Zipcode"
                                  id="zipcode"
                                  label="Zip Code *"
                                  name="zipcode"
                                  type='number'
                                  placeholder="Enter Zip Code"
                                  value={formState.values.zipcode}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('zipcode')}
                                  helperText={
                                    hasError('zipcode') ? formState.errors.zipcode[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControl component="fieldset">
                              <FormControlLabel
                                checked={formState.values.automaticDealCreation}
                                onChange={handleChange}
                                type="checkbox"
                                name="automaticDealCreation"
                                control={<Checkbox color="primary" />}
                                label="Automatic Deal Creation"
                                labelPlacement="end"
                              />
                            </FormControl>
                          </Grid>
                          <Grid item lg={6} xs={12}>
                            <FormControlWrapper hidden={action === 'edit'}>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-Region"
                                  id="region"
                                  label="Region"
                                  name="region"
                                  placeholder="Enter  region"
                                  value={formState.values.region}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('region')}
                                  helperText={
                                    hasError('region') ? formState.errors.region[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-Long"
                                  id="long"
                                  label="Company Location Longitude"
                                  name="long"
                                  type='text'
                                  placeholder="Enter Longitude"
                                  value={formState.values.long}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('long')}
                                  helperText={
                                    hasError('long') ? formState.errors.long[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-Lat"
                                  id="lat"
                                  label="Company Location Latitude"
                                  name="lat"
                                  type='text'
                                  placeholder="Enter Latitude"
                                  value={formState.values.lat}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('lat')}
                                  helperText={
                                    hasError('lat') ? formState.errors.lat[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-PhoneNumber"
                                  id="phoneNumber"
                                  label="Company Phone Number *"
                                  name="phoneNumber"
                                  type='number'
                                  placeholder="Enter Company Phone Number"
                                  className={classes.numberField}
                                  value={formState.values.phoneNumber}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={hasError('phoneNumber')}
                                  helperText={
                                    hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            {/* <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  type="email"
                                  variant="outlined"
                                  data-test-id="Create-Company-emailAddress"
                                  id="emailAddress"
                                  label="Email Address *"
                                  name="emailAddress"
                                  placeholder="Enter Email Address"
                                  value={formState.values.emailAddress}
                                  onChange={handleChange}
                                  error={hasError('emailAddress')}
                                  helperText={
                                    hasError('emailAddress') ? formState.errors.emailAddress[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper> */}
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-facebook"
                                  id="facebook"
                                  label="Company Facebook"
                                  name="facebook"
                                  type='text'
                                  placeholder="Enter Facebook"
                                  value={formState.values.facebook}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('facebook')}
                                  helperText={
                                    hasError('facebook') ? formState.errors.facebook[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-twitter"
                                  id="twitter"
                                  label="Company twitter"
                                  name="twitter"
                                  type='text'
                                  placeholder="Enter twitter"
                                  value={formState.values.twitter}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('twitter')}
                                  helperText={
                                    hasError('twitter') ? formState.errors.twitter[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-instagram"
                                  id="instagram"
                                  label="Company instagram"
                                  name="instagram"
                                  type='text'
                                  placeholder="Enter instagram"
                                  value={formState.values.instagram}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('instagram')}
                                  helperText={
                                    hasError('instagram') ? formState.errors.instagram[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-linkedin"
                                  id="linkedin"
                                  label="Company linkedin"
                                  name="linkedin"
                                  type='text'
                                  placeholder="Enter linkedin"
                                  value={formState.values.linkedin}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('linkedin')}
                                  helperText={
                                    hasError('linkedin') ? formState.errors.linkedin[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-youtube"
                                  id="youtube"
                                  label="Company youtube"
                                  name="youtube"
                                  type='text'
                                  placeholder="Enter youtube"
                                  value={formState.values.youtube}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('youtube')}
                                  helperText={
                                    hasError('youtube') ? formState.errors.youtube[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-searchLinkedinCompany"
                                  id="searchLinkedinCompany"
                                  label="Search Linkedin Company"
                                  name="searchLinkedinCompany"
                                  type='text'
                                  placeholder="Enter Search Linkedin Company"
                                  value={formState.values.searchLinkedinCompany}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('searchLinkedinCompany')}
                                  helperText={
                                    hasError('searchLinkedinCompany') ? formState.errors.searchLinkedinCompany[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-linkedinCompanyPageUrl"
                                  id="linkedinCompanyPageUrl"
                                  label="Company Linkedin Company Page Url"
                                  name="linkedinCompanyPageUrl"
                                  type='text'
                                  placeholder="Enter Linkedin Company Page Url"
                                  value={formState.values.linkedinCompanyPageUrl}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('linkedinCompanyPageUrl')}
                                  helperText={
                                    hasError('linkedinCompanyPageUrl') ? formState.errors.linkedinCompanyPageUrl[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-stockSymbol"
                                  id="stockSymbol"
                                  label="Company Stock Symbol"
                                  name="stockSymbol"
                                  type='text'
                                  placeholder="Enter Stock Symbol"
                                  value={formState.values.stockSymbol}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('stockSymbol')}
                                  helperText={
                                    hasError('stockSymbol') ? formState.errors.stockSymbol[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-numberOfEmployees"
                                  id="numberOfEmployees"
                                  label="Company Number Of Employees"
                                  name="numberOfEmployees"
                                  type='number'
                                  placeholder="Enter Number Of Employees"
                                  className={classes.numberField}
                                  value={formState.values.numberOfEmployees}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('numberOfEmployees')}
                                  helperText={
                                    hasError('numberOfEmployees') ? formState.errors.numberOfEmployees[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper hidden={action === 'edit'}>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-companyOwner"
                                  id="companyOwner"
                                  label="Company Owner"
                                  name="companyOwner"
                                  type='text'
                                  placeholder="Enter Company Owner"
                                  value={formState.values.companyOwner}
                                  onChange={handleStartCaseChange}
                                  onBlur={handleBlur}
                                  error={hasError('companyOwner')}
                                  helperText={
                                    hasError('companyOwner') ? formState.errors.companyOwner[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper hidden={action === 'edit'}>
                              <FormControl variant="outlined" className='w-100'
                              >
                                <InputLabel id="roleType-select-outlined-label">Company Primary Contact RoleType *</InputLabel>
                                <Select
                                  error={hasError('roleType')}
                                  labelId="roleType-select-outlined-label"
                                  id="roleType-select-outlined"
                                  label="Company Primary Contact RoleType"
                                  data-test-id="Create-Company-RoleType"
                                  placeholder="Role Type"
                                  value={formState.values.roleType}
                                  onChange={handleChange}
                                  inputProps={{
                                    name: 'roleType',
                                    id: 'roleType',
                                  }}
                                >
                                  <MenuItem value="0" disabled>Select Role Type</MenuItem>
                                  {[{ name: 'Trader' }, { name: 'User' }, { name: 'Service Provider' }, { name: 'Executive' }].map((roleType) => <MenuItem value={roleType.name}>{roleType.name}</MenuItem>)}
                                </Select>
                                {hasError('roleType') && (
                                  <FormHelperText htmlFor="form-selector" error={hasError('roleType')}>
                                    {formState.errors.roleType[0]}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper hidden={action === 'edit'}>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-FirstName"
                                  id="firstName"
                                  label="Company Primary Contact First Name *"
                                  name="firstName"
                                  placeholder="Enter First Name"
                                  value={formState.values.firstName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={hasError('firstName')}
                                  helperText={
                                    hasError('firstName') ? formState.errors.firstName[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper hidden={action === 'edit'}>
                              <FormControl className="w-100">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-LastName"
                                  id="lastName"
                                  label="Company Primary Contact Last Name *"
                                  name="lastName"
                                  placeholder="Enter Last Name"
                                  value={formState.values.lastName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={hasError('lastName')}
                                  helperText={
                                    hasError('lastName') ? formState.errors.lastName[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper hidden={action === 'edit'}>
                              <FormControl className="w-100">
                                <TextField
                                  // required
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-Email"
                                  id="email"
                                  label="Primary Contact Email ID *"
                                  name="email"
                                  type="text"
                                  placeholder="Enter Email"
                                  value={formState.values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={hasError('email')}
                                  helperText={
                                    hasError('email') ? formState.errors.email[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper hidden={action === 'edit'}>
                              <FormControl className="w-100">
                                <TextField
                                  // required
                                  fullWidth
                                  variant="outlined"
                                  data-test-id="Create-Company-Phone"
                                  id="phone"
                                  label="Primary Contact Phone Number"
                                  name="phone"
                                  type='number'
                                  placeholder="Enter phone"
                                  className={classes.numberField}
                                  value={formState.values.phone}
                                  onChange={handleChange}
                                  error={hasError('phone')}
                                  helperText={
                                    hasError('phone') ? formState.errors.phone[0] : null
                                  }
                                />
                              </FormControl>
                            </FormControlWrapper>
                          </Grid>
                          <Grid container className={classes.root} spacing={0}>
                            <Grid item xs={12}>
                              <Grid container justifyContent="center" spacing={5}>
                                <Grid item>
                                  <FormButtonWrapper>
                                    <Link to="/company" className='linkStyle'>
                                      <Button
                                        type="submit"
                                        color="secondary"
                                        data-test-id="Create-Brand-Cancel"
                                        variant="contained"
                                        size="large"
                                        className="my-2">
                                        cancel
                                      </Button>
                                    </Link>
                                  </FormButtonWrapper>
                                </Grid>
                                <Grid item>
                                  <FormButtonWrapper>
                                    <Button
                                      type="submit"
                                      color="primary"
                                      variant="contained"
                                      data-test-id="Create-Company-Submit"
                                      size="large"
                                      className="Buttons">
                                      {action === 'edit' ? 'Update' : 'Submit'}
                                    </Button>
                                  </FormButtonWrapper>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
};

const mapStateToProps = state => ({
  restClient: state.restClient,
  companyData: state.company.companyData,
  vendorData: state.vendor && state.vendor.vendorData,
})

export default connect(mapStateToProps, { submitCompanyRegisterForm, getCompanyById, editCompany, getVendorById })(CompanyRegisterComponent);
