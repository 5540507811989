import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { setUserAuthenticated } from "../reducers/UserReducer";

const InactivityDetector = ({ children, timeout }) => {
    const dispatch = useDispatch();

    const inactivityTimeout = useRef(null);
    const handleInactivity = () => {
        // Handle inactivity here
        console.log('User has been inactive for 15 minutes');
        dispatch(setUserAuthenticated(false));
    };

    const resetTimer = () => {
        clearTimeout(inactivityTimeout.current);
        inactivityTimeout.current = setTimeout(handleInactivity, timeout);
    };

    useEffect(() => {
        resetTimer();

        window.addEventListener('mousemove', resetTimer);
        window.addEventListener('keydown', resetTimer);

        return () => {
            clearTimeout(inactivityTimeout.current);
            window.removeEventListener('mousemove', resetTimer);
            window.removeEventListener('keydown', resetTimer);
        };
    }, [timeout, handleInactivity]);

    return <>{children}</>;
};

InactivityDetector.propTypes = {
    children: PropTypes.node.isRequired,
    timeout: PropTypes.number.isRequired,
};

export default InactivityDetector;