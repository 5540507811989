import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { getContactList } from '../../reducers/EspoCrmReducer';
import { getUserList } from "../../reducers/UserReducer";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridPagination } from '@mui/x-data-grid';
import { Card, Typography, IconButton, Grid, Box, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import AddBoxIcon from '@mui/icons-material/AddBox';
import SearchBar from '../../utils/Search';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles({
  actions: {
    color: "#3E5272",
  },
  root: {
    maxWidth: "100%",
    margin: "20px",
  },
  link: {
    textDecoration: "none",
    color: "#696969",
    "&:hover": {
      backgroundColor: "rgb(180, 180, 180)",
    },
  },
  header: {
    maxWidth: "700px",
  },
  create: {
    color: "#3BB143",
  },
  childDiv: {
    paddingTop: "10px",
  },
  tableheader: {
    marginTop: "-3rem"
  },
  cardStyle: {
    height: "430px", 
    width: "1314px",
    marginLeft: "-5rem", 
    marginBottom: "-1rem"
  }
});

const CrmContactList = (props) => {
  const {
    contactList = [],
    userList = [],
    restClient: { rest },
    getContactList,
    getUserList,
    totalCount
  } = props;
  const classes = useStyles();
  const history = useHistory();
  useEffect(() => {
    const getList = async () => {
      try {
        if (rest) {
          await getContactList();
        }
      } catch (e) {
        console.log(e);
      }
    };
    getList();
  }, [rest, getContactList]);

  useEffect(() => {
    const getList = async () => {
      try {
        if (rest) {
          await getUserList();
        }
      } catch (e) {
        console.log(e);
      }
    };
    getList();
  }, [rest, getUserList]);

  const [pageState, setPageState] = useState({
    page: 1,
    limit: 100,
    sort: null,
    searchData: '',
  });
  const [rowCountState, setRowCountState] = React.useState(totalCount);
  const [search, setSearch] = React.useState('');

  React.useEffect(() => {
    setRowCountState((prevRowCountState) =>
      totalCount !== undefined ? totalCount : prevRowCountState
    );
  }, [totalCount, setRowCountState]);

  useEffect(() => {
    const fetchData = async () => {
      await getContactList(
        pageState.page,
        pageState.sort,
        pageState.searchData,
        pageState.limit
      );
      await getContactList(pageState.page, pageState.sort, pageState.searchData, pageState.limit);
    };
    fetchData();
  }, [
    pageState.page,
    pageState.sort,
    pageState.searchData,
    pageState.limit,
    getContactList,
  ]);

  // console.log("userList", contactList);

  const handleUserCreate = (event, user) => {
    history.push(`/users/create?action=espoContact&id=${user.id}`);
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 250,
    },
    {
      field: "email",
      headerName: "Email Address",
      type: "string",
      width: 270,
    },
    {
      field: "phone",
      headerName: "Phone Number",
      type: "string",
      width: 150,
    },
    {
      field: "addressCity",
      headerName: "City",
      width: 120,
    },
    {
      field: "addressState",
      headerName: "State",
      width: 120,
    },
    {
      field: "addressCountry",
      headerName: "Country",
      width: 100,
    },
    {
      field: "addressPostalCode",
      headerName: "Postal Code",
      width: 120,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      renderCell: (rowData) => {
        if (userList.some((i) => rowData.row.email === i.email)) {
          return (
            <div>
              <Tooltip title="User already exists">
                <IconButton>
                  <CheckCircleRoundedIcon className={classes.create} />
                </IconButton>
              </Tooltip>
            </div>
          );
        } else {
          return (
            <div>
              <Tooltip title="Create New Contact">
                <IconButton
                  sx={{ color: "#3E5272" }}
                  onClick={(event) => {
                    handleUserCreate(event, rowData.row);
                  }}
                >
                  <AddBoxIcon />
                </IconButton>
              </Tooltip>
            </div>
          );
        }
      },
    },
  ];


  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              CONTACTLIST
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Grid container spacing={0}>
              <Grid item lg={6} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  <GridToolbarContainer>
                    <GridToolbarExport />
                  </GridToolbarContainer>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  }

  const onFilterChange = (event) => {
    // Here you save the data you need from the filter model and perform server-side search/filtering
    // Once the server-side search is completed, you can update the searchInput state with the new value

    // Example: Simulating server-side search delay with setTimeout
    setSearch(event.target.value);
    // if (search.length > 4 || search.trim() == '') {
    if (event?.target?.value?.length >= 3)
      setTimeout(() => {
        setPageState((old) => ({ ...old, searchData: event.target.value }));
      }, 2000);
    if (event?.target?.value?.length < 1)
      setPageState((old) => ({ ...old, searchData: '' }));
    // }
  };
  // console.log(pageState.search);
  const handleClearText = () => {
    setSearch('');
    setPageState((old) => ({ ...old, searchData: '' }));
  };

  return (
    <div className={classes.tableheader}>
      <SearchBar
        searchText={search}
        filterChange={onFilterChange}
        onClickFunc={handleClearText}
      />
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rowCount={rowCountState}
            paginationMode="server"
            rowsPerPageOptions={[100]}
            pagination
            pageSize={pageState.pageSize}
            onPageChange={(newPage) => {
              setPageState((old) => ({ ...old, page: newPage + 1 }));
            }}
            rows={contactList || []}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            sortingMode="server"
            onSortModelChange={(col) => {
              if (col.length > 0) {
                if (col[0].sort === "asc") {
                  setPageState((old) => ({ ...old, sort: `${col[0].field}` }));
                } else {
                  setPageState((old) => ({ ...old, sort: `-${col[0].field}` }));
                }
              } else {
                setPageState((old) => ({ ...old, sort: null }));
              }
            }}
            sx={{
              "& .css-ptiqhd-MuiSvgIcon-root": {
                color: "#ee9907",
              },
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }}
            // density='standard'
            // components={{ Toolbar: DataGridTitle }}
            components={{
              Toolbar: DataGridTitle,
              Pagination: GridPagination,
            }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  contactList: state.espoCrm && state.espoCrm.contactList.contactList,
  userList: state.user && state.user.userList.userList,
  totalCount: state.espoCrm && state.espoCrm.contactList.total,
  limit: state.espoCrm && state.espoCrm.contactList.limit,
});
export default connect(mapStateToProps, { getContactList, getUserList })(
  CrmContactList
);
