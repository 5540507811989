import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import useQuery from '../../utils/useQuery';
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from '../../styles/appStyles';
import {
  FormControlWrapper,
  FormButtonWrapper
} from '../../styles/formStyles';
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
  InputLabel,
  FormControl,
} from '@mui/material';
import { getDealById } from '../../reducers/DealReducer';

const ViewDeal = props => {
  const { restClient: { rest }, dealData, getDealById } = props;
  const query = useQuery();
  const dealID = query.get('id');

  useEffect(() => {
    if (dealID && rest) {
      const getDeal = async () => {
        getDealById(dealID);
      }
      getDeal();
    }
  }, [dealID, rest, getDealById])

  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="xl">
              <Grid container spacing={5}>
                <Grid
                  item
                  xs={12}
                  lg={12}>
                  <Card className='cardView'>
                    <Header >
                      <HeaderText >
                        <h5>{'View Deal Details'}</h5>
                      </HeaderText>
                    </Header>
                    <CardContent >
                      <form className='formSpacing'>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="dealName" data-test-id="View-Deal-Name" name="dealName">Deal Name:<div className='deal'><b>{dealData.dealName}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="dealDescription" name="dealDescription" data-test-id="View-Deal-dealDescription">Deal Description:<div className='deal'><b>{dealData.dealDescription}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="dealBrand" name="dealBrand" data-test-id="View-Deal-dealBrand">Equipment Name:<div className='deal'><b>{dealData.dealBrand}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="industry" name="industry" data-test-id="View-Deal-industry">Industry:<div className='deal'><b>{dealData.industry}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="dealStartDate" name="dealStartDate" data-test-id="View-Deal-dealStartDate">Deal Start Date:<div className='deal'><b>{dealData.dealStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="dealEndDate" name="dealEndDate" data-test-id="View-Deal-dealEndDate">Deal End Date:<div className='deal'><b>{dealData.dealEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="creatorCompany" name="creatorCompany" data-test-id="View-Deal-creatorCompany">Creator Company:<div className='deal'><b>{dealData.creatorCompany}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="creatorPeople" name="creatorPeople" data-test-id="View-Deal-creatorPeople">Creator People:<div className='deal'><b>{dealData.creatorPeople}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="dealNetwork" name="dealNetwork" data-test-id="View-Deal-dealNetwork">Deal Network:<div className='deal'><b>{dealData.dealNetwork}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="email" name="email" data-test-id="View-Deal-email">Model:<div className='deal'><b>{dealData.email}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="firstName" name="firstName" data-test-id="View-Deal-firstName">First Name:<div className='deal'><b>{dealData.firstName}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="lastName" name="lastName" data-test-id="View-Deal-lastName">Last Name:<div className='deal'><b>{dealData.lastName}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="sendDate" name="sendDate" data-test-id="View-Deal-sendDate">Send Date:<div className='deal'><b>{dealData.sendDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="invitedUser" name="invitedUser" data-test-id="View-Deal-invitedUser">Invited User:<div className='deal'><b>{dealData.invitedUser}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="inviteStatus" name="inviteStatus" data-test-id="View-Deal-inviteStatus">Invite Status:<div className='deal'><b>{dealData.inviteStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="dealParticipantStartDate" name="dealParticipantStartDate" data-test-id="View-Deal-dealParticipantStartDate">Deal Participant Start Date:<div className='deal'><b>{dealData.dealParticipantStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="dealParticipantEndDate" name="dealParticipantEndDate" data-test-id="View-Deal-dealParticipantEndDate">Deal Participant End Date:<div className='deal'><b>{dealData.dealParticipantEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="participant" name="participant" data-test-id="View-Deal-participant">Participant:<div className='deal'><b>{dealData.participant}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>

                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="channelName" name="channelName" data-test-id="View-Deal-channelName">Channel Name:<div className='deal'><b>{dealData.channelName}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="channelType" name="channelType" data-test-id="View-Deal-channelType">Channel Type:<div className='deal'><b>{dealData.channelType}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="channelDescription" name="channelDescription" data-test-id="View-Deal-channelDescription">Channel Description:<div className='deal'><b>{dealData.channelDescription}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="channelDescriptionStartDate" name="channelDescriptionStartDate" data-test-id="View-Deal-channelDescriptionStartDate">Channel Description Start Date:<div className='deal'><b>{dealData.channelDescriptionStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="channelDescriptionEndDate" name="channelDescriptionEndDate" data-test-id="View-Deal-channelDescriptionEndDate">Channel Description End Date:<div className='deal'><b>{dealData.channelDescriptionEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="numMessages" name="numMessages" data-test-id="View-Deal-numMessages">Num Messages:<div className='deal'><b>{dealData.numMessages}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="cost" name="cost" data-test-id="View-Deal-cost">Cost:<div className='deal'><b>${dealData.cost}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="messagingChannelStatus" name="messagingChannelStatus" data-test-id="View-Deal-messagingChannelStatus">Messaging Channel Status:<div className='deal'><b>{dealData.messagingChannelStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="channelParticipants" name="channelParticipants" data-test-id="View-Deal-channelParticipants">Channel Participants:<div className='deal'><b>{dealData.channelParticipants}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="inviteToCommunicate" name="inviteToCommunicate" data-test-id="View-Deal-inviteToCommunicate">Invite To Communicate:<div className='deal'><b>{dealData.inviteToCommunicate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="inviteToCommunicateStartDate" name="inviteToCommunicateStartDate" data-test-id="View-Deal-inviteToCommunicateStartDate">Invite To Communicate Start Date:<div className='deal'><b>{dealData.inviteToCommunicateStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="inviteToCommunicateEndDate" name="inviteToCommunicateEndDate" data-test-id="View-Deal-inviteToCommunicateEndDate">Invite To Communicate End Date:<div className='deal'><b>{dealData.inviteToCommunicateEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="participantStatus" name="participantStatus" data-test-id="View-Deal-participantStatus">Participant Status:<div className='deal'><b>{dealData.participantStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="buyerId" name="buyerId" data-test-id="View-Deal-buyerId">Buyer Id:<div className='deal'><b>{dealData.buyer}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="buyerPrice" name="buyerPrice" data-test-id="View-Deal-buyerPrice">Buyer Price:<div className='deal'><b>{dealData.buyerPrice}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="sellerId" name="sellerId" data-test-id="View-Deal-sellerId">Seller Id:<div className='deal'><b>{dealData.seller}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="acceptedPrice" name="acceptedPrice" data-test-id="View-Deal-acceptedPrice">Accepted Price:<div className='deal'><b>{dealData.acceptedPrice}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="quantity" name="quantity" data-test-id="View-Deal-quantity">Quantity:<div className='deal'><b>{dealData.quantity}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="totalPrice" name="totalPrice" data-test-id="View-Deal-totalPrice">Total Price:<div className='deal'><b>{dealData.totalPrice}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="agreementSignedDate" name="agreementSignedDate" data-test-id="View-Deal-agreementSignedDate">Agreement Signed Date:<div className='deal'><b>{dealData.agreementSignedDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="closingSaleStatus" name="closingSaleStatus" data-test-id="View-Deal-closingSaleStatus">Closing Sale Status:<div className='deal'><b>{dealData.closingSaleStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="closingType" name="closingType" data-test-id="View-Deal-closingType">Closing Type:<div className='deal'><b>{dealData.closingType}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="ClosingStartDate" name="ClosingStartDate" data-test-id="View-Deal-ClosingStartDate">Closing Start Date:<div className='deal'><b>{dealData.ClosingStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="ClosingEndDate" name="ClosingEndDate" data-test-id="View-Deal-ClosingEndDate">Closing End Date:<div className='deal'><b>{dealData.ClosingEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="dealClosingEquipment" name="dealClosingEquipment" data-test-id="View-Deal-dealClosingEquipment">Deal Closing Equipment:<div className='deal'><b>{dealData.dealClosingEquipment}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="equipmentQuantity" name="equipmentQuantity" data-test-id="View-Deal-equipmentQuantity">Equipment Quantity:<div className='deal'><b>{dealData.equipmentQuantity}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="paymentMethod" name="paymentMethod" data-test-id="View-Deal-paymentMethod">Payment Method:<div className='deal'><b>{dealData.paymentMethod}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="paymentDate" name="paymentDate" data-test-id="View-Deal-paymentDate">Payment Date:<div className='deal'><b>{dealData.paymentDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="paymentStatus" name="paymentStatus" data-test-id="View-Deal-paymentStatus">Payment Status:<div className='deal'><b>{dealData.paymentStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="deliveryDate" name="deliveryDate" data-test-id="View-Deal-deliveryDate">Delivery Date:<div className='deal'><b>{dealData.deliveryDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="deliverySigned" name="deliverySigned" data-test-id="View-Deal-deliverySigned">Delivery Signed:<div className='deal'><b>{dealData.deliverySigned}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="deliveryStatus" name="deliveryStatus" data-test-id="View-Deal-deliveryStatus">Delivery Status:<div className='deal'><b>{dealData.deliveryStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="author" name="author" data-test-id="View-Deal-RatingAuthor">Rating Author:<div className='deal'><b>{dealData.author}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="bestRating" name="bestRating" data-test-id="View-Deal-BestRating">Best Rating:<div className='deal'><b>{dealData.bestRating}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="worstRating" name="worstRating" data-test-id="View-Deal-WorstRating">Worst Rating:<div className='deal'><b>{dealData.worstRating}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="ratingValue" name="ratingValue" data-test-id="View-Deal-RatingValue">Rating Value:<div className='deal'><b>{dealData.ratingValue}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                          </Grid>
                          <Grid item xs={6}>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="reviewAspect" name="reviewAspect" data-test-id="View-Deal-ReviewAspect">Review Aspect:<div className='deal'><b>{dealData.reviewAspect}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="ratingComments" name="ratingComments" data-test-id="View-Deal-RatingComments">Rating Comments:<div className='deal'><b>{dealData.ratingComments}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="lastUpdated" name="lastUpdated" data-test-id="View-Deal-LastUpdated">Last Updated:<div className='deal'><b>{dealData.lastUpdated}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="ownershipTransitionStartDate" name="ownershipTransitionStartDate" data-test-id="View-Deal-OwnershipStartDate">Ownership StartDate:<div className='deal'><b>{dealData.ownershipTransitionStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="ownershipTransitionEndDate" name="ownershipTransitionEndDate" data-test-id="View-Deal-OwnershipEndDate">Ownership EndDate:<div className='deal'><b>{dealData.ownershipTransitionEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="ownershipStatus" name="ownershipStatus" data-test-id="View-Deal-OwnershipStatus">Ownership Status:<div className='deal'><b>{dealData.ownershipStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="availabilityStateTransitionStartDate" name="availabilityStateTransitionStartDate" data-test-id="View-Deal-AvailabilityStateStartDate">Availability State StartDate:<div className='deal'><b>{dealData.availabilityStateTransitionStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="availabilityStateTransitionEndDate" name="availabilityStateTransitionEndDate" data-test-id="View-Deal-AvailabilityStateEndDate">Availability State EndDate:<div className='deal'><b>{dealData.availabilityStateTransitionEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="availabilityStatus" name="availabilityStatus" data-test-id="View-Deal-AvailabilityStatus">Availability Status:<div className='deal'><b>{dealData.availabilityStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="locationStateTransitionStartDate" name="locationStateTransitionStartDate" data-test-id="View-Deal-LocationStateStartDate">location State StartDate:<div className='deal'><b>{dealData.locationStateTransitionStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="locationStateTransitionEndDate" name="locationStateTransitionEndDate" data-test-id="View-Deal-LocationStateEndDate">location State EndDate:<div className='deal'><b>{dealData.locationStateTransitionEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>

                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="needStateTransitionStartDate" name="needStateTransitionStartDate" data-test-id="View-Deal-NeedStateStartDate">Need State StartDate:<div className='deal'><b>{dealData.needStateTransitionStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="needStateTransitionEndDate" name="needStateTransitionEndDate" data-test-id="View-Deal-NeedStateEndDate">Need State EndDate:<div className='deal'><b>{dealData.needStateTransitionEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="needStatus" name="needStatus" data-test-id="View-Deal-NeedStatus">Need Status:<div className='deal'><b>{dealData.needStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="usageStateTransitionStartDate" name="usageStateTransitionStartDate" data-test-id="View-Deal-UsageStateStartDate">Usage State StartDate:<div className='deal'><b>{dealData.usageStateTransitionStartDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="usageStateTransitionEndDate" name="usageStateTransitionEndDate" data-test-id="View-Deal-UsageStateEndDate">Usage State EndDate:<div className='deal'><b>{dealData.usageStateTransitionEndDate}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="usageStatus" name="usageStatus" data-test-id="View-Deal-UsageStatus">Usage Status:<div className='deal'><b>{dealData.usageStatus}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="sequenceStart" name="sequenceStart" data-test-id="View-Deal-SequenceStart">Sequence Start:<div className='deal'><b>{dealData.sequenceStart}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="sequenceAvailable" name="sequenceAvailable" data-test-id="View-Deal-SequenceAvailable">Sequence Available:<div className='deal'><b>{dealData.sequenceAvailable}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="sequenceSale" name="sequenceSale" data-test-id="View-Deal-SequenceSale">sequence Sale:<div className='deal'><b>{dealData.sequenceSale}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <FormControlWrapper>
                              <FormControl variant="outlined" className='w-100'>
                                <InputLabel id="status" name="status" data-test-id="View-Deal-Status">Status:<div className='deal'><b>{dealData.status}</b></div></InputLabel>
                              </FormControl>
                            </FormControlWrapper>
                            <div className="classes.Margin">
                              <FormControlWrapper>
                                <FormControl variant="outlined" className='w-100'>
                                  <InputLabel id="dealEquipment" name="dealEquipment" data-test-id="View-Deal-dealEquipment">Deal Equipment:<div className='MarginBottom'><b>{(dealData.dealEquipment || []).join("\n")}</b></div></InputLabel>
                                </FormControl>
                              </FormControlWrapper></div>
                          </Grid>
                        </Grid>
                        &nbsp;&nbsp;&nbsp;
                        <FormButtonWrapper>
                          <Link to="/dealpage" className='linkStyle'>
                            <Button color='primary' variant='contained' type='submit' data-test-id="View-Deal-GoBack" text-decoration='none'>
                              Go Back
                            </Button>
                          </Link>
                        </FormButtonWrapper>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
};
const mapStateToProps = state => ({
  restClient: state.restClient,
  dealData: state.deal.dealData
});
export default connect(mapStateToProps, { getDealById })(ViewDeal);