import React, { useEffect, useRef, useState, useCallback } from "react";
import { connect } from 'react-redux';
import {
  IconButton,
  Grid,
  Button,
  FormControl,
  TextField,
  Card,
  Typography,
  useTheme,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Divider,
  FormLabel,
  FormGroup,
  FormControlLabel,
  ListItemAvatar,
  Tooltip,
  Box,
  Tab,
  Tabs,
  InputAdornment,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  AppBar,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles'
import Avatar from '@mui/material/Avatar';
import SendIcon from '@mui/icons-material/Send';
import { getDealMessageList, submitDealMessage, submitAcceptMessage, submitRejectMessage, submitDealMessageWithoutTransactionId } from '../../../reducers/DealCommunicationReducer';
import useQuery from '../../../utils/useQuery';
import TabsContainer from "../../NewDealPayments/Card";
import { editDeal } from "../../../reducers/DealReducer";
import { Stack, styled } from '@mui/system';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Slider from 'react-slick';  // For horizontal sliding, install react-slick and slick-carousel
import { BlobServiceClient } from "@azure/storage-blob";
import { uploadImage, deleteImage } from "../../DealChat/blobStorage";
import ImageDisplay from "../../DealChat/image";
// import deleteImage from "../../DealChat/deleteBlob";
import { editTransactionBlobs } from "../../../reducers/DealCommunicationReducer";
import { FileCard, FileMosaic, FullScreen, ImagePreview, VideoPreview } from "@files-ui/react";
import { getTransactionList, GenerateTransactionRegisterForm, editTransaction } from "../../../reducers/TransactionReducer";
import EditNoteIcon from '@mui/icons-material/EditNote';
import PersonRemoveAlt1RoundedIcon from '@mui/icons-material/PersonRemoveAlt1Rounded';
import EditNoteRoundedIcon from '@mui/icons-material/EditNoteRounded';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import getTranslationObject from "../../../utils/getTranslations";

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1)
    }
  },
  messageRow: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: "0 16px 4px 16px",
    flex: "0 0 auto",
    marginRight: "30%",
    "&.contact": {
      "& $bubble": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        marginLeft: 28,
        "& $time": {
          marginLeft: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopLeftRadius: 20
        }
      },
      "&.last-of-group": {
        "& $bubble": {
          borderBottomLeftRadius: 20
        }
      }
    },
    "&.me": {
      paddingLeft: 40,

      "& $avatar": {
        order: 2,
        margin: "0 0 0 16px"
      },

      "& $bubble": {
        marginLeft: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        "& $time": {
          justifyContent: "flex-end",
          right: 0,
          marginRight: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopRightRadius: 20
        }
      },

      "&.last-of-group": {
        "& $bubble": {
          borderBottomRightRadius: 20
        }
      }
    },
    "&.contact + .me, &.me + .contact": {
      paddingTop: 20,
      marginTop: 20
    },
    "&.first-of-group": {
      "& $bubble": {
        borderTopLeftRadius: 20,
        paddingTop: 13
      }
    },
    "&.last-of-group": {
      "& $bubble": {
        borderBottomLeftRadius: 20,
        paddingBottom: 13,
        "& $time": {
          display: "flex"
        }
      }
    }
  },
  messageRowRight: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: "0 16px 4px 16px",
    flex: "0 0 auto",
    [theme.breakpoints.down('600')]: {
      padding: "0 8px 2px 8px",
      "& $bubble": {
        width: "100%",
      },
    },
    "&.contact": {
      "& $bubble": {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.getContrastText(theme.palette.background.paper),
        borderTopLeftRadius: 5,
        borderBottomLeftRadius: 5,
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        marginLeft: 28,
        "& $time": {
          marginLeft: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopLeftRadius: 20
        }
      },
      "&.last-of-group": {
        "& $bubble": {
          borderBottomLeftRadius: 20
        }
      }
    },
    "&.me": {
      paddingLeft: 40,

      "& $avatar": {
        order: 2,
        margin: "0 0 0 16px"
      },

      "& $bubble": {
        marginLeft: "auto",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5,
        "& $time": {
          justifyContent: "flex-end",
          right: 0,
          marginRight: 12
        }
      },
      "&.first-of-group": {
        "& $bubble": {
          borderTopRightRadius: 20
        }
      },

      "&.last-of-group": {
        "& $bubble": {
          borderBottomRightRadius: 20
        }
      }
    },
    "&.contact + .me, &.me + .contact": {
      paddingTop: 20,
      marginTop: 20
    },
    "&.first-of-group": {
      "& $bubble": {
        borderTopLeftRadius: 20,
        paddingTop: 13
      }
    },
    "&.last-of-group": {
      "& $bubble": {
        borderBottomLeftRadius: 20,
        paddingBottom: 13,
        "& $time": {
          display: "flex"
        }
      }
    }
  },
  avatar: {
    position: "absolute",
    left: 0,
    margin: 0
  },
  bubble: {
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    padding: 12,
    maxWidth: "100%",
    wordWrap: "break-word",
    boxShadow: theme.shadows[1]
  },
  message: {
    whiteSpace: "pre-wrap",
    lineHeight: 1.2
  },
  time: {
    position: "absolute",
    display: "none",
    width: "100%",
    fontSize: 11,
    marginTop: 8,
    top: "100%",
    left: 0,
    whiteSpace: "nowrap"
  },
  inputWrapper: {
    borderRadius: 24
  },
  rightMessage: {
    width: "fit-content",
    alignSelf: "end"
  },
  ButtonGroup: {
    textAlign: "center",
    whiteSpace: "nowrap",
  },
  AcceptButton: {
    borderRadius: "30px",
    color: "white",
    backgroundColor: "rgb(62, 82, 114)",
    '&:hover': {
      backgroundColor: "#fbbc05",
      color: "black"
    }
  },
  RejectButton: {
    borderRadius: "30px",
    color: "#fff",
    backgroundColor: "#9c27b0",
    '&:hover': {
      backgroundColor: "#6C3483"
    }
  },
  accept: {
    textAlign: "start",
  },
  reject: {
    textAlign: "end",
  },
  dialogButtons: {
    display: "flex"
  },
  achPayment: {
    paddingRight: "30px",
  },
  achlink: {
    textDecoration: "none",
  },
  cardlink: {
    textDecoration: "none"
  },
  achButton: {
    backgroundColor: "rgb(62, 82, 114)",
    color: "white",
    borderRadius: "30px",
    '&:hover': {
      backgroundColor: "#fbbc05",
      color: "black"
    }
  },
  cardButton: {
    backgroundColor: "#A569BD",
    borderRadius: "30px",
    '&:hover': {
      backgroundColor: "#884EA0"
    }
  },
  cancelButton: {
    backgroundColor: "#f50057",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#f50057",
      color: "#fff"
    }
  },
  makePayment: {
    textAlign: "center",
  },
}));

const FileCardsDisplay = ({ files, dealId, editTransactionBlobs, allFiles, transactionId }) => {
  const fileName = files?.blobPath; //array of strings
  const fileGroups = files?.blobPath?.length > 0 && fileName.reduce((acc, file) => {
    const extension = file.split('.').pop();
    if (!acc[extension]) {
      acc[extension] = [];
    }
    acc[extension].push(file);
    return acc;
  }, {});
  const baseUrl = process.env.REACT_APP_BLOB_URL;

  const parsedFiles = Object.keys(fileGroups).reduce((acc, key) => {
    acc[key] = fileGroups[key].map(url => `${baseUrl}deal/${dealId}/` + url);
    return acc;
  }, {});
  const [imgSrc, setImgSrc] = React.useState(undefined);
  const [videoSrc, setVideoSrc] = React.useState(undefined);

  const handleDelete = (fileType, fileName) => {
    console.log(`Delete ${fileName} from ${fileType}`);
    // Implement the delete functionality here
    const decision = window.confirm("Are u sure you need to delete the file permanently?");
    const res = decision && deleteImage('deal', `${dealId}/${fileName}`);
    let transactioncommunication = {};
    transactioncommunication.blobPath = allFiles.filter((file) => file === fileName);
    // editTransactionBlobs(transaction?.id, transactioncommunication);
  };

  const handleSee = (imageSource) => {
    setImgSrc(imageSource);
  };

  const handleWatch = (videoSource) => {
    setVideoSrc(videoSource);
  };

  const handleDownload = (fileId, downloadUrl) => {
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = fileId; // or any other filename you wish to use
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  function getFileExtension(filename) {
    const match = filename.match(/\.([^\.]+)$/);
    const fileType = match ? match[1] : '';
    let finalFileType;
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp', 'tiff', 'svg'].includes(fileType)) {
      finalFileType = `image/${fileType}`;
    } else if (fileType === 'pdf') {
      finalFileType = `application/pdf`;
    } else if (['xls', 'xlsx'].includes(fileType)) {
      finalFileType = `application/vnd.openxmlformats-officedocument.spreadsheetml.sheet`;
    } else if (fileType === 'csv') {
      finalFileType = `text/${fileType}`;
    } else if (['doc', 'docx'].includes(fileType)) {
      finalFileType = `application/vnd.openxmlformats-officedocument.wordprocessingml.document`;
    } else if (['ppt', 'pptx'].includes(fileType)) {
      finalFileType = `application/vnd.openxmlformats-officedocument.presentationml.presentation`;
    } else if (['mp4', 'avi', 'mov', 'wmv', 'flv', 'mkv'].includes(fileType)) {
      finalFileType = `video/${fileType}`;
    } else if (['mp3', 'wav', 'aac', 'flac', 'ogg'].includes(fileType)) {
      finalFileType = `audio/${fileType}`;
    } else if (['txt', 'md', 'rtf'].includes(fileType)) {
      finalFileType = `text/${fileType}`;
    } else {
      finalFileType = 'application/octet-stream';
    }
    return finalFileType
  }
  return (
    <>
      {Object.entries(parsedFiles).map(([fileType, fileArray]) =>
        fileArray.map((fileUrl) => {
          const fileName = fileUrl.split('/').pop();
          return (
            <FileCard
              key={fileName}
              id={fileName}
              name={fileName}
              type={getFileExtension(fileName)}
              onSee={() => handleSee(fileUrl)}
              onWatch={() => handleWatch(fileUrl)}
              onDownload={() => handleDownload(fileName, fileUrl)}
              preview
            // Add other props as needed
            />
          );
        })
      )}
    </>
  );
};

const BorderRight500 = styled('div')(({ theme }) => ({
  borderRight: '1px solid #e0e0e0',
}));

const UserList = ({ loggedInUserData, sortedUserList,
  setSelectedUsers, setTransaction,
  dealID, equipment,
  GenerateTransactionRegisterForm, dealTransactionList,
  setToGroupMembers, submitDealMessageWithoutTransactionId,
  editTransaction, setSelectedTabId, selectedTabId,
  group, setGroup, setNameOfSelectedContact, isSmallScreen, value, setValue }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [editGroupName, setEditGroupName] = useState('');
  const [groupMembers, setGroupMembers] = useState([]);
  const [dealGroups, setDealGroups] = useState([]);
  const [editGroupMembers, setEditGroupMembers] = useState([]);

  const handleEditOpenDialog = (event, transaction) => {
    setTransaction(transaction);
    setOpenEditDialog(true);
    setEditGroupMembers(transaction?.userGroup);
    setEditGroupName(transaction?.selectionName);
    setNameOfSelectedContact(transaction?.selectionName);
  };

  const handleEditCloseDialog = () => {
    setOpenEditDialog(false);
  };

  // for opening the group creation dialogue panel
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  // for closing the group creation dialogue panel
  const handleCloseDialog = () => {
    setOpenDialog(false);
    setGroupName("");
    setGroupMembers([]);
  };

  // For changing the tabs user-wise
  const handleChange = (event, newValue) => {
    setValue(newValue);
    setGroup([]);
  };

  // For changing the tabs group-wise
  const handleGroupChange = (event, newGroup) => {
    setGroup(newGroup);//0
    setValue([]);
  };

  // For selecting the members for group creation
  const handleCheckboxForGroupCreationChange = (event, user) => {
    if (event.target.checked) {
      setGroupMembers((prevSelectedUsers) => [...prevSelectedUsers, user]);
    } else {
      setGroupMembers((prevSelectedUsers) =>
        prevSelectedUsers.filter((u) => u.participant._id !== user.participant._id)
      );
    }
  };

  // for group creation
  const transactionCommunication = {
    dealId: dealID,
    message: `${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]} created this group chat`,
    transactionEquipment: equipment,
    from: `${loggedInUserData.user._id} `,
    to: groupMembers.map((user) => user.participant._id),
    notificationType: "General Message",
    addedBy: `${loggedInUserData.user._id} `,
  };

  // For creating the group
  const handleCreateGroupChat = async () => {
    const otherGroupMembers = groupMembers.map(members => members.participant._id);
    otherGroupMembers.push(loggedInUserData.user._id);
    const transaction = {
      transaction: {
        dealId: dealID,
        equipment: equipment,
        selectionName: groupName,
        price: "$345",
        addedBy: `${loggedInUserData.user._id} `,
        userGroup: otherGroupMembers, // need to add all members
        transactionType: "Group"
      }
    };
    await GenerateTransactionRegisterForm(dealID, transaction, { ...transactionCommunication, selectionName: groupName });
    setOpenDialog(false);
  };

  const isUserInGroup = (transaction, user, userId) => {
    return transaction?.userGroup?.includes(user?.participant?._id) && transaction?.userGroup?.includes(userId) && transaction?.userGroup?.length == 2;
  };

  // for individual chatting
  const handleCheckboxChange = (event, transaction, user) => {
    if (event.target.checked && transaction) {
      setSelectedUsers([user]);
      setNameOfSelectedContact(`${user?.participant?.contact?.firstName[0]} ${user?.participant?.contact?.lastName[0]}`);
      setTransaction(transaction);
      setToGroupMembers([]);
    }
  };

  // for group chatting
  const handleCheckboxForGroupChange = (user) => {
    setToGroupMembers(user.userGroup.filter((ub) => ub !== loggedInUserData.user._id));
    setTransaction(user);//
    setNameOfSelectedContact(user?.selectionName);
    setSelectedUsers([]);
    setSelectedTabId(user?.id);
  };

  const handleTabClick = (event, transaction) => {
    setSelectedTabId(transaction?.id);
    handleCheckboxForGroupChange(transaction);
  };

  const filterCondition = useCallback(
    (transaction) =>
      transaction.transactionType === 'Group' &&
      transaction.userGroup.length > 2 &&
      transaction.userGroup.includes(loggedInUserData.user._id),
    [loggedInUserData.user._id]
  );

  useEffect(() => {
    // Apply the filter condition when dealTransactionList or loggedInUserData.user._id changes
    const filteredGroups = (dealTransactionList || []).filter(filterCondition);
    setDealGroups(filteredGroups);
  }, [dealTransactionList, filterCondition]);

  // To exit from the group
  const handleExitFromGroup = (event, transaction) => {
    event.stopPropagation();
    const decision = window.confirm(`Are you sure you want to exit from group ${transaction?.selectionName} `);
    const payload = {
      userGroup: transaction.userGroup.filter((users) => users !== loggedInUserData.user._id),
    };
    if (transaction.id === selectedTabId) {
      setTransaction({});
      setGroup(null);
      setSelectedTabId(null); // Reset selectedTabId when you exit from the group
    }
    if (decision) {
      editTransaction(transaction?.dealId, transaction?.id, payload, loggedInUserData);
    }
  };

  // Edit Group MEMBERS
  const handleCheckboxForGroupEditChange = (event, user) => {
    const userId = user.participant._id;
    setEditGroupMembers(prevGroupMembers => {
      if (event.target.checked) {
        return [...prevGroupMembers, userId];
      } else {
        return prevGroupMembers.filter(id => id !== userId);
      }
    });
  };
  // Edit group
  const handleEditGroup = (event) => {
    const payload = {
      selectionName: editGroupName,
      userGroup: editGroupMembers,
    };
    setToGroupMembers(editGroupMembers.filter((user) => user !== loggedInUserData.user._id));
    editTransaction(dealID, selectedTabId, payload);
    setOpenEditDialog(false);
    setNameOfSelectedContact(editGroupName);
  };

  return (
    <Grid
      item
      as={BorderRight500}
      xs={isSmallScreen ? 12 : undefined}
      style={{ width: isSmallScreen ? "100%" : "auto", flexBasis: isSmallScreen ? "375px" : undefined }}>
      <List>
        <ListItem>
          <ListItemIcon>
            <Avatar alt={`${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]} `} src="https://material-ui.com/static/images/avatar/1.jpg" />
          </ListItemIcon>
          <ListItemText primary={`${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]} `} />
          <ListItemAvatar>
            <Tooltip title="Add Group Chat">
              <IconButton onClick={handleOpenDialog}>
                <GroupAddOutlinedIcon />
              </IconButton>
            </Tooltip>
          </ListItemAvatar>
        </ListItem>
      </List>
      <Divider />
      <div style={{ display: 'flex', flexDirection: 'column', height: '60vh' }}>
        <div style={{ flex: 1, overflow: 'auto' }}>
          {sortedUserList.length > 0 ?
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
            >
              {(sortedUserList || []).map((user, index) => (
                <Tab
                  key={user.participant._id}
                  label={`${user.participant.contact.firstName[0]} ${user.participant.contact.lastName[0]} `}
                  id={`vertical - tab - ${index} `}
                  aria-controls={`vertical - tabpanel - ${index} `}
                  onClick={(event) => {
                    const ifTransactionIdExists = (dealTransactionList || []).filter(transaction => isUserInGroup(transaction, user, loggedInUserData?.user?._id));
                    if (ifTransactionIdExists.length) {
                      handleCheckboxChange(event, ifTransactionIdExists[0], user);
                    } else {
                      const transaction = {
                        dealId: dealID,
                        equipment: equipment.map((un) => un._id),
                        addedBy: loggedInUserData.user._id,
                        transactionType: "Individual",
                        selectionName: "selectionName",
                        price: "$345",
                        userGroup: [user?.participant?._id, loggedInUserData.user._id]
                      };
                      // const response = await submitDealMessageWithoutTransactionId(dealID, transaction);
                      submitDealMessageWithoutTransactionId(dealID, transaction)
                        .then(response => {
                          const transaction = {
                            id: response.transaction._id,
                            equipment: response.transaction.equipment,
                            dealId: response.transaction.dealId,
                            selectionName: response.transaction.selectionName,
                            price: response.transaction.price,
                            addedBy: response.transaction.addedBy,
                            userGroup: response.transaction.userGroup,
                            transactionType: response.transaction.transactionType,
                          };
                          handleCheckboxChange(event, transaction, user); // This should log the transactionForIndividual
                        })
                        .catch(error => {
                          console.error('Error:', error);
                        });

                    }
                  }}
                  checked={sortedUserList?.some((u) => u.participant._id === user.participant._id)}
                  fullWidth
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    width: '100%',
                    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                    marginTop: 0.2
                  }}
                />
              ))}
            </Tabs>
            : null}
          <Divider />
          <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
            <TextField
              size="small"
              fullWidth
              placeholder="Type your Group Name"
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              required
              sx={{ marginTop: "10px" }}
            />
            <DialogContent>
              {(sortedUserList || []).map((user) => (
                <ListItem key={user.participant._id}>
                  <ListItemIcon>
                    <Checkbox
                      checked={groupMembers.some((u) => u.participant._id === user.participant._id)}
                      onChange={(event) => handleCheckboxForGroupCreationChange(event, user)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${user.participant.contact.firstName} ${user.participant.contact.lastName} `}
                  />
                </ListItem>
              ))}
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {groupMembers.length < 2 &&
                    <Typography variant="caption" sx={{ color: "red" }}>
                      *Select At least Two Users
                    </Typography>}
                </Grid>
                <Grid item>
                  <Button onClick={handleCloseDialog} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={handleCreateGroupChat}
                    color="primary"
                    disabled={groupMembers.length < 2 || groupName.trim() === ""}
                  >
                    Create Group Chat
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
          {dealGroups.length ?
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Badge
                  badgeContent={dealGroups.length}
                  color="primary"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}>Group Chats</Badge>
              </AccordionSummary>
              <AccordionDetails>
                <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={group}
                  onChange={handleGroupChange}
                  aria-label="Vertical tabs example"
                >
                  {(dealGroups || []).map((transaction) => (
                    <Tab
                      key={transaction.id}
                      label={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <span style={{ flexGrow: 1 }}>{transaction.selectionName}</span>
                          {transaction.addedBy !== loggedInUserData.user._id &&
                            <IconButton style={{ marginLeft: 48 }} onClick={(event) => handleExitFromGroup(event, transaction)}>
                              <PersonRemoveAlt1RoundedIcon />
                            </IconButton>
                          }
                          {transaction.addedBy === loggedInUserData.user._id &&
                            <IconButton style={{ marginLeft: 68 }} onClick={(event) => handleEditOpenDialog(event, transaction)}>
                              <EditNoteIcon />
                            </IconButton>
                          }
                        </div>
                      }
                      id={`vertical - tab - ${transaction.id} `}
                      aria-controls={`vertical - tabpanel - ${transaction.id} `}
                      onClick={(event) => handleTabClick(event, transaction)}
                      checked={dealGroups?.some((u) => u.id === transaction.id)}
                      fullWidth
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        width: '100%',
                        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
                        marginTop: 0.2
                      }}
                    />
                  ))}
                </Tabs>
              </AccordionDetails>
            </Accordion> : null
          }
          <Dialog open={openEditDialog} onClose={handleEditCloseDialog} fullWidth>
            <TextField
              size="small"
              fullWidth
              placeholder="Edit your Group Name"
              value={editGroupName}
              onChange={(e) => setEditGroupName(e.target.value)}
              required
              sx={{ marginTop: "10px" }}
            />
            <DialogContent>
              {(sortedUserList || []).map((user) => (
                <ListItem key={user.participant._id}>
                  <ListItemIcon>
                    <Checkbox
                      checked={(editGroupMembers || []).includes(user.participant._id)}
                      onChange={(event) => handleCheckboxForGroupEditChange(event, user)}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${user.participant.contact.firstName} ${user.participant.contact.lastName} `}
                  />
                </ListItem>
              ))}
            </DialogContent>
            <DialogActions>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  {editGroupMembers.length < 3 &&
                    <Typography variant="caption" sx={{ color: "red" }}>
                      *Select At least Two Users
                    </Typography>}
                </Grid>
                <Grid item>
                  <Button onClick={handleEditCloseDialog} color="primary">
                    Cancel
                  </Button>
                  <Button
                    onClick={(event) => handleEditGroup(event)}
                    color="primary"
                    disabled={editGroupMembers?.length < 3 || editGroupName?.trim() === ""}
                  >
                    Edit Group Chat
                  </Button>
                </Grid>
              </Grid>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </Grid>
  );
};

function getBlobUrls(uploadResults) {
  return uploadResults.map(result => result.fileUrl);
}

async function uploadMultipleFiles(containerName, subcontainer, files) {
  const uploadPromises = files.map(file => uploadImage(containerName, subcontainer, file));
  const results = await Promise.all(uploadPromises);
  const urls = getBlobUrls(results);
  return urls;
}
const DealMessageChat = (props) => {
  const { dealCommunicationList = [], restClient: { rest }, getDealMessageList, loggedInUserData, dealData, editTransactionBlobs, getTransactionList, IndividualMessageList = [], GroupMessageList = [], transactionList = [], GenerateTransactionRegisterForm, submitDealMessageWithoutTransactionId, setTransactionListForMakeOffer, editTransaction, locale } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [dealMessageList, setDealMessageList] = useState([]);
  const [unreadMessageList, setUnreadMessageList] = useState([]);
  const [filteredUserList, setFilterUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [dealTransactionList, setDealTransactionList] = useState([]);
  const [open, setOpen] = React.useState(false);
  const messagesEndRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [transaction, setTransaction] = useState({});
  const [toGroupMembers, setToGroupMembers] = useState([]);
  const [makePaymentButtonVisble, setMakePaymentButtonVisble] = useState(true);
  const [makeAcceptButtonVisble, setMakeAcceptButtonVisble] = useState(true);
  const [selectedTabId, setSelectedTabId] = useState(null);
  const [group, setGroup] = useState(null);//want to make null
  const [nameOfSelectedContact, setNameOfSelectedContact] = useState("");
  const [openChat, setOpenChat] = useState(false);
  const [value, setValue] = useState();

  const i18n = getTranslationObject(locale, "deal_chat_component");

  const dealID = dealData && dealData?.id;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  };

  const userId = props.user && props.user.user && props.user.user._id;

  // const DealMakeOffer = dealCommunicationList && dealCommunicationList.find((item) => item.notificationType === "Make Offer");

  const DealAcceptOffer = Array.isArray(dealCommunicationList) && dealCommunicationList.find((item) => item.notificationType === "Accept Offer");

  const DealRejectOffer = Array.isArray(dealCommunicationList) && dealCommunicationList.find((item) => item.notificationType === "Reject Offer");

  // const DealPayment = dealCommunicationList?.find && dealCommunicationList.find((item) => item.notificationType === "Payment");

  const ENTER_KEY_CODE = 13;

  const [msg, setMsg] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [intialized, setIntialized] = useState(true);

  const handleMessageChange = (event) => {
    if ((selectedUsers?.length === 0 && toGroupMembers.length === 0) && msg.trim() == "") {
      alert("You must select atleast one user or group to send message.");
    } else {
      setMsg(event.target.value);
      setBtnDisabled(!event.target.value);;
    }
  }

  const listmsg = filteredUserList.find(item => item.message);

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (filteredUserList.length > 0) {
      scrollToBottom();
    }
    setTimeout(() => {
      if (filteredUserList.length > 0) {
        setIntialized(false);
      }
    }, 2000);
  }, [filteredUserList, scrollToBottom]);

  //for making the unreadmessageList zero
  const handleFocusChange = () => {
    // console.log("Focus executed")
    const loggedInUserId = loggedInUserData.user._id;
    const messagesCountOfUser = unreadMessageList?.length > 0 ? unreadMessageList?.filter((user) => user.userId === loggedInUserId)[0]?.totalUnReadMessages : props?.dealData?.UnreadMessages.filter((user) => user.userId === loggedInUserId)[0].totalUnReadMessages;
    if (unreadMessageList?.length > 0 && messagesCountOfUser) {
      unreadMessageList.forEach(message => {
        // Check if the userId matches the loggedInUserId
        if (message.userId === loggedInUserId) {
          // Update totalUnReadMessages to zero
          message.totalUnReadMessages = 0;
          message.readMessage = true;
        }
      });
      props.editDeal(dealID, { "UnreadMessages": unreadMessageList }, true);
    }
    else if (Object.keys(props.dealData).length > 0 && unreadMessageList?.length === 0 && messagesCountOfUser > 0) {
      props?.dealData?.UnreadMessages.filter((userData) => {
        if (userData.userId === loggedInUserId) {
          userData.totalUnReadMessages = 0;
          userData.readMessage = true;
        }
        return true;
      });
      props.editDeal(dealID, { "UnreadMessages": props?.dealData?.UnreadMessages }, true);
    }
  }


  // for send message to api
  const sendMessage = async () => {
    //for communication individual WITH transactionId between loggedInUser and selected user
    if (transaction?.transactionType === "Individual") {
      let blobPath = [];
      if (files.length > 0) {
        blobPath = files.map((file) => file.name);
        await uploadMultipleFiles("deal", `${dealID}`, files);
        setFiles([]);
      }
      const payload = {
        dealId: dealID,
        transactionId: transaction?.id,
        message: msg,
        transactionEquipment: props.equipment,
        from: userId,
        to: selectedUsers.map((p) => { return p.participant._id }),
        notificationType: "General Message",
        notes: "notes",
        askPrice: "$435",
        offerPrice: "$410",
        priceUnit: "USD",
        addedBy: userId,
        blobPath: blobPath,
      }
      const dealId = props?.dealCommunicationList[0]?.dealId;
      props.submitDealMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
      // scrollToBottom();
      setMsg("");
      setBtnDisabled(true);
    }
    //for groups
    else if (transaction?.transactionType === "Group") {
      let blobPath = [];
      if (files.length > 0) {
        blobPath = files.map((file) => file.name);
        await uploadMultipleFiles("deal", `${dealID}`, files);
        setFiles([]);
      }
      const payload = {
        dealId: dealID,
        message: msg,
        transactionEquipment: props.equipment,
        from: userId,
        to: toGroupMembers,
        transactionId: transaction?.id,
        notificationType: "General Message",
        notes: "notes",
        askPrice: "$435",
        offerPrice: "$410",
        priceUnit: "USD",
        addedBy: userId,
        blobPath: blobPath,
      }
      const dealId = props?.dealCommunicationList[0]?.dealId;
      props.submitDealMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload, transaction);
      setMsg("");
      setBtnDisabled(true);
    }

  }

  const acceptMessage = () => {
    const transactioncommunication = {
      dealId: dealID,
      transactionId: transaction?.id,
      message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} accepted the offer`,
      transactionEquipment: props.equipment,
      from: userId,
      to: selectedUsers.map((p) => { return p.participant._id }),
      notificationType: "Accept Offer",
      notes: "notes",
      askPrice: "$435",
      offerPrice: "$410",
      priceUnit: "USD",
      addedBy: userId
    };
    const participantemail = {
      dealname: dealData?.dealName,
      dealparticipantemails: dealData?.dealParticipants?.filter((un) => un.participant.acceptOffer === true).map((p) => { return p.participant.email })
    }
    function updateUnreadMessages(masterObj, filterObj) {
      if (masterObj.userId === filterObj.participant._id) {
        masterObj.totalUnReadMessages += 1;
        masterObj.readMessage = false;
      }
    }
    const dealId = props?.dealCommunicationList[0]?.dealId;
    // Iterate through masterData and filterData

    if (dealId && dealId?.UnreadMessages) {
      // Iterate through UnreadMessages and users
      dealId?.UnreadMessages.forEach((masterObj) => {
        selectedUsers.forEach((filterObj) => {
          updateUnreadMessages(masterObj, filterObj);
        });
      });
    }
    const payload = { transactioncommunication, participantemail }
    props.submitAcceptMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
  }

  const rejectMessage = () => {
    const transactioncommunication = {
      dealId: dealID,
      transactionId: transaction?.id,
      message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} rejected an offer`,
      transactionEquipment: props.equipment,
      from: userId,
      to: selectedUsers.map((p) => { return p.participant._id }),
      notificationType: "Reject Offer",
      notes: "notes",
      askPrice: "$435",
      offerPrice: "$410",
      priceUnit: "USD",
      addedBy: userId
    }
    const participantemail = {
      dealname: dealData.dealName,
      dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.rejectOffer === true).map((p) => { return p.participant.email })
    }
    const payload = { transactioncommunication, participantemail }
    function updateUnreadMessages(masterObj, filterObj) {
      if (masterObj.userId === filterObj.participant._id) {
        masterObj.totalUnReadMessages += 1;
        masterObj.readMessage = false;
      }
    }
    const dealId = props?.dealCommunicationList[0]?.dealId;
    // Iterate through masterData and filterData

    if (dealId && dealId?.UnreadMessages) {
      // Iterate through UnreadMessages and users
      dealId?.UnreadMessages.forEach((masterObj) => {
        selectedUsers.forEach((filterObj) => {
          updateUnreadMessages(masterObj, filterObj);
        });
      });
    }
    props.submitRejectMessage(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload);
  }

  const handleEnterKeyChange = (event) => {
    if (event.keyCode === ENTER_KEY_CODE) {
      sendMessage();
      // scrollToBottom();
    }
  }
  const handleFileUpload = (event) => {
    if (selectedUsers?.length <= 0 && toGroupMembers.length === 0) {
      alert("You must select atleast one user or group to upload files.");
    } else {
      const newFiles = Array.from(event.target.files);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };

  const handleFileDelete = (fileName) => {
    setFiles((prevFiles) => prevFiles.filter(file => file.name !== fileName));
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  };

  const ChatMessages = (filteredUserList.length > 0 ? filteredUserList : []).map((chatMessagesDto, index) =>
    <div>
      {(chatMessagesDto.from?._id !== userId) ?
        <div className={classes.messageRow}>
          <List>
            <ListItem key={index.toString()}>
              <ListItemIcon>
                <Avatar alt="Venu" src="https://material-ui.com/static/images/avatar/1.jpg">
                </Avatar>
              </ListItemIcon>
              <div className={classes.bubble}>
                <div className={classes.message}>
                  <FileCardsDisplay files={chatMessagesDto} dealId={dealID} editTransactionBlobs={props.editTransactionBlobs} />
                  <ListItemText
                    primary={`${chatMessagesDto?.from?.contact?.firstName[0]} ${chatMessagesDto?.from?.contact?.lastName[0]} (${chatMessagesDto.from?._id === dealData.seller ? "Seller" : "Buyer"}) : ${chatMessagesDto?.message}`}
                  />
                </div >
                <div>
                  {(dealData?.dealClosingSale?.find((un) => un.seller === loggedInUserData.user._id) && chatMessagesDto?.notificationType === "Make Offer" && !DealAcceptOffer && !DealRejectOffer) ?
                    <div className={classes.ButtonGroup}>
                      {makeAcceptButtonVisble &&
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <div className={classes.accept}>
                              <Button variant="contained" onClick={acceptMessage} className={classes.AcceptButton}>Accept Offer</Button>
                            </div>
                          </Grid>
                          <Grid item xs={6}>
                            <div className={classes.reject}>
                              <Button variant="contained" onClick={(event) => { window.confirm("Are you sure want to reject offer?") && rejectMessage() }} className={classes.RejectButton}>Reject Offer</Button>
                            </div>
                          </Grid>
                        </Grid>}
                    </div> : null}
                  {(dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id) && chatMessagesDto?.notificationType === "Accept Offer") ?
                    <div className={classes.ButtonGroup}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div className={classes.makePayment}>
                            {makePaymentButtonVisble &&
                              <Button variant="contained" onClick={handleClickOpen} className={classes.AcceptButton}>Make Payment</Button>}
                            <Dialog open={open} onClose={handleClose}>
                              <DialogTitle>Choose Any Payment Method</DialogTitle>
                              <DialogContent>
                                <div className={classes.dialogButtons}>
                                  <TabsContainer dealData={props.dealData} users={selectedUsers} setOpen={setOpen} transaction={transaction} />
                                </div>
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose} className={classes.cancelButton}>Cancel</Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </Grid>
                      </Grid>
                    </div> : null}
                </div>
                <div>
                  <Typography variant="caption" style={{ color: '#ff6f00', float: "right" }}>
                    {chatMessagesDto.createdAt}
                  </Typography>
                </div>
              </div >
            </ListItem >
          </List >
        </div >
        :
        <>
          {(chatMessagesDto?.blobPath?.length === 0 && !Boolean(chatMessagesDto?.message)) ? //For no images and no messages
            null :
            <div className={classes.messageRowRight}>
              <List sx={{ alignSelf: "end" }}>
                <ListItem key={index.toString()} className={classes.rightMessage}>
                  <div className={classes.bubble}>
                    <div className={classes.message}>
                      <FileCardsDisplay files={chatMessagesDto} dealId={dealID} editTransactionBlobs={props.editTransactionBlobs} />
                      <ListItemText
                        primary={chatMessagesDto.message}
                        secondary={<Typography variant="caption" style={{ color: '#ff6f00', float: "right" }}>
                          {chatMessagesDto.createdAt}
                        </Typography>}
                      />
                    </div>
                  </div>
                  <ListItemIcon>
                    <Avatar alt="Venu" src="https://tse3.mm.bing.net/th?id=OIP.ZX_-sfAfuG9WjeGBRMkl9gHaHa&pid=Api&P=0">
                    </Avatar>
                  </ListItemIcon>
                </ListItem>
              </List>
            </div>
          }
        </>
      }
    </div >
  );
  useEffect(() => {
    scrollToBottom();
  }, [ChatMessages]);

  useEffect(() => {
    const getMessageList = async () => {
      try {
        if (dealID && rest) {
          await getDealMessageList(dealID, loggedInUserData);
        }
      } catch (e) {
        console.log(e);
      }
    };
    getMessageList();
  }, [rest, getDealMessageList, dealID, loggedInUserData]);

  useEffect(() => {
    const getTransactionListByDealId = async () => {
      try {
        if (dealID && rest) {
          await getTransactionList(dealID); // Fetch the transaction list
        }
      } catch (e) {
        console.log(e);
      }
    };
    getTransactionListByDealId();
  }, [rest, getTransactionList, dealID]);

  useEffect(() => {
    if (transactionList?.length > 0) {
      setDealTransactionList(transactionList);
    }
  }, [transactionList, dealCommunicationList]);

  useEffect(() => {
    if (dealCommunicationList?.length > 0) {
      setDealMessageList(dealCommunicationList);
    }
  }, [dealCommunicationList]);

  useEffect(() => {
    if (dealTransactionList?.length > 0) {
      setTransactionListForMakeOffer(dealTransactionList);
    }
  }, [dealTransactionList]);

  useEffect(() => {
    //for handling the real time messages
    const handleRealTimeMessages = async (message) => {
      if (message?.transactioncommunication?.transactionId?._id) {
        setDealMessageList((prevList) => [
          ...prevList,
          message?.transactioncommunication,
        ]);
      }
    };
    rest?.service('transactioncommunication').on('created', handleRealTimeMessages);
    return () => {
      rest?.service('transactioncommunication').removeListener('created', handleRealTimeMessages);
    };
  }, [rest]);

  //
  useEffect(() => {
    //When transaction is created, we are updating it on time
    const handleRealTimeTransaction = async (message) => {
      if (message?.transaction?._id) {
        const transaction = {
          id: message.transaction._id,
          equipment: message.transaction.equipment,
          dealId: message.transaction.dealId,
          selectionName: message.transaction.selectionName,
          price: message.transaction.price,
          addedBy: message.transaction.addedBy,
          userGroup: message.transaction.userGroup,
          transactionType: message.transaction.transactionType,
          updatedAt: message.transaction.updatedAt,
        }
        setDealTransactionList((prevList) => [
          ...prevList,
          transaction
        ]);
      }
    };

    //for real-time edit transaction
    const handleRealTimeEditTransaction = async (message) => {
      if (message?.transaction?._id) {
        const transaction = {
          id: message.transaction._id,
          equipment: message.transaction.equipment,
          dealId: message.transaction.dealId,
          selectionName: message.transaction.selectionName,
          price: message.transaction.price,
          addedBy: message.transaction.addedBy,
          userGroup: message.transaction.userGroup,
          transactionType: message.transaction.transactionType,
          updatedAt: message.transaction.updatedAt,
        }

        // Check if the current user is still part of the transaction's user group
        const isUserInTransaction = transaction.userGroup.includes(loggedInUserData.user._id);

        // Check if the transaction already exists in the list
        const existingTransactionIndex = dealTransactionList.findIndex(t => t.id === transaction.id);
        let updatedDealTransactionList;

        if (existingTransactionIndex !== -1) {
          // If the transaction exists, update it
          updatedDealTransactionList = dealTransactionList.map((t) =>
            t.id === transaction.id ? transaction : t
          );

          // If the current user is removed from the group, filter out the transaction
          if (!isUserInTransaction) {
            updatedDealTransactionList = updatedDealTransactionList.filter(t => t.id !== transaction.id);
          }
        } else {
          // If the transaction is new and the user is part of the user group, add it to the list
          if (isUserInTransaction) {
            updatedDealTransactionList = [...dealTransactionList, transaction];
          } else {
            updatedDealTransactionList = [...dealTransactionList];
          }
        }
        if (message.transaction.addedBy !== loggedInUserData.user._id) {
          updatedDealTransactionList.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));
        }

        setDealTransactionList(updatedDealTransactionList);

        // Update deal communication list based on the updated transaction list
        const updatedDealCommunicationList = dealCommunicationList.filter((com) => {
          const transactionId = com?.transactionId?._id;
          if (transactionId === undefined) {
            return false;
          }
          const transactionUserGroup = updatedDealTransactionList.find(t => t.id === transactionId)?.userGroup || [];
          return transactionUserGroup.includes(com.addedBy);
        });

        setDealMessageList(updatedDealCommunicationList);

        // If the user is currently viewing the transaction they were removed from, update the selectedTabId
        if (!isUserInTransaction && selectedTabId === transaction.id) {
          setSelectedTabId(null);
          setGroup(null);
          setTransaction({});
        }
      }
    };

    rest?.service('transaction').on('created', handleRealTimeTransaction);
    rest?.service('transaction').on('patched', handleRealTimeEditTransaction);
    return () => {
      rest?.service('transaction').removeListener('created', handleRealTimeTransaction);
      rest?.service('transaction').removeListener('patched', handleRealTimeEditTransaction);
    };
  }, [rest, dealTransactionList, loggedInUserData, transaction, dealCommunicationList, selectedTabId]);

  useEffect(() => {
    const filteredList = dealMessageList.filter((com) => {
      if (com?.transactionId?._id === undefined) {
        return false;
      }
      // Check if transactionId matches
      const isMatch = com?.transactionId?._id === transaction?.id;
      if (!isMatch) return false;

      // Check if from or to contains the logged in user's id
      const isUserInFrom = com.from?._id === loggedInUserData.user._id;
      const isUserInTo = com.to?.some((recipient) => recipient._id === loggedInUserData.user._id);
      return isUserInFrom || isUserInTo;
    });
    const isPaymentDone = filteredList.filter((transactioncom) => transactioncom.notificationType === "Payment");
    const isAcceptDone = filteredList.filter((transactioncom) => transactioncom.notificationType === "Accept Offer");
    if (isPaymentDone.length) {
      setMakePaymentButtonVisble(false);
    } else if (isAcceptDone.length) {
      setMakeAcceptButtonVisble(false);
    } else {
      setMakePaymentButtonVisble(true);
      setMakeAcceptButtonVisble(true);
    }
    setFilterUserList(filteredList);
  }, [selectedUsers, transaction, dealMessageList, dealCommunicationList, loggedInUserData]);

  const handleUnselect = (event) => {
    setValue([]);
    setGroup(null);
    setNameOfSelectedContact('');
  }
  return (
    <div>
      <Card elevation={1} className="flex flex-col h-512 rounded-8"
      >
        <div
          className="flex flex-shrink-0 items-center justify-between px-24 h-64"
          style={{
            background: "#607d8b",
            color: theme.palette.getContrastText('#607d8b'),
            paddingLeft: "40px",
          }}
        >
          <Typography className="text-center text-16 font-400">{i18n.translate("deal_communication")}</Typography>
        </div>
        <div style={{ height: "500px", flex: 1 }}>
          <Grid container>
            {nameOfSelectedContact &&
              isSmallScreen ? null :
              <UserList
                loggedInUserData={loggedInUserData}
                sortedUserList={props?.users}
                setSelectedUsers={setSelectedUsers}
                setTransaction={setTransaction}
                dealID={dealID}
                equipment={props?.equipment}
                GenerateTransactionRegisterForm={GenerateTransactionRegisterForm}
                dealTransactionList={dealTransactionList.length > 0 && dealTransactionList}
                setToGroupMembers={setToGroupMembers}
                submitDealMessageWithoutTransactionId={submitDealMessageWithoutTransactionId}
                editTransaction={editTransaction}
                setSelectedTabId={setSelectedTabId}
                selectedTabId={selectedTabId}
                group={group}
                setGroup={setGroup}
                setNameOfSelectedContact={setNameOfSelectedContact}
                isSmallScreen={isSmallScreen}
                value={value}
                setValue={setValue}
              />}
            <Grid container xs={isSmallScreen && 0} lg={9} sx={{ height: "500px" }}>
              {nameOfSelectedContact && <Grid xs={12}>
                <AppBar
                  position="sticky"
                  sx={{
                    top: 0,
                    zIndex: 1,
                    backgroundColor: "white",
                    color: "black"
                  }}
                >
                  <Toolbar>
                    <IconButton edge="start" color="primary" sx={{ mr: 2 }} onClick={handleUnselect}>
                      <ArrowBackIcon />
                    </IconButton>
                    <Typography variant="h6" component="div">
                      {nameOfSelectedContact}
                    </Typography>
                  </Toolbar>
                </AppBar>
              </Grid>}
              {(nameOfSelectedContact || isSmallScreen) &&
                <>
                  <Grid xs={12} lg={12} md={12} sm={12} sx={{
                    height: "300px",
                    flex: 1,
                    overflowY: "auto",
                    position: "relative",
                    width: "100%",
                    '&::-webkit-scrollbar': { display: 'none' },
                    '-ms-overflow-style': 'none',
                    scrollbarWidth: 'none'
                  }} ref={messagesEndRef}>
                    {filteredUserList ? ChatMessages : ""}
                  </Grid>
                  <Grid
                    xs={12}
                    style={{
                      padding: 5,
                      display: "flex",
                      flexDirection: "row",
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "#fff", // Ensure background color for better visibility
                      zIndex: 0, // Ensure it stays on top of scrolling content
                    }}
                  >
                    <Box sx={{ width: "99%" }}>
                      <TextField
                        fullWidth
                        multiline
                        variant="outlined"
                        placeholder={i18n.translate("chat_input_placeholder")}
                        value={msg}
                        onChange={handleMessageChange}
                        onKeyDown={handleEnterKeyChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <input
                                accept="*"
                                style={{ display: 'none' }}
                                id="file-upload"
                                type="file"
                                multiple
                                onChange={handleFileUpload}
                              />
                              <label htmlFor="file-upload">
                                <IconButton component="span">
                                  <AttachFileIcon />
                                </IconButton>
                              </label>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton disabled={btnDisabled && files.length === 0} onClick={sendMessage}>
                                <SendIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      <Box mb={2}>
                        {files.length > 0 && (
                          <Slider {...settings}>
                            {files.map((file, index) => (
                              <Stack>
                                <Chip
                                  key={index}
                                  label={file.name}
                                  onDelete={() => handleFileDelete(file.name)}
                                  deleteIcon={<CancelOutlinedIcon />}
                                  style={{ marginTop: 8 }}
                                />
                              </Stack>
                            ))}
                          </Slider>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </>
              }
            </Grid>
          </Grid>
        </div>
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  dealCommunicationList: state.dealCommunication && state.dealCommunication.dealCommunicationList,
  deal: state.deal && state.deal.dealList,
  // dealData: state.deal && state.deal.dealData,
  user: state.user.userData,
  loggedInUserData: state.user && state.user.loggedInUserData,
  transactionList: state.transaction && state.transaction.transactionList,
  locale: state.locale,
});

export default connect(mapStateToProps, { getDealMessageList, submitDealMessage, submitAcceptMessage, submitRejectMessage, editDeal, editTransactionBlobs, getTransactionList, submitDealMessageWithoutTransactionId, GenerateTransactionRegisterForm, editTransaction })(DealMessageChat);