// NetworkErrorPage.js
import React from "react";

const NetworkError = () => {
    return (
        <div>
            <h1>Network Error</h1>
            <p>It seems you are offline. Please check your internet connection.</p>
        </div>
    );
};

export default NetworkError;
