export const initialFormValues = {
  companyName: '',//ok
  description: '',//ok
  roleType: '',
  dominantRole: '',
  traderSubType: 'Buyer',
  country: '',
  phoneNumber: '',
  email: '',//
  phone: '',
  password: '',
  confirmPassword: '',
  taxId: '',
  firstLine: '',
  secondLine: '',
  city: '',
  state: '',
  zipcode: '',//ok
  name: '',
  lat: '',
  long: '',
  webURL: '',// ok
  firstName: '',//ok
  middleName: '',
  lastName: '',
  alias: '',
  // emailAddress: '',//ok
  branchID: '',
  branchName: '',
  industry: '',
  region: '',
  annualRevenue: '',
  companyEmailAddress: '',
  facebook: '',//ok
  twitter: '',//ok
  instagram: '',//ok
  linkedin: '',//ok
  youtube: '',//ok
  searchLinkedinCompany: '',//ok
  linkedinCompanyPageUrl: '',//ok
  stockSymbol: '',//ok
  numberOfEmployees: '',//ok
  companyOwner: '',//ok
  contactLinkedin: '',
  deptOrTitle: '',
  automaticDealCreation: false
};

export const schema = {
  dominantRole: {
    presence: { allowEmpty: false, message: 'Dominant Role is required' },
  },
  roleType: {
    presence: { allowEmpty: false, message: 'Role Type is required' },
  },
  firstLine: {
    presence: { allowEmpty: false, message: 'Company Address is required' },
  },
  companyName: {
    presence: { allowEmpty: false, message: 'Company Name is required' },
  },
  branchID: {
    presence: { allowEmpty: false, message: 'Branch Id is required' },
  },
  branchName: {
    presence: { allowEmpty: false, message: 'Branch Name is required' },
  },
};

export const getPayload = (values) => {
  const payload = {
    "company": {
      companyName: values.companyName,//ok
      automaticDealCreation: values.automaticDealCreation,
      facebook: values.facebook,
      twitter: values.twitter,
      instagram: values.instagram,
      linkedin: values.linkedin,
      youtube: values.youtube,
      "atgRole": {
        roleType: values.roleType,
        dominantRole: values.dominantRole,
        traderSubType: values.traderSubType
      },
      description: values.description,//ok
      industry: values.industry,//ok
      deptOrTitle: values.deptOrTitle,//ok
      annualRevenue: values.annualRevenue,
      taxId: values.taxId,
      companyEmailAddress: values.companyEmailAddress,
      "address": {
        city: values.city,
        state: values.state,
        country: values.country,
        lat: values.lat,
        long: values.long,
        name: values.firstLine,
        firstLine: values.firstLine,
        secondLine: values.secondLine,
        zipcode: values.zipcode,
      },
      webURL: values.webURL,
      // emailAddress: values.emailAddress,
      phoneNumber: values.phoneNumber,
      "contact": {
        firstName: values.firstName,
        middleName: values.firstName,
        lastName: values.lastName,
        alias: values.firstName,
        webURL: values.webURL,
        emailAddress: values.email,
        countryCode: values.country,
        mobileNumber: values.phoneNumber,
        smsNumber: values.phoneNumber,
        linkedin: values.contactLinkedin
      },
      "branch": {
        branchID: values.branchID,
        branchName: values.branchName,
        region: values.region,
      },
      "additionalCompanyInfo": {
        stockSymbol: values.stockSymbol,
        numberOfEmployees: values.numberOfEmployees,
        companyOwner: values.companyOwner
      },
      "socialMedia": {
        facebook: values.facebook,//ok
        twitter: values.twitter,//ok
        instagram: values.instagram,//ok
        linkedin: values.linkedin,//ok
        youtube: values.youtube,//ok
        searchLinkedinCompany: values.searchLinkedinCompany,//ok
        linkedinCompanyPageUrl: values.linkedinCompanyPageUrl,//ok
      }
    },
    "owner": {
      "atgRole": {
        roleType: values.roleType,
      },
      "contact": {
        firstName: values.firstName,
        lastName: values.lastName,
        alias: values.firstName,
        webURL: values.webURL,
        countryCode: values.country,
        mobileNumber: values.phone,
        smsNumber: values.phone
      },
      email: values.email,//1
      password: values.password,
      phone: values.phone
    }
  }
  return payload;
};

export const getEditPayload = (values) => {
  const payload = {
    "company": {
      companyName: values.companyName,
      automaticDealCreation: values.automaticDealCreation,
      "atgRole": {
        roleType: values.roleType,
        dominantRole: values.dominantRole,
      },
      description: values.description,
      companyEmailAddress: values.companyEmailAddress,
      industry: values.industry,
      deptOrTitle: values.deptOrTitle,
      annualRevenue: values.annualRevenue,
      "address": {
        city: values.city,
        state: values.state,
        country: values.country,
        lat: values.lat,
        long: values.long,
        name: values.firstLine,
        description: values.description,
        firstLine: values.firstLine,
        secondLine: values.secondLine,
        zipcode: values.zipcode,
      },
      taxId: values.taxId,
      webURL: values.webURL,
      // emailAddress: values.emailAddress,
      phoneNumber: values.phoneNumber,
      "contact": {
        firstName: values.firstName,
        middleName: values.firstName,
        lastName: values.lastName,
        alias: values.branchName,
        webURL: values.webURL,
        emailAddress: values.emailAddress,
        countryCode: values.country,
        mobileNumber: values.phoneNumber,
        smsNumber: values.phoneNumber,
        linkedin: values.contactLinkedin
      },
      "additionalCompanyInfo": {
        stockSymbol: values.stockSymbol,
        numberOfEmployees: values.numberOfEmployees,
      },
      "socialMedia": {
        facebook: values.facebook,
        twitter: values.twitter,
        instagram: values.instagram,
        linkedin: values.linkedin,
        youtube: values.youtube,
        searchLinkedinCompany: values.searchLinkedinCompany,
        linkedinCompanyPageUrl: values.linkedinCompanyPageUrl,
      },
    },
    "owner": {
      "atgRole": {
        roleType: values.roleType,
      },
      "contact": {
        firstName: values.firstName,
        lastName: values.lastName,
        alias: values.branchName,
        webURL: values.webURL,
        countryCode: values.country,
        mobileNumber: values.phone,
        smsNumber: values.phone
      },
      email: values.email,
      password: values.password,
      phone: values.phone
    }
  }
  return payload;
};

export const stateCountryList = [{
  country: { value: "1", text: "USA" },
  states:
    [{ value: "AK", text: "Alaska" },
    { value: "AL", text: "Alabama" },
    { value: "AR", text: "Arkansas" },
    { value: "AS", text: "American Samoa" },
    { value: "AZ", text: "Arizona" },
    { value: "CA", text: "California" },
    { value: "CO", text: "Colorado" },
    { value: "CT", text: "Connecticut" },
    { value: "DC", text: "District of Columbia" },
    { value: "DE", text: "Delaware" },
    { value: "FL", text: "Florida" },
    { value: "GA", text: "Georgia" },
    { value: "GU", text: "Guam" },
    { value: "HI", text: "Hawaii" },
    { value: "IA", text: "Iowa" },
    { value: "ID", text: "Idaho" },
    { value: "IL", text: "Illinois" },
    { value: "IN", text: "Indiana" },
    { value: "KS", text: "Kansas" },
    { value: "KY", text: "Kentucky" },
    { value: "LA", text: "Louisiana" },
    { value: "MA", text: "Massachusetts" },
    { value: "MD", text: "Maryland" },
    { value: "ME", text: "Maine" },
    { value: "MI", text: "Michigan" },
    { value: "MN", text: "Minnesota" },
    { value: "MO", text: "Missouri" },
    { value: "MS", text: "Mississippi" },
    { value: "MT", text: "Montana" },
    { value: "NC", text: "North Carolina" },
    { value: "ND", text: "North Dakota" },
    { value: "NE", text: "Nebraska" },
    { value: "NH", text: "New Hampshire" },
    { value: "NJ", text: "New Jersey" },
    { value: "NM", text: "New Mexico" },
    { value: "NV", text: "Nevada" },
    { value: "NY", text: "New York" },
    { value: "OH", text: "Ohio" },
    { value: "OK", text: "Oklahoma" },
    { value: "OR", text: "Oregon" },
    { value: "PA", text: "Pennsylvania" },
    { value: "PR", text: "Puerto Rico" },
    { value: "RI", text: "Rhode Island" },
    { value: "SC", text: "South Carolina" },
    { value: "SD", text: "South Dakota" },
    { value: "TN", text: "Tennessee" },
    { value: "TX", text: "Texas" },
    { value: "UT", text: "Utah" },
    { value: "VA", text: "Virginia" },
    { value: "VI", text: "Virgin Islands" },
    { value: "VT", text: "Vermont" },
    { value: "WA", text: "Washington" },
    { value: "WI", text: "Wisconsin" },
    { value: "WV", text: "West Virginia" },
    { value: "WY", text: "Wyoming" }]
}, {
  country: { value: "91", text: "India" },
  states: [
    {
      value: "AN",
      text: "Andaman and Nicobar Islands"
    },
    {
      value: "AP",
      text: "Andhra Pradesh"
    },
    {
      value: "AR",
      text: "Arunachal Pradesh"
    },
    {
      value: "AS",
      text: "Assam"
    },
    {
      value: "BR",
      text: "Bihar"
    },
    {
      value: "CG",
      text: "Chandigarh"
    },
    {
      value: "CH",
      text: "Chhattisgarh"
    },
    {
      value: "DH",
      text: "Dadra and Nagar Haveli"
    },
    {
      value: "DD",
      text: "Daman and Diu"
    },
    {
      value: "DL",
      text: "Delhi"
    },
    {
      value: "GA",
      text: "Goa"
    },
    {
      value: "GJ",
      text: "Gujarat"
    },
    {
      value: "HR",
      text: "Haryana"
    },
    {
      value: "HP",
      text: "Himachal Pradesh"
    },
    {
      value: "JK",
      text: "Jammu and Kashmir"
    },
    {
      value: "JH",
      text: "Jharkhand"
    },
    {
      value: "KA",
      text: "Karnataka"
    },
    {
      value: "KL",
      text: "Kerala"
    },
    {
      value: "LD",
      text: "Lakshadweep"
    },
    {
      value: "MP",
      text: "Madhya Pradesh"
    },
    {
      value: "MH",
      text: "Maharashtra"
    },
    {
      value: "MN",
      text: "Manipur"
    },
    {
      value: "ML",
      text: "Meghalaya"
    },
    {
      value: "MZ",
      text: "Mizoram"
    },
    {
      value: "NL",
      text: "Nagaland"
    },
    {
      value: "OR",
      text: "Odisha"
    },
    {
      value: "PY",
      text: "Puducherry"
    },
    {
      value: "PB",
      text: "Punjab"
    },
    {
      value: "RJ",
      text: "Rajasthan"
    },
    {
      value: "SK",
      text: "Sikkim"
    },
    {
      value: "TN",
      text: "Tamil Nadu"
    },
    {
      value: "TS",
      text: "Telangana"
    },
    {
      value: "TR",
      text: "Tripura"
    },
    {
      value: "UK",
      text: "Uttar Pradesh"
    },
    {
      value: "UP",
      text: "Uttarakhand"
    },
    {
      value: "WB",
      text: "West Bengal"
    }
  ]
}
]

export const countryList = stateCountryList.filter((un) => un.country).map((un) => un.country);