import {
  AppBar,
  Toolbar,
  Button,
  Typography,
  IconButton,
  Drawer,
  Tooltip,
  // Link,
  MenuItem,
  Badge
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState, useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { setUserAuthenticated, setLoggedInUserData } from '../../reducers/UserReducer';
import ATGImage from '../../assets/images/main.svg';
import { makeStyles } from "@mui/styles";
import { getDealInvitationsListCount } from '../../reducers/DealInvitationCountReducer';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import getTranslationObject from "../../utils/getTranslations";
import SelectLanguageComponent from '../SelectLanguage';

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#fbbc05 !important",
    paddingRight: "5px",
    paddingLeft: "18px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
  },
  logo: {
    width: '80px',
    paddingLeft: '10px'
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "15px",
    marginLeft: "10px",
    padding: "6px 6px"
  },
  menuButtonProfile: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "15px",
    marginLeft: "15px",
    padding: "6px 6px",
    textTransform: "none",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  mobileLogo: {
    textAlign: 'center',
    width: '100%',
    color: '#000',
    paddingLeft: "20px"
  },
  drawerContainer: {
    padding: "20px 30px",
  },
  headerTab: {
    display: "flex"
  },
  avatarContainer: {
    position: "relative",
    cursor: "pointer",
    display: "flex",
    maxHeight: "40px"
  },
  avatarColor: {
    Color: "blue"
  },
  active: {
    "&:active": {
      borderBottom: "1px solid yellow",
    }
  },
  useravatar: {
    display: "flex",
    textDecoration: "none",
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "15px",
    color: "rgb(120, 120, 120)"
  },
  userlogoutdesk: {
    textDecoration: "none",
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 700,
    size: "15px",
    color: "rgb(120, 120, 120)",
    paddingLeft: "5px"
  }
}
));

const Header = (props) => {
  const { restClient: { rest }, isAuthenticated, setUserAuthenticated, userProfileData, userData, loggedInUserData, dealInvitationCount, getDealInvitationsListCount, locale, onHeightChange } = props;
  const i18n = getTranslationObject(locale, "header_component");

  const logOut = (e, flag) => {
    e.preventDefault();
    setUserAuthenticated(false);
    handleDrawerClose();
  }
  const { header, logo, mobileLogo, headerTab, menuButtonProfile, menuButton, toolbar, drawerContainer, active, avatarContainer, useravatar, userlogoutdesk } = useStyles();
  const headerRef = useRef(null);
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const avrTitle = (userProfileData && userProfileData.contact && userProfileData.contact.firstName[0] && userProfileData.contact.lastName[0]) ? `${userProfileData.contact.firstName[0].split(' ')[0]} ${userProfileData.contact.lastName[0]}` : '';
  const profileImage = userProfileData?.profilePath;
  // console.log("avrTitle :", avrTitle);
  // console.log("userProfileData", userProfileData);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));

  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));


  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const getInvitations = async () => {
      if (rest) {
        await getDealInvitationsListCount(loggedInUserData);
      }
    }
    getInvitations();
    // console.log("loggedinuserdata",loggedInUserData);
    //   const interval = setInterval( getInvitations, 2000);
    // return () => {
    //   clearInterval(interval); // Clear the interval to avoid memory leaks
    // };
  }, [rest, loggedInUserData, getDealInvitationsListCount])


  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    };
  }, []);

  let headersData = [];
  if (loggedInUserData) {
    const role = loggedInUserData && loggedInUserData.user && loggedInUserData.user.roleSystem;
    headersData = [];
    if (role === "regular" && (loggedInUserData.user?.parentCompany?.automaticDealCreation)) {
      headersData.push(
        // { label: "Home", href: "/homedeal" },
        { label: i18n.translate("deals"), href: "/dealpage" },
        { label: i18n.translate("equipment"), href: "/equipment" },
        { label: i18n.translate("matched_equipment"), href: "/matchedequipment" },
        // { label: "Deals", href: "/dealpage" },
        // { label: "Invitations", href: "/dealinvitations" },
        // { label: `${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]}`, href: "/userprofile" },
        // { label: "Chat", href: "/deal/chatpage" },
      );
    } else if (role === "regular" && loggedInUserData?.user?.atgRole?.roleType[0] === "Trusted Agent") {
      headersData.push(
        // { label: "Home", href: "/homedeal" },
        { label: i18n.translate("deals"), href: "/dealpage" },
        { label: i18n.translate("equipment"), href: "/equipment" },
        // { label: "Deals", href: "/dealpage" },
        // { label: "Invitations", href: "/dealinvitations" },
        // { label: `${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]}`, href: "/userprofile" },
        // { label: "Chat", href: "/deal/chatpage" },
        { label: "Users", href: "/users" },
        { label: "pending Users", href: "/pendingrequest" },
        { label: "Company", href: "/company" },
        { label: "Matched Equipment", href: "/matchedequipment" },
      );
    }
    else if (role === "regular") {
      headersData.push(
        // { label: "Home", href: "/homedeal" },
        { label: i18n.translate("deals"), href: "/dealpage" },
        { label: i18n.translate("equipment"), href: "/equipment" },
        // { label: "Deals", href: "/dealpage" },
        // { label: "Invitations", href: "/dealinvitations" },
        // { label: `${loggedInUserData.user?.contact?.firstName[0]} ${loggedInUserData.user?.contact?.lastName[0]}`, href: "/userprofile" },
        // { label: "Chat", href: "/deal/chatpage" },
      );
    }
    else {
      headersData.push(
        { label: "Network", href: "/network" },
        { label: "Users", href: "/users" },
        { label: "pending Users", href: "/pendingrequest" },
        { label: "Company", href: "/company" },
        { label: "Equipment", href: "/equipment" },
        { label: "Matched Equipment", href: "/matchedequipment" },
        { label: "Brands", href: "/brand" },
        // { label: "Crm Contacts", href: "/contacts" },
        { label: "Deals", href: "/dealpage" },
        // { label: "Vendors", href: "/vendor" },
      );
    }
  }
  const role = loggedInUserData && loggedInUserData.user && loggedInUserData.user.roleSystem;

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        {renderLogo}
        <div className={headerTab}>
          {isAuthenticated === true ? (
            <>
              <SelectLanguageComponent style={{ display: 'flex', justifyContent: 'center' }} />
              {getMenuButtons()}
            </>
          ) : ""}
          {(isAuthenticated === true && role === "regular") ?
            <div className={avatarContainer}>
              <Tooltip title={avrTitle}>
                <IconButton
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 2 }}
                  aria-controls={open ? 'account-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                >
                  <Avatar sx={{ backgroundColor: "#3e5272", textDecoration: "none" }} src={profileImage} alt={avrTitle && stringAvatar(avrTitle)} />
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Tooltip>
              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&::before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 12,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <Link to="/userprofile" className={useravatar}>
                  <MenuItem onClick={handleClose}>
                    <div style={{ paddingTop: "3px" }} className={useravatar}>{i18n.translate("my_profile")}</div>
                  </MenuItem>
                </Link>
                <Link to="/userpasswordchange" className={useravatar}>
                  <MenuItem onClick={handleClose}>
                    <div style={{ paddingTop: "3px" }} className={useravatar}>{i18n.translate("change_password")}</div>
                  </MenuItem>
                </Link>
                {loggedInUserData.user.isAdmin === true ? <Link to="/mySubscription" className={useravatar}>
                  <MenuItem onClick={handleClose}>
                    <div style={{ paddingTop: "3px" }} className={useravatar}>My Subscription</div>
                  </MenuItem>
                </Link> : null}
                <Divider />
                <Link to="/" style={{ textDecoration: "none", display: "flex", color: 'inherit' }} onClick={(e) => logOut(e, false)}>
                  <MenuItem onClick={handleClose}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    <div className={userlogoutdesk}>{i18n.translate("logout")}</div>
                  </MenuItem>
                </Link>
              </Menu>
            </div>
            : renderDesktopLogout()}
          {/* {renderDesktopLogout()} */}
        </div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    return (
      <Toolbar>
        {isAuthenticated === true ?
          <IconButton
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon />
          </IconButton>
          : ""}

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
          sx={{
            '& .MuiDrawer-paper': {
              height: '25vh', // Set the height here
              backgroundColor: '#E4E4E4', // Set your desired background color
              borderRadius: "5%",
            },
          }}
        >
          <div className={drawerContainer}>
            {isAuthenticated === true ? (
              <>
                {getDrawerChoices()}
                <div style={{ paddingTop: "20px" }}>
                  <SelectLanguageComponent style={{ display: 'flex', justifyContent: 'center' }} />
                </div>
              </>
            ) : ""}
            {/* {renderMobileLogout()} */}
          </div>

        </Drawer>

        <div className={mobileLogo}>{renderMobileLogo}</div>
        {(isAuthenticated === true && role === "regular") ?
          <div className={avatarContainer}>
            <Tooltip title={avrTitle}>
              <IconButton
                onClick={handleClick}
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open ? 'account-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
              >
                <Avatar sx={{ backgroundColor: "#3e5272", textDecoration: "none" }} src={profileImage} alt={avrTitle && stringAvatar(avrTitle)} />
                <KeyboardArrowDownIcon />
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={open}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  backgroundColor: "#E4E4E4",
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 12,
                    bgcolor: 'background.paper',
                    transform: 'translateY(-50%) rotate(45deg)',
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              <Link to="/userprofile" className={useravatar}>
                <MenuItem onClick={handleClose}>
                  <div style={{ paddingTop: "3px" }} className={useravatar}>My Profile</div>
                </MenuItem>
              </Link>
              <Link to="/userpasswordchange" className={useravatar}>
                <MenuItem onClick={handleClose}>
                  <div style={{ paddingTop: "3px" }} className={useravatar}>Change Password</div>
                </MenuItem>
              </Link>
              {loggedInUserData.user.isAdmin === true ? <Link to="/mySubscription" className={useravatar}>
                <MenuItem onClick={handleClose}>
                  <div style={{ paddingTop: "3px" }} className={useravatar}>My Subscription</div>
                </MenuItem>
              </Link> : null}
              <Divider />
              <Link to="/" style={{ textDecoration: "none", display: "flex", color: 'inherit' }} onClick={(e) => logOut(e, false)}>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  <div className={userlogoutdesk}>Logout</div>
                </MenuItem>
              </Link>
            </Menu>
          </div>
          : renderDesktopLogout()}
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column", // Aligns buttons vertically
            alignItems: "flex-start", // Optionally align buttons to the left
          }}>
          <Button
            {...{
              component: NavLink,
              to: href,
              color: "inherit",
              style: { textDecoration: "none", width: "100%", justifyContent: "flex-start" },
              activeStyle: {
                color: "#fff",
                textDecoration: "none", backgroundColor: "#3E5272", width: "100%",
              },
              className: active,
              key: label,
              onClick: () => handleDrawerClose()
            }}
          >
            <MenuItem>{label}</MenuItem>
          </Button>
        </div>
      );
    });
  };

  const renderLogo = (
    <Link to="/"><img className={logo} src={ATGImage} alt="Ask Take GO" /></Link>
  );
  const renderMobileLogo = (
    <Typography variant="h6" component="h1">
      Ask Take Go
    </Typography>
  );
  const renderMobileLogout = () => {
    return (<>
      {isAuthenticated ?
        <Link to="/" title="logout" style={{ textDecoration: "none", color: 'inherit' }} onClick={(e) => logOut(e, false)}><MenuItem>Logout</MenuItem></Link>
        :
        <Link to="/" style={{ textDecoration: "none", color: 'inherit' }} title="Login"><MenuItem>Login</MenuItem></Link>}
    </>)
  }

  const renderDesktopLogout = () => {
    return (<>
      {isAuthenticated ?
        <Button
          {...{
            key: 'logout',
            color: "inherit",
            to: '/',
            component: NavLink,
            className: menuButton,
            onClick: (e) => logOut(e, false)
          }}
        >
          Logout
        </Button>
        : null
        //     <Button
        //     {...{
        //     key: 'login',
        //     color: "inherit",
        //     to: '/',
        //     component: NavLink,
        //     className: menuButton,
        //     }}
        // >
        //     Login
        // </Button>
      }
    </>)
  }

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        label === "Deals" ?
          <Badge badgeContent={dealInvitationCount} color="primary" anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          >
            <Button
              {...{
                key: label,
                color: "inherit",
                to: href,
                component: NavLink,
                className: menuButton,
                activeStyle: {
                  color: "#fff",
                  textDecoration: "none", backgroundColor: "#3E5272"
                },
              }}
            >
              {label}
            </Button>
          </Badge>
          :
          // href === "/userprofile" ?
          //   <Button
          //     {...{
          //       key: label,
          //       color: "inherit",
          //       to: href,
          //       component: NavLink,
          //       className: menuButtonProfile,
          //       activeStyle: {
          //         color: "#fff",
          //         textDecoration: "none", backgroundColor: "#3E5272"
          //       },
          //     }}
          //     sx={{ textTransform: "none", marginLeft: "18px", backgroundColor: "#855f17" }}
          //   >
          //     {label}
          //   </Button> :
          <Button
            {...{
              key: label,
              color: "inherit",
              to: href,
              component: NavLink,
              className: menuButton,
              activeStyle: {
                color: "#fff",
                textDecoration: "none", backgroundColor: "#3E5272"
              },
            }}
          >
            {label}
          </Button>
      )
    }
    );
  };
  useEffect(() => {
    // Calculate the height of the header and pass it to the parent component
    const updateHeaderHeight = () => {
      onHeightChange(headerRef.current.offsetHeight);
    };

    // Initial calculation
    updateHeaderHeight();

    // Listen for window resize events and update the header height
    window.addEventListener('resize', updateHeaderHeight);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateHeaderHeight);
    };
  }, [onHeightChange]);

  return (
    <header ref={headerRef} id="atg-header">
      <AppBar className={header}>
        {mobileView ? displayMobile() : displayDesktop()}
      </AppBar>
      <Toolbar />
    </header>
  );
}
const mapStateToProps = state => ({
  restClient: state.restClient,
  userProfileData: state.user.userProfile,
  userData: state.user.userData,
  isAuthenticated: state.user.isAuthenticated,
  loggedInUserData: state.user.loggedInUserData,
  dealInvitationCount: state.dealInvitationCount.dealInvitationCount,
  locale: state.locale
});

const mapDispatchToProps = dispatch => ({
  setUserAuthenticated: flag => dispatch(setUserAuthenticated(flag)),
  setLoggedInUserData: user => dispatch(setLoggedInUserData(user)),
  getDealInvitationsListCount: (id) => dispatch(getDealInvitationsListCount(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
