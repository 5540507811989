import { enqueueSnackbar } from './NotifierReducer';
import { setShowLoader } from './LoaderReducer';
import { push } from 'connected-react-router';
export const COMPANY_SET_DATA = 'COMPANY_SET_DATA';
export const COMPANY_SET_LIST = 'COMPANY_SET_LIST';
export const RAWDATA_SET_LIST = 'RAWDATA_SET_LIST';
export const RAWDATA_SET_DATA = 'RAWDATA_SET_DATA';
export const COMPANY_SET_SUBSCRIPTION_DATA = 'COMPANY_SET_SUBSCRIPTION_DATA';

// create company passing model 
export const submitCompanyRegisterForm = (payload = {}, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      // console.log(payload);
      const company = await client.service('register-company').create(
        payload
      );
      if (type === 'upload') {
        dispatch(getCompanyList())
      } else {
        dispatch({ type: COMPANY_SET_DATA, payload: company })
        dispatch(push('/company'));
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Company successfully Registered.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Company Registration failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const submitCompanyRawDataForm = (payload, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const rawData = await client.service('raw-data').create({
        "rawdata": payload
      });
      if (type === 'upload') {
        dispatch(getCompanyRawDataList())
      } else {
        dispatch({ type: RAWDATA_SET_DATA, payload: rawData })
        dispatch(push('/rawdata'));
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `RawData successfully Uploaded.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `RawData Upload failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const getCompanyRawDataList = () => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const fetchedCompanyList = await client.service('raw-data').find(
        {
        }
      );
      // console.log("fetchedCompanyList",fetchedCompanyList);
      let rawList = [];
      if (fetchedCompanyList.rawData && fetchedCompanyList.rawData.data)
        rawList = fetchedCompanyList.rawData.data.map(item => {
          const rawData = {};
          rawData.id = item._id;
          rawData.companyName = item.companyName;
          rawData.Address = item.props.Address;
          rawData.City = item.props.City;
          rawData.State = item.props.State;
          rawData.County = item.props.County;
          rawData.neighborhood = item.props.Neighborhood;

          return rawData;
        })
      rawList = { rawList: rawList, total: fetchedCompanyList.rawData.total, skip: fetchedCompanyList.rawData.skip }
      // console.log("rawList", rawList);
      dispatch({ type: RAWDATA_SET_LIST, payload: rawList });
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch({ type: RAWDATA_SET_LIST, payload: [] });
    }
  }
}

//Get all companies
export const getCompanyList = (pageState) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const fetchedCompanyList = await client.service('register-company').find(
        {
          query: {
            limit: pageState?.limit,
            page: pageState?.page,
            sort: pageState?.sort || 'companyName',
            search: pageState?.searchData
          }
        }
      );
      let companyList = [];
      if (fetchedCompanyList.companies && fetchedCompanyList.companies.data)
        companyList = fetchedCompanyList.companies.data.map(item => {
          const company = {};
          company.id = item._id;
          company.companyName = item.companyName[0];
          company.companyEmailAddress = item.companyEmailAddress;
          company.description = item.description;
          company.webURL = item.webURL;
          company.emailAddress = item.contact.emailAddress;
          company.phoneNumber = `+${item.contact.countryCode} ${item.phoneNumber}`;
          company.branch = item.branch;
          return company;
        })
      // companyList.sort(function (a, b) {
      //   if (a.companyName < b.companyName) {
      //     return -1;
      //   }
      //   if (a.companyName > b.companyName) {
      //     return 1;
      //   }
      //   return 0;
      // });
      companyList = { companyList: companyList, total: fetchedCompanyList.companies.total, skip: fetchedCompanyList.companies.skip }
      dispatch({ type: COMPANY_SET_LIST, payload: companyList });
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch({ type: COMPANY_SET_LIST, payload: [] });
    }
  }
}
// select company details by company Id.   
export const getCompanyById = (companyId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const company = await client.service(`register-company`).get(companyId, {})
      const data = company.company || {};
      const values = {
        companyId: data._id,
        companyName: data.companyName,
        companyEmailAddress: data.companyEmailAddress,
        industry: data.industry,
        deptOrTitle: data.deptOrTitle,
        annualRevenue: data.annualRevenue,
        branchName: data.branch.length > 0 ? data.branch[0].branchName : "",
        branchID: data.branch.length > 0 ? data.branch[0].branchID : "",
        region: data.branch.length > 0 ? data.branch[0].region : "",
        roleType: data.atgRole.roleType[0],
        contactLinkedin: data.contact.linkedin,
        dominantRole: data.atgRole.dominantRole,
        description: data.description,
        firstLine: data.address.length ? data.address[0].firstLine : '',
        secondLine: data.address[0].secondLine,
        city: data.address[0].city,
        state: data.address[0].state,
        country: data.address[0].country,
        zipcode: data.address[0].zipcode,
        name: data.address[0].firstLine,
        address1Description: data.description,
        lat: data.address[0].lat,
        long: data.address[0].long,
        webURL: data.webURL,
        taxId: data.taxId,
        phoneNumber: data.phoneNumber,
        firstName: data.contact.firstName,
        lastName: data.contact.lastName,
        alias: data.contact.firstName,
        emailAddress: data.contact.emailAddress,
        facebook: data.socialMedia !== undefined ? data.socialMedia.facebook : '',
        twitter: data.socialMedia !== undefined ? data.socialMedia.twitter : '',
        instagram: data.socialMedia !== undefined ? data.socialMedia.instagram : '',
        linkedin: data.socialMedia !== undefined ? data.socialMedia.linkedin : '',
        youtube: data.socialMedia !== undefined ? data.socialMedia.youtube : '',
        searchLinkedinCompany: data.socialMedia !== undefined ? data.socialMedia.searchLinkedinCompany : '',
        linkedinCompanyPageUrl: data.socialMedia !== undefined ? data.socialMedia.linkedinCompanyPageUrl : '',
        stockSymbol: data.additionalCompanyInfo ? data.additionalCompanyInfo.stockSymbol : '',
        numberOfEmployees: data.additionalCompanyInfo ? data.additionalCompanyInfo.numberOfEmployees : '',
        contact1WebURL: data.contact.webURL,
        countryCode: data.contact.countryCode,
        mobileNumber: data.phoneNumber,
        whatsAppNumber: data.phoneNumber,
        smsNumber: data.phoneNumber,
        automaticDealCreation: data.automaticDealCreation,
        subscriptionData: data.subscriptionData,
      };
      dispatch({ type: COMPANY_SET_DATA, payload: values })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }

  }
}

// select company details by company Id.   
export const getCompanyForChatById = (companyId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const company = await client.service(`register-company`).get(companyId, {})
      const data = company.company || {};
      const values = {
        companyId: data._id,
        companyName: data.companyName,
        companyEmailAddress: data.companyEmailAddress,
        industry: data.industry,
        deptOrTitle: data.deptOrTitle,
        annualRevenue: data.annualRevenue,
        branchName: data.branch.length > 0 ? data.branch[0].branchName : "",
        branchID: data.branch.length > 0 ? data.branch[0].branchID : "",
        region: data.branch.length > 0 ? data.branch[0].region : "",
        roleType: data.atgRole.roleType[0],
        contactLinkedin: data.contact.linkedin,
        dominantRole: data.atgRole.dominantRole,
        description: data.description,
        firstLine: data.address.length ? data.address[0].firstLine : '',
        secondLine: data.address[0].secondLine,
        city: data.address[0].city,
        state: data.address[0].state,
        country: data.address[0].country,
        zipcode: data.address[0].zipcode,
        name: data.address[0].firstLine,
        address1Description: data.description,
        lat: data.address[0].lat,
        long: data.address[0].long,
        webURL: data.webURL,
        taxId: data.taxId,
        phoneNumber: data.phoneNumber,
        firstName: data.contact.firstName,
        lastName: data.contact.lastName,
        alias: data.contact.firstName,
        emailAddress: data.contact.emailAddress,
        facebook: data.socialMedia !== undefined ? data.socialMedia.facebook : '',
        twitter: data.socialMedia !== undefined ? data.socialMedia.twitter : '',
        instagram: data.socialMedia !== undefined ? data.socialMedia.instagram : '',
        linkedin: data.socialMedia !== undefined ? data.socialMedia.linkedin : '',
        youtube: data.socialMedia !== undefined ? data.socialMedia.youtube : '',
        searchLinkedinCompany: data.socialMedia !== undefined ? data.socialMedia.searchLinkedinCompany : '',
        linkedinCompanyPageUrl: data.socialMedia !== undefined ? data.socialMedia.linkedinCompanyPageUrl : '',
        stockSymbol: data.additionalCompanyInfo ? data.additionalCompanyInfo.stockSymbol : '',
        numberOfEmployees: data.additionalCompanyInfo ? data.additionalCompanyInfo.numberOfEmployees : '',
        contact1WebURL: data.contact.webURL,
        countryCode: data.contact.countryCode,
        mobileNumber: data.phoneNumber,
        whatsAppNumber: data.phoneNumber,
        smsNumber: data.phoneNumber,
        automaticDealCreation: data.automaticDealCreation,
        subscriptionData: data.subscriptionData,
      };
      dispatch({ type: COMPANY_SET_SUBSCRIPTION_DATA, payload: values })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }

  }
}

// edit company        
export const editCompany = (companyId, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('register-company').patch(companyId, payload);
      dispatch(enqueueSnackbar({
        message: 'Company information EDITED And Saved.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/company'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit Company information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
// for delete company
export const deleteCompany = (companyId, companyList, totalCount) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('register-company').remove(companyId);
      const newList = [...companyList];
      const index = companyList.findIndex(item => item.id === companyId);
      if (index !== -1) { // Check if the item was found
        newList.splice(index, 1);
        const updatedTotalRecords = totalCount - 1; // Update totalRecords
        let listing = { companyList: newList, total: updatedTotalRecords }
        dispatch({ type: COMPANY_SET_LIST, payload: listing });
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Company Information is deleted successfully',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export const patchCompany = (companyId, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const newCompanyList = await client.service('register-company').patch(companyId, payload);
      dispatch({ type: COMPANY_SET_LIST, payload: newCompanyList });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Company details are edited successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Company activation/deactivation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export default function reducer(
  state = {
    companyData: {},
    companyDataForChat: {},
    companyList: [],
    rawData: {},
    rawList: []
  },
  action
) {
  switch (action.type) {
    case COMPANY_SET_DATA:
      return {
        ...state,
        companyData: action.payload
      };
    case COMPANY_SET_LIST:
      return {
        ...state,
        companyList: action.payload
      };
    case COMPANY_SET_SUBSCRIPTION_DATA:
      return {
        ...state,
        companyDataForChat: action.payload
      };
    case RAWDATA_SET_DATA:
      return {
        ...state,
        rawData: action.payload
      };
    case RAWDATA_SET_LIST:
      return {
        ...state,
        rawList: action.payload
      };
    default:
      break;
  }
  return state;
}