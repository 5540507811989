import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import useQuery from '../../../../utils/useQuery';
import {
    AppContent,
    AppContentInner,
    AppWrapper,
    AppMain,
    Header,
    HeaderText,
} from '../../../../styles/appStyles';
import { submitVendorRegisterForm, getVendorById, editVendor } from '../../../../reducers/VendorReducer';
import {
    FormControlWrapper,
    FormButtonWrapper
} from '../../../../styles/formStyles';
import { schema, initialFormValues, getPayload } from './utils';
import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
    FormControl,
    TextField,
} from '@mui/material';
import validate from 'validate.js';


validate.validators.IsValidURL = function (value) {
    var regex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
    if (!validate.isEmpty(value) && !regex.test(value)) {
        return "Company Web Site Is Not Valid";
    }
    return null
};


const CreateVendorCompany = props => {
    const { restClient: { rest }, vendorData, getVendorById } = props;
    const query = useQuery();
    const action = query.get('action');
    const VendorID = query.get('id');
    const [formState, setFormState] = useState({
        isValid: false,
        values: initialFormValues,
        touched: {},
        errors: {},
    });

    const hasError = (field) => (!!(formState.touched[field] && formState.errors[field]));
    const handleChange = (event) => {
        // event.persist();
        setFormState((prevFormState) => ({
            ...prevFormState,
            values: {
                ...prevFormState.values,
                [event.target.name]:
                    event.target.type === 'file'
                        ? event.target.files[0].name
                        : event.target.value
            },
            touched: {
                ...prevFormState.touched,
                [event.target.name]: true
            }
        }));
    };

    // const handleStateChange = (field, value) => {
    //     setFormState((prevFormState) => ({
    //         ...prevFormState,
    //         values: {
    //             ...prevFormState.values,
    //             [field]: value
    //         },
    //         touched: {
    //             ...prevFormState.touched,
    //             [field]: true
    //         }
    //     }));
    // };
    useEffect(() => {
        if (action === 'edit' && VendorID && rest) {
            const getVendor = async () => {
                getVendorById(VendorID);
            }
            getVendor();
        }
    }, [action, getVendorById, VendorID, rest])

    useEffect(() => {
        if (action === 'edit' && vendorData) {
            setFormState({
                isValid: false,
                values: vendorData,
                touched: {},
                errors: {},
            });
        }
    }, [action, vendorData]);

    useEffect(() => {
        const errors = validate(formState.values, schema, { fullMessages: false });

        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {}
        }));

    }, [formState.values]);

    // const handleStartCaseChange = (event) => {
    //     const { name, value } = event.target;
    //     handleStateChange(name, StartCase(value));
    // }

    // const StartCase = (text) => {
    //     return text
    //         .split(" ")
    //         .map(word => {
    //             return word.charAt(0).toUpperCase() + word.slice(1);
    //         })
    //         .join(" ");
    // };
    const checkErrors = () => {
        let isErrorExist = false;
        let touched = {};
        for (let key in formState.values) {
            if (formState.values.hasOwnProperty(key)) {
                touched = {
                    ...touched,
                    [key]: true
                }
            }
        }
        const errors = validate(formState.values, schema, { fullMessages: false });
        if (errors) {
            isErrorExist = true;
        }
        setFormState((prevFormState) => ({
            ...prevFormState,
            isValid: !errors,
            errors: errors || {},
            touched: touched,
        }));
        return isErrorExist;
    }
    const submitForm = event => {
        event.preventDefault();
        if (!checkErrors()) {
            const { values } = formState;
            const payload = getPayload(values);
            console.log(payload)
            if (action === 'edit' && VendorID) {
                props.editVendor(VendorID, payload);
            }
            else {
                props.submitVendorRegisterForm(payload);
            }
        }
    }
    return (
        <AppContent>
            <AppContentInner>
                <AppWrapper>
                    <AppMain>
                        <Container maxWidth="lg">
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={12}>
                                    <Card >
                                        <Header>
                                            <HeaderText >
                                                <h5>{action === 'edit' ? 'View / Edit Vendor Company' : 'Create Vendor Company'}</h5>
                                            </HeaderText>
                                        </Header>
                                        <CardContent >
                                            <form className='formSpacing' onSubmit={submitForm}>
                                                <Grid container spacing={4}>
                                                    <Grid item lg={6} xs={12}>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('companyName')}
                                                                    helperText={
                                                                        hasError('companyName') ? formState.errors.companyName[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Vendor Company Name"
                                                                    fullWidth
                                                                    placeholder="Vendor Company Name"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-companyName"
                                                                    name="companyName"
                                                                    id="companyName"
                                                                    value={formState.values.companyName}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('webURL')}
                                                                    helperText={
                                                                        hasError('webURL') ? formState.errors.webURL[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Vendor Company Website"
                                                                    fullWidth
                                                                    placeholder="Vendor Company Website"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-webURL"
                                                                    name="webURL"
                                                                    id="webURL"
                                                                    value={formState.values.webURL}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('companyEmailAddress')}
                                                                    helperText={
                                                                        hasError('companyEmailAddress') ? formState.errors.companyEmailAddress[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Vendor Company Email Address"
                                                                    fullWidth
                                                                    placeholder="Vendor Company Email Address"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-companyEmailAddress"
                                                                    name="companyEmailAddress"
                                                                    id="companyEmailAddress"
                                                                    value={formState.values.companyEmailAddress}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('country')}
                                                                    helperText={
                                                                        hasError('country') ? formState.errors.country[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="country"
                                                                    fullWidth
                                                                    placeholder="country"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-country"
                                                                    name="country"
                                                                    id="country"
                                                                    value={formState.values.country}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('state')}
                                                                    helperText={
                                                                        hasError('state') ? formState.errors.state[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Vendor Company State"
                                                                    fullWidth
                                                                    placeholder="Vendor Company State"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-state"
                                                                    name="state"
                                                                    id="state"
                                                                    value={formState.values.state}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('city')}
                                                                    helperText={
                                                                        hasError('city') ? formState.errors.city[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Vendor Company City"
                                                                    fullWidth
                                                                    placeholder="Vendor Company City"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-city"
                                                                    name="city"
                                                                    id="city"
                                                                    value={formState.values.city}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('zipcode')}
                                                                    helperText={
                                                                        hasError('zipcode') ? formState.errors.zipcode[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Vendor Company Zipcode"
                                                                    fullWidth
                                                                    placeholder="Vendor Company Zipcode"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-zipcode"
                                                                    name="zipcode"
                                                                    id="zipcode"
                                                                    value={formState.values.zipcode}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('phoneNumber')}
                                                                    helperText={
                                                                        hasError('phoneNumber') ? formState.errors.phoneNumber[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Phone Number"
                                                                    fullWidth
                                                                    placeholder="Phone Number"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-phoneNumber"
                                                                    name="phoneNumber"
                                                                    id="phoneNumber"
                                                                    value={formState.values.phoneNumber}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('firstLine')}
                                                                    helperText={
                                                                        hasError('firstLine') ? formState.errors.firstLine[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Vendor Address"
                                                                    fullWidth
                                                                    placeholder="Vendor Address"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-firstLine"
                                                                    name="firstLine"
                                                                    id="firstLine"
                                                                    value={formState.values.firstLine}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>

                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('industry')}
                                                                    helperText={
                                                                        hasError('industry') ? formState.errors.industry[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="industry"
                                                                    fullWidth
                                                                    placeholder="industry"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-industry"
                                                                    name="industry"
                                                                    id="industry"
                                                                    value={formState.values.industry}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('stockSymbol')}
                                                                    helperText={
                                                                        hasError('stockSymbol') ? formState.errors.stockSymbol[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Vendor Stock Symbol"
                                                                    fullWidth
                                                                    placeholder="Vendor Stock Symbol"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-stockSymbol"
                                                                    name="stockSymbol"
                                                                    id="stockSymbol"
                                                                    value={formState.values.stockSymbol}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('searchLinkedinCompany')}
                                                                    helperText={
                                                                        hasError('searchLinkedinCompany') ? formState.errors.searchLinkedinCompany[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Search Linkedin Company"
                                                                    fullWidth
                                                                    placeholder="Search Linkedin Company"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-searchLinkedinCompany"
                                                                    name="searchLinkedinCompany"
                                                                    id="searchLinkedinCompany"
                                                                    value={formState.values.searchLinkedinCompany}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('linkedinCompanyPageUrl')}
                                                                    helperText={
                                                                        hasError('linkedinCompanyPageUrl') ? formState.errors.linkedinCompanyPageUrl[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Linkedin Company Page Url"
                                                                    fullWidth
                                                                    placeholder="Linkedin Company Page Url"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-linkedinCompanyPageUrl"
                                                                    name="linkedinCompanyPageUrl"
                                                                    id="linkedinCompanyPageUrl"
                                                                    value={formState.values.linkedinCompanyPageUrl}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                    </Grid>
                                                    <Grid item lg={6} xs={12}>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('deptOrTitle')}
                                                                    helperText={
                                                                        hasError('deptOrTitle') ? formState.errors.deptOrTitle[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Dept Or Title"
                                                                    fullWidth
                                                                    placeholder="Dept Or Title"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-deptOrTitle"
                                                                    name="deptOrTitle"
                                                                    id="deptOrTitle"
                                                                    value={formState.values.deptOrTitle}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('firstName')}
                                                                    helperText={
                                                                        hasError('firstName') ? formState.errors.firstName[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="First Name"
                                                                    fullWidth
                                                                    placeholder="First Name"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-firstName"
                                                                    name="firstName"
                                                                    id="firstName"
                                                                    value={formState.values.firstName}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('emailAddress')}
                                                                    helperText={
                                                                        hasError('emailAddress') ? formState.errors.emailAddress[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Email Address"
                                                                    fullWidth
                                                                    placeholder="Email Address"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-emailAddress"
                                                                    name="emailAddress"
                                                                    id="emailAddress"
                                                                    value={formState.values.emailAddress}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('contactLinkedin')}
                                                                    helperText={
                                                                        hasError('contactLinkedin') ? formState.errors.contactLinkedin[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Contact Linkedin"
                                                                    fullWidth
                                                                    placeholder="Contact Linkedin"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-contactLinkedin"
                                                                    name="contactLinkedin"
                                                                    id="contactLinkedin"
                                                                    value={formState.values.contactLinkedin}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('annualRevenue')}
                                                                    helperText={
                                                                        hasError('annualRevenue') ? formState.errors.annualRevenue[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Annual Revenue"
                                                                    fullWidth
                                                                    placeholder="Annual Revenue"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-annualRevenue"
                                                                    name="annualRevenue"
                                                                    id="annualRevenue"
                                                                    value={formState.values.annualRevenue}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('numberOfEmployees')}
                                                                    helperText={
                                                                        hasError('numberOfEmployees') ? formState.errors.numberOfEmployees[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Number Of Employees"
                                                                    fullWidth
                                                                    placeholder="Number Of Employees"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-numberOfEmployees"
                                                                    name="numberOfEmployees"
                                                                    id="numberOfEmployees"
                                                                    value={formState.values.numberOfEmployees}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('description')}
                                                                    helperText={
                                                                        hasError('description') ? formState.errors.description[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Description"
                                                                    fullWidth
                                                                    placeholder="Description"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-description"
                                                                    name="description"
                                                                    id="description"
                                                                    value={formState.values.description}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('companyOwner')}
                                                                    helperText={
                                                                        hasError('companyOwner') ? formState.errors.companyOwner[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Company Owner"
                                                                    fullWidth
                                                                    placeholder="Company Owner"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-companyOwner"
                                                                    name="companyOwner"
                                                                    id="companyOwner"
                                                                    value={formState.values.companyOwner}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('facebook')}
                                                                    helperText={
                                                                        hasError('facebook') ? formState.errors.facebook[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Facebook"
                                                                    fullWidth
                                                                    placeholder="Facebook"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-facebook"
                                                                    name="facebook"
                                                                    id="facebook"
                                                                    value={formState.values.facebook}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('twitter')}
                                                                    helperText={
                                                                        hasError('twitter') ? formState.errors.twitter[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Twitter"
                                                                    fullWidth
                                                                    placeholder="Twitter"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-twitter"
                                                                    name="twitter"
                                                                    id="twitter"
                                                                    value={formState.values.twitter}
                                                                    onChange={handleChange}
                                                                /></FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('instagram')}
                                                                    helperText={
                                                                        hasError('instagram') ? formState.errors.instagram[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Instagram"
                                                                    fullWidth
                                                                    placeholder="Instagram"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-instagram"
                                                                    name="instagram"
                                                                    id="instagram"
                                                                    value={formState.values.instagram}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('linkedin')}
                                                                    helperText={
                                                                        hasError('linkedin') ? formState.errors.linkedin[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Linkedin"
                                                                    fullWidth
                                                                    placeholder="Linkedin"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-linkedin"
                                                                    name="linkedin"
                                                                    id="linkedin"
                                                                    value={formState.values.linkedin}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                        <FormControlWrapper>
                                                            <FormControl className="w-100">
                                                                <TextField
                                                                    error={hasError('youtube')}
                                                                    helperText={
                                                                        hasError('youtube') ? formState.errors.youtube[0] : null
                                                                    }
                                                                    variant="outlined"
                                                                    label="Youtube"
                                                                    fullWidth
                                                                    placeholder="Youtube"
                                                                    type="text"
                                                                    data-test-id="Create-Vendor-youtube"
                                                                    name="youtube"
                                                                    id="youtube"
                                                                    value={formState.values.youtube}
                                                                    onChange={handleChange}
                                                                />
                                                            </FormControl>
                                                        </FormControlWrapper>
                                                    </Grid>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <Grid container justifyContent="center" spacing={5}>
                                                            <Grid item>
                                                                    <FormButtonWrapper>
                                                                        <Link to="/vendor" className='linkStyle'>
                                                                            <Button
                                                                                type="submit"
                                                                                color="secondary"
                                                                                data-test-id="Create-Vendor-Cancel"
                                                                                variant="contained"
                                                                                size="large"
                                                                                className="my-2">
                                                                                cancel
                                                                            </Button>
                                                                        </Link>
                                                                    </FormButtonWrapper>
                                                                </Grid>
                                                                <Grid item>
                                                                    <FormButtonWrapper>
                                                                        <Button
                                                                            type="submit"
                                                                            color="primary"
                                                                            variant="contained"
                                                                            data-test-id="Create-Vendor-Submit"
                                                                            size="large"
                                                                            className="Buttons">
                                                                            {action === 'edit' ? 'Update' : 'Submit'}
                                                                        </Button>
                                                                    </FormButtonWrapper>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </form>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </AppMain>
                </AppWrapper>
            </AppContentInner>
        </AppContent>
    );
};
const mapStateToProps = state => ({
    restClient: state.restClient,
    vendorData: state.vendor.vendorData,
});
export default connect(mapStateToProps, { submitVendorRegisterForm, getVendorById, editVendor })(CreateVendorCompany);