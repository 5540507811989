import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material';

const useStyles = makeStyles({
    hidePagination: {
      '& .MuiDataGrid-footerContainer': {
        display: 'none',
      },
    },
  });

  const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    "& .MuiDataGrid-columnHeaders": {
                        backgroundColor: "#1a75c3",
                        color: "white"
                    },
                },
                columnSeparator: {
                    visibility: "hidden",
                },
            },
        },
    },
});

export default function AutoHeightOverlayNoSnap({ equipment }) {
    const classes = useStyles();
    
    const columns = [
        {
            field: "equipmentName",
            headerName: "Equipment Name",
            width: 350,
        },
        // {
        //     field: "comparableRetailPrice",
        //     headerName: "Retail Price",
        //     type: "string",
        //     width: 150,
        // },
        // {
        //     field: "majorCategory",
        //     headerName: "Major Category",
        //     type: "string",
        //     width: 170,
        // },
        // {
        //     field: "fleetType",
        //     headerName: "Fleet Type",
        //     width: 100,
        // },
        {
            field: "make",
            headerName: "Make",
            width: 150,
        },
        {
            field: "model",
            headerName: "Model",
            width: 120,
        },
        {
            field: "modelYear",
            headerName: "Model Year",
            width: 150,
            valueFormatter: (params) => {
              const date = new Date(params.value);
              return date.getFullYear();
            },
        },
        {
            field: "listingPrice",
            headerName: "Listing Price",
            width: 100,
        },
    ];
    const getRowId = (row) => row._id;
    return (
        <Box sx={{ width: 900, border: 0, }}>
            <ThemeProvider theme={theme}>
            <DataGrid
                autoHeight
                columns={columns}
                rows={equipment || []}
                sx={{ width: 900, border: 0, }}
                getRowId={getRowId}
                pageSize={5} // Set the number of rows per page (optional)
                pagination={false} // Disable pagination
                disableClickEventBubbling={true}
                disableColumnMenu={true}
                disableColumnSelector={true}
                className={classes.hidePagination}
            />
            </ThemeProvider>
        </Box>
    );
}
