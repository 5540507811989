import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Chip, Box } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { connect } from "react-redux";
import Slider from 'react-slick';  // For horizontal sliding, install react-slick and slick-carousel
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

import {
    getUserList,
    setLoggedInUserData,
    submitContactForm
} from "../../reducers/UserReducer";

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

function ContactDialog(props) {
    const {
        userList = [],
        restClient: { rest },
        getUserList,
        loggedInUserData,
        submitContactForm,
    } = props;
    const [open, setOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);
    const [communicationType, setCommunicationType] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactId, setContactId] = useState('');
    const [contactValue, setContactValue] = useState('');
    const [contactSubject, setContactSubject] = useState('');
    const [notes, setNotes] = useState('');
    const [files, setFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [fileTypes, setFileTypes] = useState([]);
    const [error, setError] = useState('');

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSelectedContact(null);
        setCommunicationType('');
        setContactValue('');
        setContactSubject('');
        setNotes('');
        setFiles([]);
        setFileNames([]);
        setFileTypes([]);
        setError('');
    };
    useEffect(() => {
        const pageState = {
            page: 1,
            limit: 100,
            sort: null,
            searchData: ""
        };
        const getList = async () => {
            if (rest) {
                if (pageState) {
                    await getUserList(pageState, true, loggedInUserData);
                }
            }
        };
        getList();
    }, [rest, getUserList, loggedInUserData]);
    const handleContactChange = (contactId) => {
        const contact = userList.length > 0 && userList.find((item) => item.id === contactId);
        if (contact) {
            setSelectedContact(contact);
            setContactId(contactId);
            setContactName(`${contact?.name}`);
            setContactValue(''); // Clear previous value
            setCommunicationType(''); // Reset communication type selection
            setContactSubject('');
            setError('');
        }
    };

    const handleCommunicationTypeChange = (type) => {
        setCommunicationType(type);
        let newError = ''; // Start with no error
        if (type === 'Email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            newError = emailRegex.test(selectedContact?.email) ? '' : 'Invalid email format.';
            setContactValue(selectedContact?.email || '');
        } else if (type === 'WhatsApp' || type === 'SMS') {
            const phoneRegex = /^\+[1-9]\d{0,2}\s?\d{1,14}$/; // Updated regex
            newError = phoneRegex.test(selectedContact?.phone)
                ? ''
                : 'Invalid phone format. Use international format, e.g., +123 1231231232.';
            setContactValue(selectedContact?.phone || '');
        }
        setError(newError);
    };

    const handleContactValueChange = (event) => {
        const value = event.target.value.trim(); // Trim spaces at the start and end
        setContactValue(value);

        let newError = ''; // Start with no error
        if (communicationType === 'Email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            newError = emailRegex.test(value) ? '' : 'Invalid email format.';
        } else if (communicationType === 'WhatsApp' || communicationType === 'SMS') {
            const phoneRegex = /^\+[1-9]\d{0,2}\s?\d{1,14}$/; // Updated regex
            newError = phoneRegex.test(value)
                ? ''
                : 'Invalid phone format. Use international format, e.g., +123 1231231232.';
        }

        setError(newError);
    };

    const handleSubjectChange = (event) => setContactSubject(event.target.value);

    const handleNotesChange = (event) => setNotes(event.target.value);

    const handleChange = (event) => {
        const newFiles = Array.from(event.target.files);
        setFiles([...files, ...newFiles]);
        setFileNames([...fileNames, ...newFiles.map(file => file.name)]);
        setFileTypes([...fileTypes, ...newFiles.map(file => file.type)]);
    };

    const handleRemoveFile = (index) => {
        setFiles(files.filter((_, i) => i !== index));
        setFileNames(fileNames.filter((_, i) => i !== index));
        setFileTypes(fileTypes.filter((_, i) => i !== index));
    };

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (error) {
            return; // Prevent submit if there’s an error or field is empty
        }
        const payload = {
            "userId": contactId,//Id of selected User
            "contactName": contactName,//Name selected User
            "communicationType": communicationType,//Name of Communication Type
            "contactValue": contactValue,//Email or phone numbeer
            "subject": contactSubject,//Email Subject
            "notes": notes.trim(),//Notes
            "attachments": files.map((file, index) => ({
                filename: fileNames[index], // Provide a file name with an appropriate extension
                content: file, // Replace this with your buffer variable
                fileType: fileTypes[index],
            })),
        };
        // alert(JSON.stringify(payload));
        await submitContactForm(payload, loggedInUserData)
        // alert(`Contact Name: ${contactName}\nCommunication Type: ${communicationType}\nValue: ${contactValue}\nNotes: ${notes}`);
        handleClose();
    };
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
    };
    return (
        <Stack spacing={2} direction="row">
            <Button
                variant="text"
                onClick={handleClickOpen}
                style={{
                    display: "flex",
                    flexDirection: "column", // Aligns buttons vertically
                    alignItems: "center", // Optionally align buttons to the left
                    marginLeft: "15px"
                }}>
                Contact
            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{contactName ? `Contact: ${contactName}` : 'Select a Contact'}</DialogTitle>
                <DialogContent>
                    <FormControl fullWidth margin="normal">
                        <InputLabel id="contact-select-label">Select Contact *</InputLabel>
                        <Select
                            labelId="contact-select-label" // Link to InputLabel
                            id="contact-select"           // Optional: Helps debugging and testing
                            value={selectedContact?.id || ''}
                            onChange={(e) => handleContactChange(e.target.value)}
                            label="Select Contact *"        // Ensure the label is passed here
                            required
                            name="contactId"
                        >
                            {(userList || []).map((contact) => (
                                <MenuItem key={contact?.id} value={contact?.id}>
                                    {contact?.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {selectedContact && (
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="communication-type-label">Select Communication Type*</InputLabel>
                            <Select
                                labelId="communication-type-label"
                                value={communicationType}
                                onChange={(e) => handleCommunicationTypeChange(e.target.value)}
                                required
                                label="Select Communication Type*"
                                name="communicationType"
                                error={Boolean(error)} // Ensures the error state reflects correctly
                                helperText={error} // Displays the error message
                            >
                                <MenuItem value="Email">Email</MenuItem>
                                {/* <MenuItem value="SMS">SMS</MenuItem>
                                <MenuItem value="WhatsApp">WhatsApp</MenuItem> */}
                            </Select>
                        </FormControl>
                    )}
                    {communicationType && (
                        <>
                            <TextField
                                fullWidth
                                margin="normal"
                                label={communicationType === 'Email' ? 'Email' : 'Phone Number'}
                                value={contactValue}
                                onChange={handleContactValueChange}
                                required
                                error={Boolean(error)} // Ensures the error state reflects correctly
                                helperText={error} // Displays the error message
                                name="contactValue"
                            />
                            {
                                communicationType === 'Email' &&
                                <TextField
                                    fullWidth
                                    margin="normal"
                                    label={"Subject"}
                                    value={contactSubject}
                                    onChange={handleSubjectChange}
                                    required
                                    name="contactSubject"
                                />
                            }
                        </>
                    )}
                    <TextField
                        fullWidth
                        margin="normal"
                        label="Additional Notes"
                        multiline
                        rows={4}
                        value={notes}
                        onChange={handleNotesChange}
                        name="notes"
                    />
                    <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        tabIndex={-1}
                        startIcon={<CloudUploadIcon />}
                    >
                        Upload files
                        <VisuallyHiddenInput
                            type="file"
                            onChange={handleChange}
                            multiple
                        />
                    </Button>
                    {fileNames.length > 0 && (
                        <Box mt={2}>
                            <Slider {...settings}>
                                {fileNames.map((fileName, index) => (
                                    <Stack key={index}>
                                        <Chip
                                            label={fileName}
                                            onDelete={() => handleRemoveFile(index)}
                                            deleteIcon={<CancelOutlinedIcon />}
                                            style={{ marginTop: 8 }}
                                        />
                                    </Stack>
                                ))}
                            </Slider>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button
                        onClick={handleSubmit}
                        disabled={
                            !contactValue ||                                // Check if contactValue is falsy
                            !communicationType ||                          // Check if communicationType is falsy
                            (communicationType === "Email" && !contactSubject) || // Check if contactSubject is required and falsy
                            !notes.trim() ||                               // Check if notes.trim() is empty
                            !!error                                        // Check if error is truthy
                        }>
                        Submit
                    </Button>
                </DialogActions>
                <p style={{
                    color: "red",
                    display: !(!contactValue ||                                // Check if contactValue is falsy
                        !communicationType ||                          // Check if communicationType is falsy
                        (communicationType === "Email" && !contactSubject) || // Check if contactSubject is required and falsy
                        !notes.trim()) && "none"
                }}>*Please enter all the fields required to enable SUBMIT button</p>
            </Dialog>
        </Stack>
    );
}
const mapStateToProps = (state) => ({
    restClient: state.restClient,
    userList: state.user && state.user.userList.userList,
    loggedInUserData: state.user && state.user.loggedInUserData,
});

export default connect(mapStateToProps, {
    getUserList,
    setLoggedInUserData,
    submitContactForm,
})(ContactDialog);
