import XLSX from "xlsx";

export const getUploadPayload = (data) => {
    try {
        const workbook = XLSX.read(data, { type: 'binary' });
        const firstSheet = workbook.SheetNames[0];
        const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet], { raw: false });

        const payload = excelRows.map(row => {
            // Extract company name from the row
            const companyName = row.companyName;

            // Extract all other columns dynamically from the row
            const props = {};
            Object.keys(row).forEach(key => {
                // Exclude the "companyName" column
                if (key !== "companyName") {
                    // Replace dots with underscores in the key
                    const formattedKey = key.replace(/\./g, '_');
                    props[formattedKey] = row[key] || ""; // Example: Empty string as default value
                }
            });

            return {
                // rawData: {
                    companyName: companyName,
                    props: props
                // }
            };
        });

        // console.log("payload", payload);

        return payload;
    } catch (error) {
        console.error("Error processing Excel file:", error);
        return []; // Return an empty array if there's an error
    }
};