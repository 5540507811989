import { IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';
import * as React from 'react';

function SnackbarCloseButton({ snackbarKey }) {
  const { closeSnackbar } = useSnackbar();

  return (
    <IconButton onClick={() => closeSnackbar(snackbarKey)} style={{ color: 'white' }}>
      <CloseIcon />
    </IconButton>
  );
}

export default SnackbarCloseButton;