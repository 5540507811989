import { push } from 'connected-react-router';
import { enqueueSnackbar } from './NotifierReducer';
import { setShowLoader } from './LoaderReducer';
import { getDealInvitationsListCount } from './DealInvitationCountReducer'
// User
export const USER_SET_AUTHENTICATED = 'USER_SET_AUTHENTICATED';
export const LOGGEDIN_USER_SET_DATA = 'LOGGEDIN_USER_SET_DATA';
export const USER_SET_LIST = 'USER_SET_LIST';
export const USER_SET_DATA = 'USER_SET_DATA';
export const SET_ASK_LOGIN_OTP = 'SET_ASK_LOGIN_OTP';
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA';

export const submitLoginForm = (userName, password, otp, resend) => {
  return async (dispatch, getState) => {
    const client = getState().restClient.rest;
    try {
      const user = await client.authenticate({
        email: userName,
        password: password,
        strategy: 'local',
        otp: otp
      })
      if (!user.accessToken && user === 'Credentials valid. 2FA authentication needed, email has been sent.') {
        if (resend) {
          dispatch(enqueueSnackbar({
            message: user,
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'success',
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
              }
            },
          }));
        }
        dispatch({ type: SET_ASK_LOGIN_OTP, payload: true });
      }
      else if (user.user.parentCompany || user.user.roleSystem === 'admin') {
        dispatch({ type: USER_SET_DATA, payload: user });
        dispatch({ type: LOGGEDIN_USER_SET_DATA, payload: user });
        dispatch({ type: SET_PROFILE_DATA, payload: user.user });
        dispatch({ type: USER_SET_AUTHENTICATED, payload: true });
        dispatch(getDealInvitationsListCount(user.user._id));
        dispatch({ type: SET_ASK_LOGIN_OTP, payload: false });
      } else {
        dispatch(enqueueSnackbar({
          message: `Login failed. Approve pending from Admin.`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
            }
          },
        }));
        dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
      }
    } catch (error) {
      dispatch(enqueueSnackbar({
        message: `Login failed. Please try again. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch({ type: USER_SET_AUTHENTICATED, payload: false });
    }
  }
}

export const setUserAuthenticated = (state, flag = false) => {
  return (dispatch, getState) => {
    const client = getState().restClient.rest;
    if (client) {
      if (state === false && flag) {
        client.logout();
        dispatch(push('/login'));
      } else if (state === false) {
        client.logout();
      }
    }
    dispatch({ type: USER_SET_AUTHENTICATED, payload: state });
  }
}
export const setUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: USER_SET_DATA, payload: data });
  }
}

export const setLoggedInUserData = (data) => {
  return (dispatch) => {
    dispatch({ type: LOGGEDIN_USER_SET_DATA, payload: data });
  }
}

export const setAskLoginOtp = (data) => {
  return (dispatch) => {
    dispatch({ type: SET_ASK_LOGIN_OTP, payload: data });
  }
}

//Get all Users
export const getUserList = (pageState, flag = false, loggedInUserData) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const fetchedUserList = await client.service('user').find(
        {
          query: {
            limit: pageState?.limit,
            page: pageState?.page,
            sort: pageState?.sort || 'email',
            search: pageState?.searchData
          }
        }
      );
      let userList = [];
      if (fetchedUserList.userdata && fetchedUserList.userdata.data)
        userList = fetchedUserList.userdata.data.map(item => {
          const user = {};
          user.id = item._id;
          user.email = item.email ?? "";
          user.roleType = (item.atgRole && item.atgRole.roleType[0]) ?? '';
          user.phone = (item.contact && item.contact.countryCode) ? `+${item.contact.countryCode} ${item.phone}` : `${item.phone}`;
          user.roleSystem = item.roleSystem ?? '';
          user.name = (item.contact && item.contact.firstName[0] && item.contact.lastName[0]) ? `${item.contact.firstName[0]} ${item.contact.lastName[0]}` : '';
          user.firstName = (item.contact && item.contact.firstName) ? item.contact.firstName[0] : '';
          user.lastName = (item.contact && item.contact.lastName) ? item.contact.lastName[0] : '';
          user.companyName = (item.parentCompany && item.parentCompany.companyName) ? (item.parentCompany && item.parentCompany.companyName[0]) : '';
          user.branch = (item.parentCompany && item.parentCompany.branch) ? (item.parentCompany && item.parentCompany.branch) : '';
          user.parentCompany = item.parentCompany ? item.parentCompany._id : '';
          return user;
        })
      if (flag) {
        userList = userList.filter((user) => user.id != loggedInUserData.user._id);
      }
      for (const element of userList) {
        element.total = `${fetchedUserList.userdata.total}`;
      }
      // userList.sort(function (a, b) {
      //   if (a.name < b.name) {
      //     return -1;
      //   }
      //   if (a.name > b.name) {
      //     return 1;
      //   }
      //   return 0;
      // });
      userList = { userList: userList, total: fetchedUserList.userdata.total, skip: fetchedUserList.userdata.skip }
      dispatch({ type: USER_SET_LIST, payload: userList })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch({ type: USER_SET_LIST, payload: [] })
    }
  }
}

export const getUserPendingList = (pageState, isRegisteredUser) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const fetchedUserList = await client.service('user').find(
        {
          query: {
            limit: pageState?.limit,
            page: pageState?.page,
            sort: pageState?.sort,
            search: pageState?.searchData,
            isRegisteredUser: isRegisteredUser
          }
        }
      );
      let userList = [];
      if (fetchedUserList.userdata && fetchedUserList.userdata.data)
        userList = fetchedUserList.userdata.data.map(item => {
          const user = {};
          user.id = item._id;
          user.email = item.email;
          user.roleType = item.atgRole && item.atgRole.roleType[0];
          user.phone = `+${item.contact.countryCode} ${item.phone}`;
          user.roleSystem = item.roleSystem;
          user.name = `${item.contact.firstName} ${item.contact.lastName}`;
          user.firstName = item.contact.firstName[0];
          user.lastName = item.contact.lastName[0];
          user.companyName = item.parentCompany && item.parentCompany.companyName[0];
          return user;
        })
      for (const element of userList) {
        element.total = `${fetchedUserList.userdata.total}`;
      }
      let sortedUsers;
      if (pageState?.sort === null) {
        sortedUsers = userList.sort(function (a, b) {
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
      } else {
        sortedUsers = userList
      }
      userList = { userList: sortedUsers, total: fetchedUserList.userdata.total, skip: fetchedUserList.userdata.skip }
      dispatch({ type: USER_SET_LIST, payload: userList })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch({ type: USER_SET_LIST, payload: [] })
    }
  }
}

//Send the communication to Selected user
export const submitContactForm = (payload = {}, loggedInUserData) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      if (payload.communicationType === "Email") {
        await client.service('contactusers').create({
          type: "email", // Specify the communication type
          payload
        });
      } else if (payload.communicationType === "SMS") {
        payload.contactValue = payload.contactValue.slice(1);
        await client.service('contactusers').create({
          type: "sms",
          payload
        });
      } else if (payload.communicationType === "WhatsApp") {
        payload.contactValue = payload.contactValue.slice(1);
        await client.service('contactusers').create({
          type: "whatsapp",
          payload
        });
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Message sent successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const submitRegisterForm = (payload = {}, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const user = await client.service('userregistration').create({
        "user": payload
      });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Registered successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch({ type: USER_SET_DATA, payload: user })
      dispatch(push('/login'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Registration failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// create user passing model 
export const submitUserRegisterForm = (payload = {}, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const user = await client.service('user').create({
        "user": payload
      });
      if (type === 'upload') {
        dispatch(getUserList())
      } else {
        dispatch({ type: USER_SET_DATA, payload: user })
        dispatch(push('/users'));
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `New User Created successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/users'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `New User Creation failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
// edit user 
export const editUser = (userId, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('user').patch(userId, payload);
      dispatch(enqueueSnackbar({
        message: 'User information EDITED And Saved.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/users'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit User information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
export const editUserProfile = (userId, payload = {}) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const user = await client.service('user').patch(userId, payload);
      dispatch({ type: SET_PROFILE_DATA, payload: user.userdata });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'User information EDITED And Saved.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      // dispatch(push('/homedeal'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit User information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}


// remove user
export const removeUser = (userId, userList, totalCount) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('user').remove(userId);
      const newList = [...userList];
      const index = userList.findIndex(item => item.id === userId);
      if (index !== -1) { // Check if the item was found
        newList.splice(index, 1);
        const updatedTotalRecords = totalCount - 1; // Update totalRecords
        let listing = { userList: newList, total: updatedTotalRecords }
        dispatch({ type: USER_SET_LIST, payload: listing });
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'User has been deleted successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const removeUserFromPending = (userId, userList) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('user').remove(userId);
      const newList = [...userList];
      const index = userList.findIndex(item => item.id === userId);
      newList.splice(index, 1);
      dispatch({ type: USER_SET_LIST, payload: newList });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'User has been deleted successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push('/pendingrequest'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}
// get user details by userid
export const getUserById = (userId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const user = await client.service(`user`).get(userId, {})
      const data = user.userdata || {};
      const values = {
        userID: data._id,
        parentCompany: data.parentCompany && data.parentCompany,
        roleType: data.atgRole && data.atgRole.roleType[0],
        firstName: data.contact && data.contact.firstName,
        middleName: data.contact && data.contact.middleName,
        lastName: data.contact && data.contact.lastName,
        alias: data.contact.alias ? data.contact.alias : '',
        webURL: data.contact.webURL ? data.contact.webURL : '',
        countryCode: data.contact && data.contact.countryCode,
        mobileNumber: data.contact && data.contact.mobileNumber,
        whatsAppNumber: data.contact && data.contact.whatsAppNumber,
        smsNumber: data.contact && data.contact.smsNumber ? data.contact.smsNumber : '',
        email: data.email,
        password: data.password,
        roleCompany: data.roleCompany,
        roleSystem: data.roleSystem,
        dealCreation: data.dealCreation,
        makeOffer: data.makeOffer,
        acceptOffer: data.acceptOffer,
        rejectOffer: data.rejectOffer,
        secondFactor: data.secondFactor,
        isAdmin: data.isAdmin
      };
      dispatch({ type: USER_SET_DATA, payload: values })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}


export default function reducer(
  state = {
    // User
    isAuthenticated: false,
    loggedInUserData: null,
    userData: null,
    userProfile: null,
    userList: [],
    askOTP: false,
  },
  action
) {
  switch (action.type) {

    case USER_SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload,
      };
    case USER_SET_LIST:
      return {
        ...state,
        userList: action.payload
      };
    case USER_SET_DATA:
      return {
        ...state,
        userData: action.payload
      };
    case SET_PROFILE_DATA:
      return {
        ...state,
        userProfile: action.payload
      }
    case LOGGEDIN_USER_SET_DATA:
      return {
        ...state,
        loggedInUserData: action.payload
      };
    case SET_ASK_LOGIN_OTP:
      return {
        ...state,
        askOTP: action.payload
      };
    default:
      break;
  }
  return state;
}
