import { push } from 'connected-react-router';
import { enqueueSnackbar } from './NotifierReducer';
import { setShowLoader } from './LoaderReducer';
export const SET_NETWORK_CONTACT_DATA = 'SET_NETWORK_CONTACT_DATA';
export const SET_NETWORK_CONTACT_LIST = 'SET_NETWORK_CONTACT_LIST';

// add the network contacts to network. (network contacts creation)
export const submitCreateNetworkContactForm = (networkId, payload = {}, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const newNetworkContactList = await client.service('network-contact').patch(networkId, { "networkContacts": payload });
      if (type === 'upload') {
        dispatch(getNetworkContactListByNetworkId(networkId))
      } else {
        dispatch({ type: SET_NETWORK_CONTACT_DATA, payload: newNetworkContactList });
        dispatch(push(`/networkcontacts?id=${networkId}`));
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Network contact details are added successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Network contact details failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// select network contacts by network Id(networkcontactsGrid).
export const getNetworkContactListByNetworkId = (networkId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getNetworkContactList = await client.service(`network-contact`).get(networkId, {})
      const fetchedNetworkContactList = getNetworkContactList.networks || {};
      let networkContactList = [];
      networkContactList.networkId = fetchedNetworkContactList._id;
      if (fetchedNetworkContactList && fetchedNetworkContactList.networkContacts)
        networkContactList = fetchedNetworkContactList.networkContacts.map(item => {
          const networkContact = {};
          networkContact.id = item._id;
          networkContact.firstName = item.firstName;
          networkContact.middleName = item.middleName;
          networkContact.lastName = item.lastName;
          networkContact.name = `${item.firstName} ${item.middleName} ${item.lastName}`;
          networkContact.emailAddress = item.emailAddress;
          networkContact.countryCode = item.countryCode;
          networkContact.mobileNumber = item.mobileNumber;
          networkContact.whatsAppNumber = item.whatsAppNumber;
          networkContact.smsNumber = item.smsNumber;
          return networkContact;
        })
      networkContactList.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      dispatch({ type: SET_NETWORK_CONTACT_LIST, payload: networkContactList })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// get network contacts details by network and networkContactId.
export const getNetworkContactDetails = (networkId, networkContactId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getNetworkContact = await client.service(`network-contact`).find(
        {
          query: {
            "_id": networkId,
            "networkContactid": networkContactId
          }
        }, {})
      const data = getNetworkContact.networkcontact || {};
      const values = {
        networkContactId: data._id,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        countryCode: data.countryCode,
        mobileNumber: data.mobileNumber,
        whatsAppNumber: data.whatsAppNumber,
        smsNumber: data.smsNumber
      };
      dispatch({ type: SET_NETWORK_CONTACT_DATA, payload: values })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// edit network contacts (update).
export const editNetworkContact = (networkId, networkContactId, payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const networkContactList = await client.service('network-contact').update(networkId, payload,
        {
          query: {
            '_id': networkId,
            'networkContacts._id': networkContactId
          }
        });
      dispatch({ type: SET_NETWORK_CONTACT_DATA, payload: networkContactList });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Network contact information Updated successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push(`/networkcontacts?id=${networkId}`));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit Network information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// remove the network contacts
export const removeNetworkContact = (networkId, networkContactId, networkContactList) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('network-contact').remove(networkId, {
        query: {
          "networkContactid": networkContactId
        }
      });
      const newList = [...networkContactList];
      const index = networkContactList.findIndex(item => item.id === networkContactId);
      if (index !== -1) { // Check if the item was found
        newList.splice(index, 1);
        const updatedTotalRecords = newList.length; // Update totalRecords
        let listing = { networkContactList: newList, total: updatedTotalRecords }
        dispatch({ type: SET_NETWORK_CONTACT_LIST, payload: listing });
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Network contact has been deleted successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export default function reducer(
  state = {
    networkContactList: null,
    networkContactData: null,
  },
  action
) {
  switch (action.type) {
    case SET_NETWORK_CONTACT_LIST:
      return {
        ...state,
        networkContactList: action.payload
      };
    case SET_NETWORK_CONTACT_DATA:
      return {
        ...state,
        networkContactData: action.payload
      };
    default:
      break;
  }
  return state;
}

