import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import useQuery from '../../../utils/useQuery';
import {
    Grid,
    Container,
    Card,
    CardContent,
    Button,
} from '@mui/material';
import {
    AppContent,
    AppContentInner,
    AppWrapper,
    AppMain,
    Header,
    HeaderText,
} from '../../../styles/appStyles';
import {
    FormButtonWrapper
} from '../../../styles/formStyles';
import { getBrandById, getBrandList } from '../../../reducers/BrandReducer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';

const ViewBrand = props => {
    const { restClient: { rest }, brandData, brandList = [], getBrandById, getBrandList } = props;
    const query = useQuery();
    const brandID = query.get('id');

    useEffect(() => {
        if (brandID && rest) {
            const getBrand = async () => {
                getBrandById(brandID);
            }
            getBrand();
        }
    }, [brandID, rest, getBrandById])

    useEffect(() => {
        if (rest) {
            const getList = async () => {
                getBrandList();
            }
            getList();
        }
    }, [rest, getBrandList])
    const brand = brandList.filter((un) => un.id === brandID).map((item) => ({ brandCompany: item.brandCompany[0], brandPeople: item.brandPeople, brandNetwork: item.brandNetwork }))
    return (
        <AppContent>
            <AppContentInner>
                <AppWrapper>
                    <AppMain>
                        <Container maxWidth="xl">
                            <Grid container spacing={5}>
                                <Grid
                                    item
                                    xs={12}
                                    lg={12}>
                                    <Card className='cardView'>
                                        <Header >
                                            <HeaderText >
                                                <h5>{'View Brand Details'}</h5>
                                            </HeaderText>
                                        </Header>
                                        <CardContent >
                                            <form className='formSpacing'>
                                                <Grid container rowSpacing={1}>
                                                    <Grid container xs={12} md={12} lg={12}>
                                                        <TableContainer elevation={0} >
                                                            <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                                                <TableBody>
                                                                    <TableRow key={brandData.brandName} >
                                                                        <TableCell component="th" scope="row" style={{ width: 50 }}>
                                                                            {"Brand Name"}
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 160 }} >
                                                                            {brandData.brandName}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow key={brandData.brandDescription}>
                                                                        <TableCell component="th" scope="row">
                                                                            {"Dominant Role"}
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 160 }}>
                                                                            {brandData.brandDescription}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow key={brandData.brandURL}>
                                                                        <TableCell component="th" scope="row">
                                                                            {"Brand URL"}
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 160 }}>
                                                                            {brandData.brandURL}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow key={brand[0].brandPeople}>
                                                                        <TableCell component="th" scope="row">
                                                                            {"Brand People"}
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 160 }}>
                                                                            {brand[0].brandPeople}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow key={brand[0].brandCompany}>
                                                                        <TableCell component="th" scope="row">
                                                                            {"Brand Company"}
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 160 }}>
                                                                            {brand[0].brandCompany}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                    <TableRow key={brand[0].brandNetwork}>
                                                                        <TableCell component="th" scope="row">
                                                                            {"Brand Network"}
                                                                        </TableCell>
                                                                        <TableCell style={{ width: 160 }}>
                                                                            {brand[0].brandNetwork}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                                <TableFooter>
                                                                </TableFooter>
                                                            </Table>
                                                        </TableContainer>
                                                    </Grid>
                                                </Grid>
                                                &nbsp;&nbsp;&nbsp;
                                                <FormButtonWrapper>
                                                    <Link to="/brand" className='linkStyle'>
                                                        <Button color='primary' variant='contained' type='submit' data-test-id="View-Company-GoBack" text-decoration='none'>
                                                            Go Back
                                                        </Button>
                                                    </Link>
                                                </FormButtonWrapper>
                                            </form>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>
                        </Container>
                    </AppMain>
                </AppWrapper>
            </AppContentInner>
        </AppContent>
    );
};
const mapStateToProps = state => ({
    restClient: state.restClient,
    brandData: state.brand.brandData,
    brandList: state.brand && state.brand.brandList.brandList
})

export default connect(mapStateToProps, { getBrandById, getBrandList })(ViewBrand);
