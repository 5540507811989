export const initialFormValues = {
  brandName: '',
  brandDescription: '',
  brandURL: '',
  brandCompany: {},
  brandPeople: [],
  brandNetwork: [],
};

export const schema = {
  brandURL: {
    IsValidWebURL: {}
  },
  brandCompany: {
    presence: { allowEmpty: false, message: 'Brand Company is required' }
  },
  brandName: {
    presence: { allowEmpty: false, message: 'Brand Name is required' }
  }

};
export const getPayload = (values) => {
  const payload = {
    brandCompany: values.brandCompany,
    brandPeople: values.brandPeople,
    brandNetwork: values.brandNetwork,
    "dealBrand": {
      brandName: values.brandName,
      brandDescription: values.brandDescription,
      brandURL: values.brandURL,
    },
  }
  return payload;
}