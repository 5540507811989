import { push } from 'connected-react-router';
import { enqueueSnackbar } from './NotifierReducer';
import { setShowLoader } from './LoaderReducer';
export const SET_COMPANY_BRANCH_DATA = 'SET_COMPANY_BRANCH_DATA';
export const SET_COMPANY_BRANCH_LIST = 'SET_COMPANY_BRANCH_LIST';

// add the company branch to company. (company branch creation)
export const submitCreateCompanyBranchForm = (companyId, payload = {}, type) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const newCompanyBranchList = await client.service('company-branch').patch(companyId, { "branch": payload });
      if (type === 'upload') {
      } else {
        dispatch({ type: SET_COMPANY_BRANCH_DATA, payload: newCompanyBranchList });
        dispatch(push(`/companybranches?id=${companyId}`));
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Company Branch are added successfully.`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      //dispatch(push(`/companybranches?id=${companyId}`));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Company Branch is failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}


// select company branch by company Id(companyBranchGrid).
export const getCompanyBranchListByCompanyId = (companyId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getCompanyBranchList = await client.service(`company-branch`).get(companyId, {})
      const fetchedCompanyBranchList = getCompanyBranchList.branches || {};
      let companyBranchList = [];
      companyBranchList.companyId = fetchedCompanyBranchList._id;
      if (fetchedCompanyBranchList && fetchedCompanyBranchList.branch)
        companyBranchList = fetchedCompanyBranchList.branch.map(item => {
          const companybranch = {};
          companybranch.id = item._id;
          companybranch.branchID = item.branchID;
          companybranch.branchName = item.branchName;
          companybranch.region = item.region;
          return companybranch;
        })
      companyBranchList.sort(function (a, b) {
        if (a.branchName < b.branchName) {
          return -1;
        }
        if (a.branchName > b.branchName) {
          return 1;
        }
        return 0;
      });
      dispatch({ type: SET_COMPANY_BRANCH_LIST, payload: companyBranchList })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export const getCompanyBranchDetails = (companyId, companyBranchId) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const getCompanyBranch = await client.service(`company-branch`).find(
        {
          query: {
            "_id": companyId,
            "branchid": companyBranchId
          }
        }, {})
      const data = getCompanyBranch.companybranch || {};
      const values = {
        companyBranchId: data._id,
        branchID: data.branchID,
        branchName: data.branchName,
        region: data.region,
      };
      dispatch({ type: SET_COMPANY_BRANCH_DATA, payload: values })
      dispatch(setShowLoader(false));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}


// edit network contacts (update).
export const editCompanyBranch = (companyId, branchId, payload) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      const branchList = await client.service('company-branch').update(companyId, payload,
        {
          query: {
            '_id': companyId,
            'branch._id': branchId
          }
        });
      dispatch({ type: SET_COMPANY_BRANCH_DATA, payload: branchList });
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Company Branch information Updated successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      dispatch(push(`/companybranches?id=${companyId}`));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: `Edit Branch information failed. ${error.message}`,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

// remove the Remove Branch
export const removeCompanyBranch = (companyId, companyBranchId, companyBranchList) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setShowLoader(true));
      const client = getState().restClient.rest;
      await client.service('company-branch').remove(companyId, {
        query: {
          "branchid": companyBranchId
        }
      });
      const newList = [...companyBranchList];
      const index = companyBranchList.findIndex(item => item.id === companyBranchId);
      if (index !== -1) { // Check if the item was found
        newList.splice(index, 1);
        const updatedTotalRecords = newList.length; // Update totalRecords
        let listing = { companyBranchList: newList, total: updatedTotalRecords }
        dispatch({ type: SET_COMPANY_BRANCH_LIST, payload: listing });
      }
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: 'Branch has been deleted successfully.',
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
      //dispatch(push('/network/contact'));
    } catch (error) {
      dispatch(setShowLoader(false));
      dispatch(enqueueSnackbar({
        message: error.message,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'warning',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          }
        },
      }));
    }
  }
}

export default function reducer(
  state = {
    companyBranchList: null,
    companyBranchData: null,
  },
  action
) {
  switch (action.type) {
    case SET_COMPANY_BRANCH_LIST:
      return {
        ...state,
        companyBranchList: action.payload
      };
    case SET_COMPANY_BRANCH_DATA:
      return {
        ...state,
        companyBranchData: action.payload
      };
    default:
      break;
  }
  return state;
}