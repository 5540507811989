export const initialFormValues = {
    companyName: '',
    webURL: '',
    companyEmailAddress: '',
    city: '',
    state: '',
    zipcode: '',
    country: '',
    phoneNumber: '',
    firstLine: '',
    industry: '',
    stockSymbol: '',
    searchLinkedinCompany: '',
    linkedinCompanyPageUrl: '',
    deptOrTitle: '',
    firstName: '',
    emailAddress: '',
    contactLinkedin: '',
    annualRevenue: '',
    numberOfEmployees: '',
    description: '',
    companyOwner: '',
    facebook: '',
    twitter: '',
    instagram: '',
    linkedin: '',
    youtube: '',

};
export const schema = {
    companyName: {
        presence: { allowEmpty: false, message: 'Company Name is required' },
    },
};
export const getPayload = (values) => {
    const payload = {
        companyName: values.companyName,
        webURL: values.webURL,
        companyEmailAddress: values.companyEmailAddress,
        city: values.city,
        state: values.state,
        zipcode: values.zipcode,
        country: values.country,
        phoneNumber: values.phoneNumber,
        firstLine: values.firstLine,
        industry: values.industry,
        stockSymbol: values.stockSymbol,
        searchLinkedinCompany: values.searchLinkedinCompany,
        linkedinCompanyPageUrl: values.linkedinCompanyPageUrl,
        deptOrTitle: values.deptOrTitle,
        firstName: values.firstName,
        emailAddress: values.emailAddress,
        contactLinkedin: values.contactLinkedin,
        annualRevenue: values.annualRevenue,
        numberOfEmployees: values.numberOfEmployees,
        description: values.description,
        companyOwner: values.companyOwner,
        facebook: values.facebook,
        twitter: values.twitter,
        instagram: values.instagram,
        linkedin: values.linkedin,
        youtube: values.youtube,
    }
    return payload;
}