import React, { useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { submitCreateNetworkContactForm, getNetworkContactListByNetworkId, removeNetworkContact } from '../../../reducers/NetworkContactReducer';
import { getNetworkById } from '../../../reducers/NetworkReducer';
import useQuery from '../../../utils/useQuery';
import { getUploadPayload } from './utils';
import { DataGrid, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter } from '@mui/x-data-grid';
import { Card, Typography, IconButton, Grid, Box, Tooltip, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import { ThemeProvider, createTheme } from '@mui/material';

const theme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        columnHeaders: {
          backgroundColor: '#3E5272',
          color: 'white',
        },
        columnSeparator: {
          visibility: "hidden",
        },
        sortIcon: {
          color: "#ee9907",
        }
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: '100%',
    margin: '20px'
  },
  tableTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '0 1.5rem'
  },
  actions: {
    color: "#3E5272"
  },
  header: {
    maxWidth: "730",
    marginLeft: "-5rem"
  },
  childDiv: {
    paddingTop: "10px"
  },
  tableheader: {
    marginTop: "-31px"
  },
  cardStyle: {
    height: "420px", 
    width: "1314px",
    marginLeft: "-5rem", 
    marginBottom: "-22px"
  },
  textInput: {
    paddingLeft: "10px"
  }
}));

const NetworkContactsList = props => {
  const classes = useStyles();
  const { networkContactList = [], restClient: { rest }, getNetworkContactListByNetworkId, networkData, getNetworkById } = props;
  const query = useQuery();
  const action = query.get('action');
  const networkId = query.get('id');
  const [file, setFile] = useState();
  const ref = useRef(null);
  const [disabled, setDisabled] = useState(true);

  const history = useHistory();

  //Get the data from networkcontactreducer
  useEffect(() => {
    if (networkId && rest) {
      const getContactsList = async () => {
        if (rest) {
          await getNetworkContactListByNetworkId(networkId);
          await getNetworkById(networkId);
        }
      };
      getContactsList();
    }
  }, [action, networkId, getNetworkById, getNetworkContactListByNetworkId, rest]);

  useEffect(() => {
    if (networkId && rest) {
      const getNetwork = async () => {
        getNetworkById(networkId);
      }
      getNetwork();
    }
  }, [networkId, getNetworkById, rest])

  function handleChange(event) {
    setFile(event.target.files[0]);
    setDisabled(false);
  }

  // edit network contacts.
  const handleNetworkContactEdit = (event, networkContact) => {
    history.push(`/networkcreatecontacts?action=edit&id=${networkId}&contactId=${networkContact.id}`);
  }
  // delete network contacts.
  const handleNetworkContactDelete = (event, networkContact) => {
    props.removeNetworkContact(networkId, networkContact.id, networkContactList);
  }

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 120
    },
    {
      field: 'middleName',
      headerName: 'Middle Name',
      type: 'string',
      width: 120
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      type: 'string',
      width: 130
    },
    {
      field: 'emailAddress',
      headerName: 'Email Address',
      width: 200
    },
    {
      field: 'countryCode',
      headerName: 'Country Code',
      width: 130
    },
    {
      field: 'mobileNumber',
      headerName: 'Mobile Number',
      width: 150
    },
    {
      field: 'whatsAppNumber',
      headerName: 'whatsApp Number',
      width: 150
    },
    {
      field: 'smsNumber',
      headerName: 'SMS Number',
      width: 150
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      width: 100,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit Company">
              <IconButton sx={{ color: "#3E5272" }} onClick={(event) => {
                handleNetworkContactEdit(event, params.row);
              }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Delete Company'>
              <IconButton sx={{ color: "#3E5272" }} onClick={(event) => {
                window.confirm("Are you sure want to delete it?") && handleNetworkContactDelete(event, params.row);
              }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    }
  ]

  // validation for upload network contacts
  const ImportExport = event => {
    const regex = /^.*\.(xlsx|xls)$/;
    if (regex.test(file.name.toLowerCase())) {
      if (typeof (FileReader) !== 'undefined') {
        const reader = new FileReader();
        if (reader.readAsBinaryString) {
          reader.onload = (e) => {
            const payload = getUploadPayload(reader.result);
            props.submitCreateNetworkContactForm(networkId, payload, 'upload'); // send the model data to submitNetworkContactRegisterForm.
          };
          reader.readAsBinaryString(file);
        }
      } else {
        console.log("This browser does not support HTML5.");
      }
    } else {
      console.log("Please upload a valid Excel file.");
    }
    const timer = setTimeout(() => {
      ref.current.form.reset();
      setDisabled(true);
    }, 2000);
    return () => clearTimeout(timer);
  };

  function QuickSearchToolbar() {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
        }}
      >
        <GridToolbarQuickFilter />
      </Box>
    );
  }

  function DataGridTitle() {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              NETWORK CONTACTS
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h5"
              sx={{
                margin: "6px",
                fontWeight: "bolder",
                fontSize: "1.2rem",
                color: "#696969",
                lineHeight: 1.6,
              }}>
              {networkData && networkData.networkName}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container spacing={0}>
            <Grid item lg={3} xs={12}>
                {/* <div className={classes.childDiv}>
                  <GridToolbarContainer>
                    <GridToolbarExport />
                  </GridToolbarContainer>
                </div> */}
              </Grid>
              <Grid item lg={6} xs={6}>
                <div className={classes.search}>
                  {/* <GridToolbarContainer>
                    <QuickSearchToolbar />
                  </GridToolbarContainer> */}
                </div>
              </Grid>
              <Grid item lg={3} xs={12}>
                <div className={classes.childDiv}>
                  <Tooltip title="Create New NetworkContact">
                    <Link to={'/networkcreatecontacts?action=create&id=' + networkId} >
                      <IconButton sx={{ color: "#3E5272" }}>
                        <AddBoxIcon />
                      </IconButton>
                    </Link>
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <div>
      <div className={classes.tableheader}>
      <h3 className={classes.header}>
        <form>
          Upload Network Contacts Excel File (.xlsx) :
          <input type="file" onChange={handleChange} ref={ref} className={classes.textInput} />
          <Button component="label" variant="contained" endIcon={<UploadFileOutlinedIcon />} onClick={ImportExport} disabled={disabled}>
            Upload file
          </Button>
        </form>
      </h3>
      </div>
      <Card className={classes.cardStyle}>
        <ThemeProvider theme={theme}>
          <DataGrid
            rows={networkContactList || []}
            columns={columns}
            pageSize={100}
            rowsPerPageOptions={[5]}
            checkboxSelection
            disableSelectionOnClick
            sx={{
              '& .css-ptiqhd-MuiSvgIcon-root': {
                color: '#ee9907'
              },
              // "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
              //   display: 'none'
              // }
            }
            }
            // density='standard'
            components={{ Toolbar: DataGridTitle }}
          />
        </ThemeProvider>
      </Card>
    </div>
  );
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  networkContactList: state.networkContact && state.networkContact.networkContactList,
  networkData: state.network.networkData,
});

export default connect(mapStateToProps, { submitCreateNetworkContactForm, getNetworkContactListByNetworkId, removeNetworkContact, getNetworkById })(NetworkContactsList);

