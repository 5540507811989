export const initialFormValues = {
  networkName: '',
  parentCompany: '',
  parentPeople: '',
  networkContacts: []
};

export const schema = {
  networkName: {
    presence: { allowEmpty: false, message: 'Network name is required' },
    length: {
      maximum: 32
    }
  },
  parentCompany: {
    presence: { allowEmpty: false, message: 'Parent company is required' },
    length: {
      maximum: 32
    }
  },
  parentPeople: {
    presence: { allowEmpty: false, message: 'Parent people is required' },
    length: {
      maximum: 32
    }
  }
};

export const getPayload = (values) => {
  const payload = {
    networkName: values.networkName,
    parentCompany: values.parentCompany,
    parentPeople: values.parentPeople
    //networkContacts : values.networkContacts
  }
  return payload;
}
