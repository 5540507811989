import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    Button,
    Grid,
    useMediaQuery,
    DialogTitle,
    Dialog,
    DialogActions,
    DialogContent,
}
    from '@mui/material';
import TabsContainer from "../DealChat/Card/index";
import { useLocation } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SubscriptionCard = ({ title, price, features, planDetails }) => {
    const [open, setOpen] = React.useState(false);

    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Card sx={{ minWidth: 275, textAlign: 'center', borderRadius: '16px', position: 'relative', backgroundColor: (planDetails === title) && 'powderblue' }}>
            <CardContent>
                {/* Conditionally render the tick mark if planDetails is present */}
                {(planDetails === title) && (
                    <CheckCircleIcon
                        sx={{
                            position: 'absolute',
                            top: 16,
                            right: 16,
                            color: 'green',
                            fontSize: 30
                        }}
                    />
                )}

                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                    {title}
                </Typography>
                <Typography variant="h4" color="text.secondary">
                    ${price}
                </Typography>

                <ul style={{ marginRight: '50px' }}>
                    {features.map((feature, index) => (
                        <li key={index} style={{ listStyleType: 'none' }}>{feature}</li>
                    ))}
                </ul>

                {/* Conditionally render the button only if planDetails is not present */}
                <Button variant="contained" sx={planDetails !== title ? { mt: 2 } : { mt: 2, visibility: 'hidden' }} onClick={handleClickOpen}>
                    Choose {title}
                </Button>

                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>Choose Any Payment Method</DialogTitle>
                    <DialogContent sx={isSmallScreen ? { padding: 0, margin: 0 } : { padding: 0, margin: '20px' }}>
                        <div>
                            <TabsContainer setOpen={setOpen} price={price} />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            </CardContent>
        </Card>
    );
};

const SubscriptionPlans = () => {
    // Use the useLocation hook to get the current location (URL)
    const location = useLocation();

    // Create a URLSearchParams instance with the query string from the location
    const queryParams = new URLSearchParams(location.search);

    // Get the 'plan' parameter from the URL
    const planDetails = queryParams.get('plan');

    const plans = [
        {
            title: 'BASIC',
            price: '10',
            features: ['5 users', '100 Deals'],
        },
        {
            title: 'PROFESSIONAL',
            price: '100',
            features: ['25 users', '500 Deals'],
        },
        {
            title: 'ENTERPRISE',
            price: '1000',
            features: ['Unlimited Users', 'Unlimited Deals'],
        },
    ];

    return (
        <Grid container spacing={3} justifyContent="center" padding='15px'>
            <Grid item xs={12}>
                <h1>Choose Plans</h1>
            </Grid>
            {plans.map((plan, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                    <SubscriptionCard title={plan.title} price={plan.price} features={plan.features} planDetails={planDetails} />
                </Grid>
            ))}
        </Grid>
    );
};

export default SubscriptionPlans;