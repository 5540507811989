import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import useQuery from "../../../utils/useQuery";
import {
  Grid,
  Container,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import {
  AppContent,
  AppContentInner,
  AppWrapper,
  AppMain,
  Header,
  HeaderText,
} from "../../../styles/appStyles";
import {
  FormButtonWrapper,
} from "../../../styles/formStyles";
import { getCompanyById } from "../../../reducers/CompanyReducer";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TableRow from '@mui/material/TableRow';


const ViewCompany = (props) => {
  const {
    restClient: { rest },
    companyData,
    getCompanyById,
  } = props;
  const query = useQuery();
  const companyId = query.get("id");

  useEffect(() => {
    if (companyId && rest) {
      const getCompany = async () => {
        getCompanyById(companyId);
      };
      getCompany();
    }
  }, [companyId, getCompanyById, rest]);

  return (
    <AppContent>
      <AppContentInner>
        <AppWrapper>
          <AppMain>
            <Container maxWidth="xl">
              <Grid container spacing={5}>
                <Grid item xs={12} lg={12}>
                  <Card className="cardView">
                    <Header>
                      <HeaderText>
                        <h5>{"View Company Details"}</h5>
                      </HeaderText>
                    </Header>
                    <CardContent>
                      <form className="formSpacing">
                        <Grid container rowSpacing={1}>
                          <Grid container xs={12} md={12} lg={12}>
                            <TableContainer elevation={0} >
                              <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
                                <TableBody>
                                  <TableRow key={companyData.companyName} >
                                    <TableCell component="th" scope="row" style={{ width: 50 }}>
                                      {"Company Name"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }} >
                                      {companyData.companyName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.dominantRole}>
                                    <TableCell component="th" scope="row">
                                      {"Dominant Role"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.dominantRole}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.description}>
                                    <TableCell component="th" scope="row">
                                      {"Description"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.description}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.companyEmailAddress}>
                                    <TableCell component="th" scope="row">
                                      {"Company Email Address"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.companyEmailAddress}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.webURL}>
                                    <TableCell component="th" scope="row">
                                      {"Company WebURL"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.webURL}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.industry}>
                                    <TableCell component="th" scope="row">
                                      {"Industry"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.industry}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.deptOrTitle}>
                                    <TableCell component="th" scope="row">
                                      {"Dept Or Title"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.deptOrTitle}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.annualRevenue}>
                                    <TableCell component="th" scope="row">
                                      {"AnnualRevenue"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.annualRevenue}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.taxId}>
                                    <TableCell component="th" scope="row">
                                      {"TaxId"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.taxId}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.branchName}>
                                    <TableCell component="th" scope="row">
                                      {"Branch Name"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.branchName}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.branchID}>
                                    <TableCell component="th" scope="row">
                                      {"branchID"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.branchID}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.contactLinkedin}>
                                    <TableCell component="th" scope="row">
                                      {"Contact Linkedin"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.contactLinkedin}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.firstLine}>
                                    <TableCell component="th" scope="row">
                                      {"Company Address1"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.firstLine}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.secondLine}>
                                    <TableCell component="th" scope="row">
                                      {"Company Address2"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.secondLine}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.country}>
                                    <TableCell component="th" scope="row">
                                      {"Country"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.country}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.state}>
                                    <TableCell component="th" scope="row">
                                      {"State"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.state}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.city}>
                                    <TableCell component="th" scope="row">
                                      {"City"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.city}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.zipcode}>
                                    <TableCell component="th" scope="row">
                                      {"Zip Code"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.zipcode}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.webURL}>
                                    <TableCell component="th" scope="row">
                                      {"Company Website"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.webURL}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.taxId}>
                                    <TableCell component="th" scope="row">
                                      {"Tax Id"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.taxId}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.phoneNumber}>
                                    <TableCell component="th" scope="row">
                                      {"Phone Number"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.phoneNumber}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.lat}>
                                    <TableCell component="th" scope="row">
                                      {"Location Latitude"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.lat}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.long}>
                                    <TableCell component="th" scope="row">
                                      {"Location Longitude"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.long}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.emailAddress}>
                                    <TableCell component="th" scope="row">
                                      {"MediaLink"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.emailAddress}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.region}>
                                    <TableCell component="th" scope="row">
                                      {"Region"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.region}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.phoneNumber}>
                                    <TableCell component="th" scope="row">
                                      {"Phone Number"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.phoneNumber}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.facebook}>
                                    <TableCell component="th" scope="row">
                                      {"Facebook"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.facebook}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.twitter}>
                                    <TableCell component="th" scope="row">
                                      {"Twitter"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.twitter}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.instagram}>
                                    <TableCell component="th" scope="row">
                                      {"Instagram"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.instagram}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.linkedin}>
                                    <TableCell component="th" scope="row">
                                      {"Linkedin"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.linkedin}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.youtube}>
                                    <TableCell component="th" scope="row">
                                      {"Youtube"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.youtube}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.searchLinkedinCompany}>
                                    <TableCell component="th" scope="row">
                                      {"SearchLinkedinCompany"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.searchLinkedinCompany}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.linkedinCompanyPageUrl}>
                                    <TableCell component="th" scope="row">
                                      {"LinkedinCompanyPageUrl"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.linkedinCompanyPageUrl}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.stockSymbol}>
                                    <TableCell component="th" scope="row">
                                      {"StockSymbol"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.stockSymbol}
                                    </TableCell>
                                  </TableRow>
                                  <TableRow key={companyData.numberOfEmployees}>
                                    <TableCell component="th" scope="row">
                                      {"NumberOfEmployees"}
                                    </TableCell>
                                    <TableCell style={{ width: 160 }}>
                                      {companyData.numberOfEmployees}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                                <TableFooter>
                                </TableFooter>
                              </Table>
                            </TableContainer>
                          </Grid>
                        </Grid>
                        &nbsp;&nbsp;&nbsp;
                        <FormButtonWrapper>
                          <Link to="/company" className="linkStyle">
                            <Button
                              color="primary"
                              variant="contained"
                              type="submit"
                              data-test-id="View-Company-GoBack"
                              text-decoration="none"
                            >
                              Go Back
                            </Button>
                          </Link>
                        </FormButtonWrapper>
                      </form>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Container>
          </AppMain>
        </AppWrapper>
      </AppContentInner>
    </AppContent>
  );
};

const mapStateToProps = (state) => ({
  restClient: state.restClient,
  companyData: state.company.companyData,
});

export default connect(mapStateToProps, { getCompanyById })(ViewCompany);
