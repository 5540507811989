export const initialFormValues = {
    branchID: '',
    branchName: '',
    region: '',
};

export const schema = {
    branchID: {
        presence: { allowEmpty: false, message: 'BranchID is required' }
    },
    branchName: {
        presence: { allowEmpty: false, message: 'BranchName is required' }
    },
    region: {
        presence: { allowEmpty: false, message: 'Region is required' }
    }
};

export const getPayload = (values) => {
    const payload = {
        branchID: values.branchID,
        branchName: values.branchName,
        region: values.region,
    }
    return payload;
}

export const getEditPayload = (values) => {
    const payload = {
        'branch.$.branchID': values.branchID,
        'branch.$.branchName': values.branchName,
        'branch.$.region': values.region
    }
    return payload;
}