import React, { useState, useEffect, useRef } from "react";
import { Visibility, VisibilityOff, AttachMoney as AttachMoneyIcon } from '@mui/icons-material';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
// import useQuery from "../../utils/useQuery";
import { getDealMessageList } from '../../reducers/DealCommunicationReducer';
import { submitTransactionRegisterForm } from "../../reducers/TransactionReducer";
import { submitMakeOfferMessage, submitMakeOfferMessageWithoutTransactionId } from "../../reducers/DealCommunicationReducer";
import { DataGrid } from "@mui/x-data-grid";
import {
    Card,
    Grid,
    Checkbox,
    Button,
    TextField,
    Box,
    Typography,
    InputAdornment,
    IconButton,
    Tooltip,
    Chip,
    Paper,
    ImageList,
    ImageListItem
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider, createTheme, useMediaQuery } from '@mui/material';
import { getTransactionList } from "../../reducers/TransactionReducer";
import { getDealById } from "../../reducers/DealReducer";
import useQuery from '../../utils/useQuery';
import equipmentImage from '../NewDealGrid/heavy-machinery-used-construction-industry-engineering_23-2151307723.avif';
import equipment2 from '../NewDealGrid/equipment2.jpg';
import equipment3 from '../NewDealGrid/equipment3.jpg';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // Import the carousel CSS
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import { DealMessageChat } from "../../DealChat/index";
// import DealSome from "../DealListGrid/dealCloseOut";
import noImage from './Image_not_available.png';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const theme = createTheme({
    components: {
        MuiDataGrid: {
            styleOverrides: {
                columnHeaders: {
                    backgroundColor: '#3E5272',
                    color: 'white',
                },
                columnSeparator: {
                    visibility: "hidden",
                },
                sortIcon: {
                    color: "#ee9907",
                }
            },
        },
    },
});

const useStyles = makeStyles((theme) => ({
    makeOfferContainer: {
        paddingRight: "5rem",
        display: "flex",
        justifyContent: "flex-end",
        gap: 10,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            justifyContent: "center",
        },
    },
    makeOffer: {
        color: "rgb(204, 102, 0)",
        fontSize: "large",
        fontWeight: 700,
        [theme.breakpoints.down("sm")]: {
            fontSize: "medium",
        },
    },

    makeOfferFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: 10,
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            justifyContent: "center",
        },
    },

    gridChild: {
        display: "flex",
        alignItems: "center",
        gap: 10,
    },
    icon: {
        color: "rgb(204, 102, 0)",
        alignItems: "center",
    },
    parent: {
        display: "grid",
        gridTemplateColumns: "7rem 2rem 7rem",
        paddingLeft: "2px",
        marginTop: "-2px",
    },
    input: {
        padding: "7px 0px 8px 0px",
        textAlign: "center",
        borderRadius: 3,
        border: "1px solid #555",
    },
    topGrid: {
        paddingTop: "20px",
    },
    makeSeller: {
        paddingLeft: "90px",
        color: "rgb(204, 102, 0)",
        fontSize: "large",
        fontWeight: 700,
    },
    salConst: {
        paddingLeft: "3px",
        paddingBottom: "20px",
        // color: "#696969",
        fontSize: "X-large",
        fontWeight: "bolder",
    },
    salConsttwo: {
        paddingLeft: "3px",
        paddingBottom: "20px",
        // color: "#696969",
        fontSize: "large",
        fontWeight: "bolder",
        marginTop: "-2px",
        display: "flex"
    },
    bottonGrid: {
        paddingLeft: "90px",
        marginTop: "-78px",
    },
    link: {
        textDecoration: "none",
    },
    mediaLink: {
        textDecoration: "none",
        color: "black",
        "&:hover": {
            backgroundColor: "rgb(180, 180, 180)",
        },
    },
    button: {
        textAlign: "right",
        paddingBottom: "10px",
        paddingTop: "10px",
    },
    btnwidth: {
        width: "134%",
        paddingTop: "10px",
    },
    dataGrid: {
        // paddingBottom: "1px",
    },
    numberField: {
        "& input[type=number]": {
            "-moz-appearance": "textfield",
        },
        "& input[type=number]::-webkit-outer-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
        "& input[type=number]::-webkit-inner-spin-button": {
            "-webkit-appearance": "none",
            margin: 0,
        },
    },
    MakeButton: {
        color: "white",
        backgroundColor: "rgb(62, 82, 114)",
        "&:hover": {
            backgroundColor: "#fbbc05",
            //   color: "black"
        },
    },
    childDiv: {
        paddingTop: "10px",
        paddingLeft: "50px",
    },
    dealEquip: {
        paddingLeft: "10px",
    },
    hidePagination: {
        '& .MuiDataGrid-footerContainer': {
            display: 'none',
        },
    },
    totalPrice: {
        fontSize: "medium",
        fontWeight: "800",
        color: "rgb(120, 120, 120)",
    }
}));
const SerialNumberCell = ({ params }) => {
    const [showSerialNumbers, setShowSerialNumbers] = useState(false);

    const toggleSerialNumberVisibility = () => {
        setShowSerialNumbers(!showSerialNumbers);
    };
    return (
        <>
            {params.value ?
                <div>
                    {showSerialNumbers ? params.value : "••••••••••••••••"}
                    <IconButton
                        aria-label="toggle serial number visibility"
                        onClick={toggleSerialNumberVisibility}
                        edge="end"
                    >
                        {!showSerialNumbers ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                </div> : ""}
        </>
    );
};

const DataGridDemo = (props) => {
    const {
        dealData,
        loggedInUserData,
        restClient: { rest },
        getTransactionList,
        transactionList = [],
        getDealById,
        dealCommunicationList = [],
        getDealMessageList,
    } = props;
    const classes = useStyles();
    const query = useQuery();
    const dealID = query.get('dealId');
    // const equipmentId = query.get('equipmentId');
    const [data, setData] = useState();
    const [rows, setRows] = React.useState([]);
    const [dealTransactionList, setDealTransactionList] = useState([]);
    const [dealMessages, setDealMessages] = useState([]);
    const contentRef = useRef(null);
    const cardRef = useRef(null);

    useEffect(() => {
        const getMessageList = async () => {
            try {
                if (dealID && rest) {
                    await getDealMessageList(dealID);
                }
            } catch (e) {
                console.log(e);
            }
        };
        getMessageList();
    }, [rest, getDealMessageList, dealID]);

    useEffect(() => {
        const getTransactionListByDealId = async () => {
            try {
                if (dealID && rest) {
                    await getTransactionList(dealID); // Fetch the transaction list
                }
            } catch (e) {
                console.log(e);
            }
        };
        getTransactionListByDealId();
    }, [rest, getTransactionList, dealID]);

    useEffect(() => {
        if (dealID && rest) {
            const getDeal = async () => {
                getDealById(dealID);
            }
            getDeal();
        }
    }, [dealID, rest, getDealById])

    useEffect(() => {
        if (Array.isArray(dealData.dealEquipment)) {
            // const equipment = dealData.dealEquipment.filter((equip) => equip._id == equipmentId);
            // const equipmentImages = equipment[0]?.pictures;
            // setImages(equipmentImages);
            setRows(dealData.dealEquipment);
        }
    }, [dealData.dealEquipment]);

    useEffect(() => {
        if (dealCommunicationList.length > 0) {
            // const equipment = dealData.dealEquipment.filter((equip) => equip._id == equipmentId);
            // const equipmentImages = equipment[0]?.pictures;
            // setImages(equipmentImages);
            const recentMakeOffer = dealCommunicationList.filter((communication) => (communication.notificationType !== 'General Message'));
            setDealMessages(recentMakeOffer);
        }
    }, [dealCommunicationList]);

    const val = dealData?.dealParticipants?.filter((p) => p.participant._id !== loggedInUserData.user._id);
    const [first, setFirst] = useState([]);
    function filterTransactions(transactionListForMakeOffer, dealData, loggedInUserData) {
        return (transactionListForMakeOffer || []).filter(transaction =>
            transaction.userGroup.includes(dealData.seller) &&
            transaction.userGroup.includes(loggedInUserData.user._id) &&
            transaction.userGroup.length === 2
        );
    }
    const getRowData = () => {
        const ifSellerAcceptedDeal = dealData?.dealParticipants.filter((participant) => participant.participant._id === dealData.seller);
        if (ifSellerAcceptedDeal.length) {
            // const ifTransactionIdExistsWithSeller = filterTransactions(transactionListForMakeOffer, dealData, loggedInUserData);//Previous ONE
            const ifTransactionIdExistsWithSeller = filterTransactions(dealTransactionList, dealData, loggedInUserData);
            if (ifTransactionIdExistsWithSeller.length > 0) {
                const transactioncommunication = {
                    transactioncommunication: {
                        dealId: dealID,
                        message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} Made an offer: $${data}`,
                        transactionId: ifTransactionIdExistsWithSeller[0]?.id,
                        transactionEquipment: first,
                        from: loggedInUserData.user._id,
                        to: dealData?.seller,
                        notificationType: "Make Offer",
                        notes: "notes",
                        askPrice: "",
                        offerPrice: data,
                        priceUnit: "USD",
                        addedBy: loggedInUserData.user._id,
                        companyId: loggedInUserData.user.parentCompany._id || loggedInUserData.user.parentCompany,
                    },
                    dealname: dealData.dealName,
                    dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.makeOffer === true).map((p) => { return p.participant.email }),
                }
                const message = true;
                props.submitMakeOfferMessage(transactioncommunication, message);
            } else {
                const transaction = {
                    dealId: dealID,
                    equipment: first,
                    selectionName: "selectionName",
                    price: data,
                    addedBy: loggedInUserData.user._id,
                    userGroup: [loggedInUserData.user._id, dealData?.seller],
                    transactionType: "Individual"
                };
                const transactioncommunication = {
                    transactioncommunication: {
                        dealId: dealID,
                        message: `${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName} Made an offer: $${data}`,
                        transactionEquipment: first,
                        from: loggedInUserData.user._id,
                        to: dealData?.seller,
                        notificationType: "Make Offer",
                        notes: "notes",
                        askPrice: "",
                        offerPrice: data,
                        priceUnit: "USD",
                        addedBy: loggedInUserData.user._id,
                        companyId: loggedInUserData.user.parentCompany._id || loggedInUserData.user.parentCompany,
                    },
                    dealname: dealData.dealName,
                    dealparticipantemails: dealData.dealParticipants.filter((un) => un.participant.makeOffer === true).map((p) => { return p.participant.email }),
                }
                const dealId = props?.dealCommunicationList[0]?.dealId;
                const payload = { transaction };
                const message = true;
                props.submitMakeOfferMessageWithoutTransactionId(dealID, { "UnreadMessages": dealId?.UnreadMessages }, payload, transactioncommunication, message);
            }
        } else {
            alert("Seller did not accept the deal.Please ask him to accept.If issue persists, make a REFRESH of the page.")
        }
    };

    function generateRandom() {
        var length = 8,
            charset =
                "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    const handleTextFieldChange = (event, rowId) => {
        if (rowId) {
            let val = 0;
            const elems = document.getElementsByName("num1");
            elems.forEach(function (valueCheck) {
                if (valueCheck.value !== "") val = val + parseFloat(valueCheck.value);
            });
            setData(val);
            setFirst([...first, rowId]);
        }
    };

    function formatCurrency(value) {
        if (value) {
            return `$${value}`;
        } else {
            return null;
        }
    }

    const reserveValue = loggedInUserData?.user?._id === dealData?.seller;

    const isSeller = loggedInUserData?.user?._id === dealData?.seller;
    const isBuyer = loggedInUserData?.user?._id === dealData?.buyer;
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const theme = createTheme({
        components: {
            MuiDataGrid: {
                styleOverrides: {
                    root: {
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: isSeller ? "#2ecd71" : "#1a75c3",
                            color: "white",
                        },
                    },
                    columnSeparator: {
                        visibility: "hidden",
                    },
                },
            },
        },
    });

    useEffect(() => {
        if (transactionList?.length > 0) {
            setDealTransactionList(transactionList);
        }
    }, [transactionList]);

    const ImageCarousel = () => {
        const [currentIndex, setCurrentIndex] = useState(0);

        const handleSlideChange = (index) => {
            setCurrentIndex(index);
        };

        const dealImages = [
            {
                image: equipmentImage,
                index: 1,
                equipName: 'Single Man Lift',
            },
            {
                image: equipment2,
                index: 2,
                equipName: 'Wheel Tractor-Scraper',
            },
            {
                image: equipment3,
                index: 3,
                equipName: 'Compact Track and Multi-Terrain Loader',
            }
        ];
        return (
            <Carousel
                showThumbs={false}
                infiniteLoop={false} // Disable infinite loop
                showStatus={false}
                emulateTouch
                dynamicHeight={false}
                selectedItem={currentIndex} // Set the current slide based on state
                onChange={handleSlideChange} // Update the current index on slide change
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    currentIndex > 0 && (
                        <IconButton
                            onClick={onClickHandler}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '15px',
                                transform: 'translateY(-50%)',
                                zIndex: 2,
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                },
                            }}
                            aria-label={label}
                        >
                            <ArrowBackIosNewIcon />
                        </IconButton>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    currentIndex < dealImages.length - 1 && (
                        <IconButton
                            onClick={onClickHandler}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: '15px',
                                transform: 'translateY(-50%)',
                                zIndex: 2,
                                color: 'white',
                            }}
                            aria-label={label}
                        >
                            <ArrowForwardIosIcon />
                        </IconButton>
                    )
                }
            >
                {dealImages.map((image, index) => (
                    <div key={index} style={{ position: 'relative' }}>
                        <img
                            src={image.image}
                            alt={`${image.equipName}`}
                            style={{
                                borderTopLeftRadius: '16px',
                                borderTopRightRadius: '16px',
                                width: '100%',
                            }}
                        />
                        <div
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                color: 'white',
                                padding: '5px 10px',
                                borderTopLeftRadius: '16px',
                                borderTopRightRadius: '16px',
                                textAlign: 'left',
                                fontSize: '16px',
                                fontWeight: 'bold',
                            }}
                        >
                            <Tooltip title={image.equipName}>
                                <Chip
                                    label={
                                        image.equipName.length > 15
                                            ? `${image.equipName.substring(0, 10)}...`
                                            : image.equipName
                                    }
                                />
                            </Tooltip>
                        </div>
                    </div>
                ))}
            </Carousel>
        );
    };
    // useEffect(() => {
    //     if (contentRef.current) {
    //         const contentHeight = contentRef.current.offsetHeight;
    //         console.log(window.innerHeight);
    //         setImageHeight(window.innerHeight - contentHeight);
    //     }

    //     const handleResize = () => {
    //         if (contentRef.current) {
    //             const contentHeight = contentRef.current.offsetHeight;
    //             setImageHeight(window.innerHeight - contentHeight);
    //         }
    //     };

    //     window.addEventListener('resize', handleResize);
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);
    return (
        <Card ref={cardRef} sx={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '15px' }}>
            <div ref={contentRef}>
                <Box display="flex" flexDirection="row">
                    <Box style={{ paddingRight: "25px" }}>
                        <Link to="/dealpage">
                            <Tooltip title="Back To Deals" arrow>
                                <IconButton>
                                    <ArrowBackIcon style={{ fontSize: 30, color: 'rgb(120, 120, 120)' }} />
                                </IconButton>
                            </Tooltip>
                        </Link>
                    </Box>
                    <Box>
                        <Typography variant="h4" sx={{ color: '#1A75C3', fontWeight: 200 }}>
                            {dealData?.dealName}
                        </Typography>
                    </Box>
                </Box>
                <ThemeProvider theme={theme}>
                    <DataGrid
                        autoHeight
                        rows={rows || []}
                        columns={[
                            {
                                field: "equipmentName",
                                headerName: "Equipment",
                                flex: 2, // This will make the first column occupy 50%
                                renderCell: (params) => {
                                    const { equipmentName, modelYear, make, serialNum } = params.row;
                                    return (
                                        <Box>
                                            <Tooltip title={`EquipmentName:-  ${equipmentName}`}>
                                                <Typography
                                                    variant="body1"
                                                    fontWeight="bold"
                                                    style={isSmallScreen ? { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' } : {}}>
                                                    {equipmentName}
                                                </Typography>
                                            </Tooltip>
                                            <Typography variant="body2" color="textSecondary">
                                                <Box sx={{ display: 'flex', gap: 2 }}>
                                                    <Tooltip title={`ModelYear: ${new Date(modelYear).getFullYear()}`}>
                                                        <span>{new Date(modelYear).getFullYear()}</span>
                                                    </Tooltip>
                                                    <Tooltip title={`Make: ${make}`}>
                                                        <span>{make}</span>
                                                    </Tooltip>
                                                    {serialNum && (
                                                        <Tooltip title={`SerialNumber: ${serialNum}`}>
                                                            <span>{serialNum}</span>
                                                        </Tooltip>
                                                    )}
                                                </Box>
                                            </Typography>
                                        </Box>
                                    );
                                }
                            },
                            ...(reserveValue ? [{
                                field: "reservePrice",
                                headerName: "Reserve Price(USD)",
                                flex: 1, // This will make the reserve price column occupy 25%
                                valueFormatter: (params) => formatCurrency(params.value ? params.value : "")
                            }] : []),
                            {
                                field: "listingPrice",
                                headerName: "Listing Price(USD)",
                                flex: 1, // This will make the listing price column occupy 25%
                                valueFormatter: (params) => formatCurrency(params.value ? params.value : "")
                            },
                            (dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id)) ? {
                                field: "price",
                                headerName: "Buyer Price",
                                flex: 1, // This will make the buyer price column also occupy 25%
                                renderCell: (rowdata) => (
                                    <>
                                        <AttachMoneyIcon />
                                        <TextField
                                            className={classes.numberField}
                                            variant="outlined"
                                            size="small"
                                            onChange={(event) => {
                                                handleTextFieldChange(event, rowdata.row._id);
                                            }}
                                            id="textBox"
                                            name="num1"
                                            type="number"
                                            inputProps={{ min: 0 }}
                                            style={{ padding: "10px 0px 10px 0px" }}
                                        />
                                    </>
                                ),
                            } : ""
                        ]}
                        getRowId={(row) => generateRandom()}
                        pageSize={5}
                        // rowsPerPageOptions={[5]}
                        pagination={false} // Disable pagination
                        disableSelectionOnClick
                        className={classes.hidePagination}
                        sx={{
                            "& .css-ptiqhd-MuiSvgIcon-root": {
                                color: "#ee9907",
                            },
                            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                                display: 'none'
                            }
                        }}
                    />
                </ThemeProvider>
                <Grid container xs={12} my={2}>
                    <Grid
                        item
                        xs={12}
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }} // 'gap' adds spacing between elements
                    >
                        {dealData?.dealClosingSale?.find((un) => un.buyer === loggedInUserData.user._id) ? (
                            <>
                                <Typography
                                    sx={{
                                        fontSize: "medium",
                                        fontWeight: 800,
                                        color: "rgb(120, 120, 120)"
                                    }}
                                >
                                    Total Price
                                </Typography>
                                <AttachMoneyIcon />
                                <TextField
                                    type="number"
                                    value={data}
                                    readOnly
                                    size="small"
                                    placeholder="US DOLLARS"
                                    className={classes.input}
                                    sx={{ minWidth: 120 }} // Ensure the text field doesn't collapse
                                    InputProps={{
                                        inputProps: { readOnly: true }
                                    }}
                                />
                                {!(dealMessages[dealMessages.length - 1]?.notificationType == "Make Offer") &&
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={!data}
                                        onClick={getRowData}
                                        className={classes.MakeButton}
                                    >
                                        Make Offer
                                    </Button>}
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </div>
            {/* {images?.length > 0
                ?
                <div style={{ display: 'flex', overflowX: 'auto' }}>
                    <ImageList variant="masonry" cols={1} gap={1} sx={{ flexDirection: 'row', display: 'flex' }}>
                        {images.map((item, index) => (
                            <ImageListItem key={index} sx={{ minWidth: 200, flex: '0 0 auto' }}>
                                <img
                                    srcSet={`https://atgdevstore.blob.core.windows.net/equipment/${equipmentId}/${item}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                    src={`https://atgdevstore.blob.core.windows.net/equipment/${equipmentId}/${item}?w=248&fit=crop&auto=format`}
                                    alt={item}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>
                </div>
                :
                <Box
                    component="img"
                    src={noImage}
                    alt="No Image Available"
                    sx={{
                        height: `calc(${cardRef.current && cardRef.current.offsetHeight}px - ${contentRef.current ? contentRef.current.offsetHeight : 0}px)`, // Dynamically calculate the height
                        objectFit: 'cover', // Maintain aspect ratio
                        borderRadius: '16px',
                        mx: 'auto', // Center the image horizontally
                    }}
                />
            } */}
        </Card>
    );
};
const mapStateToProps = state => ({
    restClient: state.restClient,
    dealCommunicationList: state.dealCommunication && state.dealCommunication.dealCommunicationList,
    transactionList: state.transaction && state.transaction.transactionList,
    loggedInUserData: state.user && state.user.loggedInUserData,
    dealData: state.deal && state.deal.dealData,
});

export default connect(mapStateToProps, {
    getDealMessageList,
    submitMakeOfferMessage,
    submitTransactionRegisterForm,
    submitMakeOfferMessageWithoutTransactionId,
    getTransactionList,
    getDealById,
})(DataGridDemo);
