import XLSX from "xlsx";

export const GetUploadPayload = (data, companyList, userData, userList, loggedInUserData) => {
    const workbook = XLSX.read(data, { type: 'binary' });
    const firstSheet = workbook.SheetNames[0];
    const excelRows = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet], { raw: false });

    const companies = companyList
        .filter((un) => un.companyName)
        .map((item) => ({
            id: item?.id,
            company: item?.companyName,
            branchName: item?.branch[0]?.branchName,
            branchID: item?.branch[0]?.branchID,
        }));

    const payload = [];
    for (const row of excelRows) {
        const companyFilter = companies.filter((un) => un.company === row['Manufacturing Company']);
        const userFilter = userList.filter((c) => c.name === row['CreatedBy']);
        let companiesFilter, usersFilter, regularUserFilter, branchFilter, needstate, availableState;

        if (companyFilter.length > 0) {
            if (companyFilter[0]?.company === row['Manufacturing Company']){
            companiesFilter = companyFilter[0].id;
            }

            if (userFilter.length > 0 && userFilter[0].name === row['CreatedBy']) {
                usersFilter = userFilter[0].id;

                if (`${loggedInUserData.user.contact.firstName} ${loggedInUserData.user.contact.lastName}` === row['CreatedBy']) {
                    regularUserFilter = userFilter[0].id;
                }
            } else {
                // If CreatedBy is not found in userList, alert and return early
                alert(`User "${row['CreatedBy']}" not found in the system.`);
                return [];  // Return an empty array to indicate failure
            }

            if (companyFilter[0].branchName === row['Branch Name']) {
                branchFilter = companyFilter[0].branchID;
            }
            needstate = row['Need Status'] === "Need";
            availableState = row['Availability Status'] === "Ready";

            payload.push({
                "company": companiesFilter ?? '',
                "equipmentName": row['Equipment Name'],
                "equipmentDescription": row['Equipment Description'],
                "industry": row['Industry'],
                "majorCategory": row['Category'],
                "listingPrice": row['Price'],
                "model": row['Model Number'],
                "make": row['Make'],
                "fleetType": 'Rental',
                "pricingModel": 'Negotiated',
                "qtrProjected": 'Q1',
                "modelYear": row['Year of Manufacturing'],
                "createdBy": loggedInUserData.user.roleSystem === "regular" ? regularUserFilter : usersFilter,
                "assetState": {
                    "locationStateTransition": {
                        "locationStatus": 'Located'
                    },
                    "needStateTransition": needstate ? [
                        {
                            "needStatus": row['Need Status']
                        }
                    ] : [],
                    "usageStateTransition": [
                        {
                            "usageStatus": 'InUse',
                        }
                    ],
                    "availabilityStateTransition": availableState ? [
                        {
                            "availabilityStatus": row['Availability Status']
                        }
                    ] : []
                }
            });
        }
    }

    // console.log("Upload payload", payload);

    if (payload?.length > 0) {
        return payload;
    } else {
        alert("Equipment List do not have company registered. Please upload the Excel correctly.");
        return [];  // Return an empty array to indicate failure
    }
};