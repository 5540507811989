import { setShowLoader } from './LoaderReducer';

export const DEAL_INVITATION_COUNT = 'DEAL_INVITATION_COUNT';


export const getDealInvitationsListCount = (loggedInUserData) => {
    // console.log("id",loggedInUserData);
    return async (dispatch, getState) => {
        try {
            dispatch(setShowLoader(false));
            const client = getState().restClient.rest;
            const query = {
                invitedUserId: loggedInUserData.user._id,
            }
            const totalInvitations = await client.service('deal').find(
                {
                    query
                }
            );
           const dealInvitationCount = totalInvitations.invitationsCount;
        //    console.log("dealInvitationCount", dealInvitationCount);
            dispatch({ type: DEAL_INVITATION_COUNT, payload: dealInvitationCount });
            dispatch(setShowLoader(false));
        } catch (error) {
            // console.log(error)
            dispatch({ type: DEAL_INVITATION_COUNT, payload: [] });
            dispatch(setShowLoader(false));
        }
    }
}



export default function reducer(
    state = {
        dealInvitationCount: null,
    },
    action
) {
    switch (action.type) {
        case DEAL_INVITATION_COUNT:
            return {
                ...state,
                dealInvitationCount: action.payload
            };
        default:
            break;
    }
    return state;
}