// Rest Connection
export const SET_RESTCLIENT_CONNECTION = 'REST/SET_REST_CLIENT_CONNECTION';

export const setRestClientConnection = (client) => {
	return (dispatch) => {
		dispatch({ type: SET_RESTCLIENT_CONNECTION, payload: client });
	}
}

export default function reducer(
  state = {
    
    // Rest Connection
    rest: null,
    isAuthenticated: false

  },
  action
) {
  switch (action.type) {

    // Websocket
    case SET_RESTCLIENT_CONNECTION:
      return {
        ...state,
        rest: action.payload
      };
    default:
      break;
  }
  return state;
}
