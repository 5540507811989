import { setShowLoader } from './LoaderReducer';
import { enqueueSnackbar } from './NotifierReducer';
import { push } from 'connected-react-router';

export const CONTACT_SET_LIST = 'CONTACT_SET_LIST';
export const CONTACT_SET_DATA = 'CONTACT_SET_DATA';


//Get all companies
export const getContactList = (page, sort, search, limit) => {           
            return async (dispatch, getState) => {
              try {
                dispatch(setShowLoader(true));
                const client = getState().restClient.rest;
                const fetchedContactList =  await client.service('espocrm?contacts=true').find(
                  {
                    query: {
                      limit,
                      page,
                      sort,
                      search
                    }
                  }
                );
              let contactList = [];
              if(fetchedContactList.data.contacts && fetchedContactList.data.contacts.list)
              contactList = fetchedContactList.data.contacts.list.map(item => {
                const contact = {};
                contact.id = item.id;
                contact.name=item.name;
                contact.email=item.emailAddress;
                contact.phone=item.phoneNumber;
                contact.addressCity=item.addressCity;
                contact.addressState=item.addressState;
                contact.addressCountry=item.addressCountry;
                contact.addressPostalCode=item.addressPostalCode;
                contact.firstName=item.firstName;
                contact.lastName=item.lastName;

                return contact;
              })
              contactList = { contactList: contactList, total: fetchedContactList.data.contacts.total, skip: fetchedContactList.data.contacts.skip }
              dispatch({ type: CONTACT_SET_LIST, payload: contactList });
              dispatch(setShowLoader(false));
              } catch (error) {
                dispatch(setShowLoader(false));
                dispatch({ type: CONTACT_SET_LIST, payload: [] });
              }
            }
          }

          export const getPlacesList = () => {           
            return async (dispatch, getState) => {
              try {
                dispatch(setShowLoader(true));
                const client = getState().restClient.rest;
                const fetchedPlacesList =  await client.service('espocrm?places=true').find();
              let contactList = [];
              if(fetchedPlacesList.data.places && fetchedPlacesList.data.places.documents)
              contactList = fetchedPlacesList.data.places.documents.map(item => {
                const contact = {};
                contact.id = item.infogroup_id;
                contact.name=item.name;
                contact.street=item.street;
                contact.city=item.city;
                contact.addressState=item.state;
                contact.phone=item.phone;
                contact.addressCountry=item.country_code;
                contact.addressPostalCode=item.postal_code;
                contact.landmarkAddress=item.landmark_address;
                contact.addressCountryCode=item.country_code;

                return contact;
              })
              dispatch({ type: CONTACT_SET_LIST, payload: contactList });
              dispatch(setShowLoader(false));
              } catch (error) {
                dispatch(setShowLoader(false));
                dispatch({ type: CONTACT_SET_LIST, payload: [] });
              }
            }
          }

          export const getContactById = (userId) => {
            return async (dispatch, getState) => {
              try {
                dispatch(setShowLoader(true));
                const client = getState().restClient.rest;
                const contact = await client.service(`espocrm`).get(userId, {})
                const data = contact.contact || {};
                const values = {
                  contactID: data.id,
                  name: data.name,
                  email:data.emailAddress,
                  phone:data.phoneNumber,
                  addressCity:data.addressCity,
                  addressState:data.addressState,
                  addressCountry:data.addressCountry,
                  addressPostalCode:data.addressPostalCode,
                  firstName:data.firstName,
                  lastName:data.lastName,
                };
                dispatch({ type: CONTACT_SET_DATA, payload: values })
                dispatch(setShowLoader(false));
              } catch (error) {
                dispatch(setShowLoader(false));
                dispatch(enqueueSnackbar({
                  message: error.message,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'warning',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    }
                  },
                }));
              }
            }
          }

          export const editContact = (userId, payload = {}) => {
            return async (dispatch, getState) => {
              try {
                dispatch(setShowLoader(true));
                const client = getState().restClient.rest;
                await client.service('espocrm').patch(userId, payload);
                dispatch(enqueueSnackbar({
                  message: 'User information EDITED And Saved.',
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    }
                  },
                }));
                dispatch(push('/users'));
              } catch (error) {
                dispatch(setShowLoader(false));
                dispatch(enqueueSnackbar({
                  message: `Edit User information failed. ${error.message}`,
                  options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'right',
                    }
                  },
                }));
              }
            }
          }
export default function reducer(
            state = {
              contactList: [],
              contactData:{}
            },
            action
          ) {
            switch (action.type) {
              case CONTACT_SET_LIST:
                return {
                  ...state,
                  contactList: action.payload
                };
              case CONTACT_SET_DATA:
                  return {
                    ...state,
                    contactData: action.payload
                  };
             default:
              break;
            }
            return state;
        }