import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Card, Alert, CardContent, Typography, Box, Button, Divider, Grid } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from 'react-router-dom';
import { getCompanyById } from "../../reducers/CompanyReducer";
import { setLoggedInUserData } from "../../reducers/UserReducer";

const MySubscription = (props) => {
    const { restClient: { rest }, loggedInUserData, companyData = {}, getCompanyById } = props;
    const [parentCompany, setParentCompany] = useState({});
    useEffect(() => {
        if (rest && loggedInUserData) {
            const getCompany = async () => {
                if (loggedInUserData?.user?.parentCompany?._id) {
                    await getCompanyById(loggedInUserData?.user?.parentCompany?._id);
                } else if (typeof loggedInUserData?.user?.parentCompany === 'string') {
                    await getCompanyById(loggedInUserData?.user?.parentCompany);
                }
            }
            getCompany();
        }
    }, [getCompanyById, loggedInUserData, rest]);

    useEffect(() => {
        if (Object.keys(companyData).length > 0 && rest) {
            setParentCompany(companyData);
        }
    }, [companyData, rest])

    return (
        <Grid container xs={12} sx={{ padding: '15px' }}>
            {parentCompany?.companyEmailAddress ? (
                <>
                    <Grid item xs={12}>
                        <Typography variant="h4">My Subscription</Typography>
                        <Typography variant="subtitle2">
                            Plan Details
                        </Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Card sx={{ borderRadius: '16px', maxWidth: '100%' }}>
                            {/* Header with gradient */}
                            <Box
                                sx={{
                                    background: 'linear-gradient(to right, #4a00e0, #8e2de2)',
                                    height: '8px',
                                    borderTopLeftRadius: '16px',
                                    borderTopRightRadius: '16px',
                                }}
                            />

                            <CardContent>
                                {/* Title */}
                                <Typography variant="h6" fontWeight="bold" gutterBottom>
                                    {parentCompany?.subscriptionData?.planType
                                        ? `${parentCompany.subscriptionData.planType} plan chosen`
                                        : 'No plan chosen'}
                                </Typography>

                                {/* End Date (if available) */}
                                {parentCompany?.subscriptionData?.EndDate && (
                                    <Typography variant="caption" fontWeight="bold">
                                        End date: {new Date(parentCompany.subscriptionData.EndDate).toLocaleDateString('en-US')}
                                    </Typography>
                                )}

                                <Divider sx={{ my: 2 }} />

                                {/* Change Plan Button */}
                                <Link to={`/subscription?plan=${parentCompany?.subscriptionData?.planType}`}>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Button variant="text" sx={{ textTransform: 'none' }}>
                                            {parentCompany?.subscriptionData?.planType ? 'Change plan' : 'Choose plan'}
                                        </Button>
                                        <ArrowForwardIosIcon fontSize="small" />
                                    </Box>
                                </Link>
                            </CardContent>
                        </Card>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <Card sx={{ borderRadius: '16px', maxWidth: '100%' }}>
                        {/* Header with gradient */}
                        <Box
                            sx={{
                                background: 'linear-gradient(to right, #4a00e0, #8e2de2)',
                                height: '8px',
                                borderTopLeftRadius: '16px',
                                borderTopRightRadius: '16px',
                            }}
                        />

                        <CardContent>
                            <Alert severity="info">Please update your Company Email Address</Alert>
                        </CardContent>
                    </Card>
                </Grid>
            )}
        </Grid>
    )
}

const mapStateToProps = state => ({
    restClient: state.restClient,
    loggedInUserData: state.user && state.user.loggedInUserData,
    companyData: state.company.companyData
});

export default connect(mapStateToProps, { setLoggedInUserData, getCompanyById })(MySubscription);
