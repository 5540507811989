import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Grid,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  FormGroup,
  Collapse,
  CardActions,
  Switch,
  Tooltip,
  IconButton
} from '@mui/material';
import { getDealList, getDealById } from '../../reducers/DealReducer';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { withStyles } from '@mui/styles';
import { makeStyles } from '@mui/styles'
import { orange } from '@mui/material/colors';
import { setLoggedInUserData } from '../../reducers/UserReducer';
import useQuery from '../../utils/useQuery';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from "clsx";


const PurpleSwitch = withStyles({
  switchBase: {
    color: orange[300],
    '&$checked': {
      color: orange[500],
    },
    '&$checked + $track': {
      backgroundColor: orange[500],
    },
  },
  checked: {},
  track: {},
})(Switch);

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 300,
    display: "block",
    width: "20vw",
    paddingLeft: "20px",
    paddingBottom: "25px",
    "@media (max-width: 768px)": {
      marginLeft: "26px",
    },
    "&:hover": { transform: "scale3d(1.05, 1.05, 1)" },
  },
  media: {
    height: 200
  },
  title: {
    fontSize: 14,
    marginLeft: "12px"
  },
  linkStyle: {
    textDecoration: "none",
  },
  expandStyle: {
    textDecoration: "none",
    float: 'right'
  },
  pos: {
    marginBottom: 12,
  },
  Card: {
    margin: "10px",
    borderRadius: "10px",
    overflow: "hidden",
    boxShadow: "0px 6px 18px -9px rgba(0, 0, 0, 0.75)",
    transition: "transform 100ms ease-in",
    cursor: "pointer"
  },
  button: {
    backgroundColor: "orange",
  },
  link: {
    textDecoration: "none"
  },
  float: {
    float: "right"
  },
  expand: {
    transform: 'rotate(0deg)',
    left: '4em',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  separate: {
    marginRight: "3.5em",
    marginBottom: "1em",
    "@media (max-width: 1300px)": {
      justifyContent: "center",
    },
  },
  fullWidth: {
    "@media (max-width: 768px)": {
      marginLeft: "4em"
    },
  }
}))

const DealList = (props) => {
  const { dealList = [], restClient: { rest }, getDealList, loggedInUserData, getDealById } = props;
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobileDevices = useMediaQuery(theme.breakpoints.down('sm'));
  const [expanded, setExpanded] = useState({});


  const [filterChecked, setFilterCheked] = useState(false);
  const [filteredDealList, setFilterDealList] = useState([]);

  const query = useQuery();
  const dealID = query.get('dealId');

  useEffect(() => {
    if (dealID && rest) {
      const getDeal = async () => {
        getDealById(dealID);
      }
      getDeal();
    }
  }, [dealID, getDealById, rest])

  const handleActiveDealChange = (event) => {
    setFilterCheked(event.target.checked);

  }

  const handleExpandClick = (id) => {
    setExpanded((expanded) => ({
      ...expanded,
      [id]: !expanded[id]
    }));
  };

  const handleDealEdit = (id) => {

    history.push(`/create/deals?action=edit&id=${id}`);

  }

  useEffect(() => {
    if (filterChecked) {
      const filterDeals = dealList.filter((item) => item.creatorPeople === loggedInUserData.user._id);
      setFilterDealList(filterDeals);
    } else {
      setFilterDealList(dealList);
    }
  }, [dealList, filterChecked, loggedInUserData.user._id]);

  useEffect(() => {
    const getList = async () => {
      try {
        if (rest) {
          await getDealList();
        }
      } catch (e) {
        console.log(e);
      }
    };
    getList();
  }, [rest, getDealList]);


  const deal = filteredDealList.map((data) => {
    return (
      <div>
        <Grid item xs={12} md={6} lg={4} spacing={3}>
          <div container className={classes.root}>
            <Card >
              <Link to='/deal/chatbox'>
                <CardMedia
                  className={classes.media}
                  src="https://asktakego.blob.core.windows.net/equipment/imageExcavator.jpg"
                  component="img"
                  title="jcb"
                />
              </Link>
              <Typography className={classes.title} color="textSecondary" gutterBottom>
                <h2>{data.dealName}{filterChecked ?
                  <Tooltip title="Edit Deal" placement="top" arrow>
                    <Button size="small" color="primary" className={classes.float} onClick={() => handleDealEdit(data.id)}>
                      <EditIcon />
                    </Button>
                  </Tooltip> : null}</h2>
              </Typography>
              <CardActions>
                <Link to={!isMobileDevices ? "/deal/closeouts?dealId=" + data.id : "/inbox?dealId=" + data.id} className={classes.linkStyle}>
                  <Button size="small" color="primary">
                    More Details
                  </Button>
                </Link>
                <IconButton
                  className={clsx(classes.expand, {
                    [classes.expandOpen]: expanded[data.id],
                  })}
                  onClick={(id) => handleExpandClick(data.id)}
                  aria-expanded={expanded[data.id]}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </IconButton>
              </CardActions>
              <Collapse in={expanded[data.id]} timeout="auto" unmountOnExit>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    <h6>{data.dealDescription}</h6>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    <h5>{data.dealBrand}</h5>
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p">
                    <h5>{data.brandDescription}</h5>
                  </Typography>
                </CardContent>
              </Collapse>
            </Card>
          </div>
        </Grid>
      </div>
    )
  })
  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="stretch"
        className={classes.separate}
        spacing={2}
      >
        <Grid item className={classes.fullWidth}>
          <FormGroup row>
            <Typography variant="h6" color="main">All Deals</Typography>
            <PurpleSwitch checked={filterChecked} onChange={handleActiveDealChange} name="checkedC" />
            <Typography variant="h6" color="main">My Deals</Typography>
          </FormGroup>
        </Grid>
        <Grid item className={classes.fullWidth}>
          <FormGroup row>
            <Link to="/create/deals" className={classes.link}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<AddIcon />}
              >
                Add a New Deal
              </Button>
            </Link>
          </FormGroup>
        </Grid>
      </Grid>
      {deal}
    </>
  );
}

const mapStateToProps = state => ({
  restClient: state.restClient,
  dealList: state.deal.dealList,
  dealData: state.deal && state.deal.dealData,
  loggedInUserData: state.user && state.user.loggedInUserData
});

export default connect(mapStateToProps, { getDealList, getDealById, setLoggedInUserData })(DealList);